import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  DatePicker,
  TimePicker,
} from '@material-ui/pickers';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Switch,
  FormControlLabel,
  Box,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import useStyles from './styles';
import {
  status as listOfStatus,
  formatAsDate,
} from '../../../../utils';
import notificationSystem from '../../../../services/notificationSystemRef';

const AddCouponModal = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: { coupon } } = useLocation();
  const edit = useMemo(() => Boolean(coupon?.id), [coupon]);
  const [code, setCode] = useState('');
  const [invalidCode, setInvalidCode] = useState(false);
  const [savingPercent, setSavingPercent] = useState('');
  const [invalidSavingPercent, setInvalidSavingPercent] = useState(false);
  const [type, setType] = useState('');
  const [invalidType, setInvalidType] = useState(false);
  const [validPeriodStart, setValidPeriodStart] = useState(null);
  const [invalidValidPeriodStart, setInvalidValidPeriodStart] = useState(false);
  const [validPeriodFinish, setValidPeriodFinish] = useState(null);
  const [invalidValidPeriodFinish, setInvalidValidPeriodFinish] = useState(false);
  const [validHoursStart, setValidHoursStart] = useState(null);
  const [invalidValidHoursStart, setInvalidValidHoursStart] = useState(false);
  const [validHoursFinish, setValidHoursFinish] = useState(null);
  const [invalidValidHoursFinish, setInvalidValidHoursFinish] = useState(false);
  const [influencerName, setInfluencerName] = useState('');
  const [invalidInfluencerName, setInvalidInfluencerName] = useState(false);
  const [influencerMail, setInfluencerMail] = useState('');
  const [invalidInfluencerMail, setInvalidInfluencerMail] = useState(false);
  const [influencerType, setInfluencerType] = useState('');
  const [invalidInfluencerType, setInvalidInfluencerType] = useState(false);
  const [subsequentRemuneration, setSubsequentRemuneration] = useState('');
  const [invalidSubsequentRemuneration, setInvalidSubsequentRemuneration] = useState(false);
  const [remunerationFeePercent, setRemunerationFeePercent] = useState('');
  const [invalidRemunerationFeePercent, setInvalidRemunerationFeePercent] = useState(false);
  const [remunerationPeriod, setRemunerationPeriod] = useState('');
  const [invalidRemunerationPeriod, setInvalidRemunerationPeriod] = useState(false);
  const [unlimitedUse, setUnlimitedUse] = useState(false);
  const [quantityUse, setQuantityUse] = useState(null);

  const classes = useStyles();
  const {
    isPending,
    isResolved,
    isRejected,
    setRequest,
    message,
    status,
  } = useAxios();

  useEffect(() => {
    setRequest(null);
    setInvalidCode(false);
    setInvalidSavingPercent(false);
    setInvalidType(false);
    setInvalidValidPeriodStart(false);
    setInvalidValidPeriodFinish(false);
    setInvalidValidHoursStart(false);
    setInvalidValidHoursFinish(false);
    setInvalidInfluencerName(false);
    setInvalidInfluencerMail(false);
    setInvalidInfluencerType(false);
    setInvalidSubsequentRemuneration(false);
    setInvalidRemunerationFeePercent(false);
    setInvalidRemunerationPeriod(false);

    setCode(coupon?.code || '');
    setSavingPercent(coupon?.savingPercent || '');
    setType(coupon?.type || '');
    setValidPeriodStart(coupon?.validPeriod?.start || null);
    setValidPeriodFinish(coupon?.validPeriod?.finish || null);

    const minutesPerHour = 60;
    if (coupon?.validHours?.start === undefined) {
      setValidHoursStart(null);
    } else {
      const { validHours: { start } } = coupon;
      const hour = start / minutesPerHour;
      const minute = start % minutesPerHour;
      const vHStart = moment({ hour, minute });
      setValidHoursStart(vHStart);
    }

    if (coupon?.validHours?.finish === undefined) {
      setValidHoursFinish(null);
    } else {
      const { validHours: { finish } } = coupon;
      const hour = finish / minutesPerHour;
      const minute = finish % minutesPerHour;
      const vHFinish = moment({ hour, minute });
      setValidHoursFinish(vHFinish);
    }

    setInfluencerName(coupon?.influencer?.name || '');
    setInfluencerMail(coupon?.influencer?.mail || '');
    setInfluencerType(coupon?.influencer?.type || '');
    setUnlimitedUse(coupon?.unlimited || false);
    setQuantityUse(coupon?.qtdLimitUses || null);

    /* coupon.remuneration === null quer dizer que o influencer não é remunerado */
    const initialSubseqRemuneration = coupon?.id ? Boolean(coupon?.remuneration) : '';
    setSubsequentRemuneration(initialSubseqRemuneration);

    setRemunerationFeePercent(coupon?.remuneration?.remunerationFeePercent || '');
    setRemunerationPeriod(coupon?.remuneration?.remunerationPeriod || '');
  }, [
    setRequest,
    coupon,
  ]);

  useEffect(() => {
    if (isResolved) {
      const notificationTitle = t((edit && 'coupons.edit.success.notification.title')
        || 'coupons.add.success.notification.title');
      const notificationMessage = t((edit && 'coupons.edit.success.notification.message')
        || 'coupons.add.success.notification.message');

      notificationSystem.current.addNotification({
        title: notificationTitle,
        message: notificationMessage,
        level: 'success',
        position: 'tr',
        autoDismiss: 3,
      });

      history.goBack();
    }
  }, [
    t,
    isResolved,
    history,
    edit,
  ]);

  const resetRequest = useCallback(() => {
    if (status !== listOfStatus.IDLE) {
      setRequest(null);
    }
  }, [status, setRequest]);

  useEffect(
    () => setInvalidCode(isRejected && message === 'error.coupon.code.already.exists'),
    [isRejected, message, code],
  );

  useEffect(() => setInvalidSavingPercent(
    savingPercent && (
      savingPercent < 1 || savingPercent > 100 || (savingPercent > 85 && savingPercent < 100)
    ),
  ), [savingPercent]);

  useEffect(resetRequest, [code, savingPercent]);

  const validPeriodFinishLessThanValidPeriodStart = useMemo(() => (
    validPeriodStart && validPeriodFinish
    && (
      formatAsDate(validPeriodStart) === formatAsDate(validPeriodFinish)
      || validPeriodStart.valueOf() >= validPeriodFinish.valueOf()
    )
  ), [validPeriodStart, validPeriodFinish]);

  const validHoursFinishLessThanValidHoursStart = useMemo(
    () => (
      validHoursStart
      && validHoursFinish && (
        validHoursStart.hours() > validHoursFinish.hours() || (
          validHoursStart.hours() === validHoursFinish.hours()
          && validHoursStart.minutes() >= validHoursFinish.minutes()
        ))),
    [validHoursStart, validHoursFinish],
  );

  useEffect(
    () => setInvalidValidPeriodFinish(validPeriodFinishLessThanValidPeriodStart),
    [validPeriodFinishLessThanValidPeriodStart],
  );

  useEffect(
    () => setInvalidValidHoursFinish(validHoursFinishLessThanValidHoursStart),
    [validHoursFinishLessThanValidHoursStart],
  );

  const submitDisabled = useMemo(() => (
    isPending
    || isRejected
    || isResolved
    || !code
    || invalidCode
    || !savingPercent
    || invalidSavingPercent
    || !type
    || !validPeriodStart
    || !validPeriodFinish
    || invalidValidPeriodFinish
    || !validHoursStart
    || !validHoursFinish
    || invalidValidHoursFinish
    || !influencerName
    || !influencerMail
    || invalidInfluencerMail
    || !influencerType
    || subsequentRemuneration === ''
    || (
      subsequentRemuneration === true && (
        !remunerationFeePercent
        || invalidRemunerationFeePercent
        || !remunerationPeriod
        || invalidRemunerationPeriod
      )
    )
  ), [
    isPending,
    isRejected,
    isResolved,
    code,
    invalidCode,
    savingPercent,
    invalidSavingPercent,
    type,
    validPeriodStart,
    validPeriodFinish,
    invalidValidPeriodFinish,
    validHoursStart,
    validHoursFinish,
    invalidValidHoursFinish,
    influencerName,
    influencerMail,
    invalidInfluencerMail,
    influencerType,
    remunerationFeePercent,
    invalidRemunerationFeePercent,
    remunerationPeriod,
    invalidRemunerationPeriod,
    subsequentRemuneration,
  ]);

  const handleSubmit = () => {
    if (!submitDisabled) {
      const getMinutes = (value) => {
        const time = moment(value);
        const minutesPerHour = 60;

        return (time.hours() * minutesPerHour) + time.minutes();
      };

      const method = (edit && 'PUT') || 'POST';

      const url = (edit && `coupon/${coupon.code}`) || 'coupon';

      const req = {
        method,
        baseURL: `${process.env.REACT_APP_BACKOFICCE_API_URL}/`,
        url,
        data: {
          active: true,
          code,
          savingPercent: Number(savingPercent),
          type,
          validPeriod: {
            start: validPeriodStart.valueOf(),
            finish: validPeriodFinish.valueOf(),
          },
          validHours: {
            start: getMinutes(validHoursStart),
            finish: getMinutes(validHoursFinish),
          },
          influencer: {
            name: influencerName,
            mail: influencerMail,
            type: influencerType,
          },
          remuneration: subsequentRemuneration ? {
            remunerationFeePercent: Number(remunerationFeePercent),
            remunerationPeriod: Number(remunerationPeriod),
          } : null,
          unlimited: unlimitedUse,
          qtdLimitUses: Number(quantityUse),
        },
      };
      setRequest(req);
    }
  };

  const remunerationPeriodDisabled = useMemo(
    () => subsequentRemuneration === '' || subsequentRemuneration === false || isPending,
    [subsequentRemuneration, isPending],
  );
  const remunerationFeePercentDisabled = useMemo(
    () => subsequentRemuneration === '' || subsequentRemuneration === false || isPending,
    [subsequentRemuneration, isPending],
  );

  const subsequentRemunerationDisabled = useMemo(
    () => {
      setInvalidSubsequentRemuneration(false);
      const SEASONAL = type === 'SEASONAL';

      if (!isPending && type && SEASONAL) {
        setSubsequentRemuneration(false);
      }

      return (SEASONAL || isPending);
    },
    [type, isPending],
  );

  useEffect(() => {
    setInvalidRemunerationFeePercent(false);
    setInvalidRemunerationPeriod(false);
    if (subsequentRemuneration === false) {
      setRemunerationFeePercent('');
      setRemunerationPeriod('');
    }
  }, [subsequentRemuneration]);

  useEffect(() => setInvalidValidPeriodStart(false), [validPeriodStart]);
  useEffect(() => setInvalidValidHoursStart(false), [validHoursStart]);
  useEffect(
    () => setInvalidValidPeriodFinish(validPeriodFinishLessThanValidPeriodStart),
    [validPeriodFinishLessThanValidPeriodStart],
  );
  useEffect(
    () => setInvalidValidHoursFinish(validHoursFinishLessThanValidHoursStart),
    [validHoursFinishLessThanValidHoursStart],
  );

  const validPeriodFinishHelperText = useMemo(() => (
    invalidValidPeriodFinish && (
      (validPeriodFinish && t('add.coupon.modal.invalidValidPeriodFinish.message'))
      || t('required.field')
    )
  ), [invalidValidPeriodFinish, validPeriodFinish, t]);

  const validHoursFinishHelperText = useMemo(() => (
    invalidValidHoursFinish && (
      (validHoursFinish && t('add.coupon.modal.invalidValidHoursFinish.message'))
      || t('required.field')
    )
  ), [invalidValidHoursFinish, validHoursFinish, t]);

  const savingPercentHelperText = useMemo(
    () => (
      (
        !savingPercent
        && invalidSavingPercent
        && t('required.field')
      ) || t('add.coupon.modal.savingPercent.rule.message')
    ),
    [savingPercent, invalidSavingPercent, t],
  );

  const remunerationFeePercentHelperText = useMemo(
    () => (
      invalidRemunerationFeePercent
      && (
        (!remunerationFeePercent && t('required.field')) || (
          remunerationFeePercent
          && (remunerationFeePercent <= 0 || remunerationFeePercent > 100)
          && t('add.coupon.modal.remunerationFeePercent.rule.message')
        )
      )
    ),
    [remunerationFeePercent, invalidRemunerationFeePercent, t],
  );

  const checkMail = (mail) => mail
    && mail.includes('@')
    && mail.includes('.');

  return (
    <Dialog
      open
      onClose={() => history.goBack()}
      transitionDuration={500}
    >
      <DialogTitle>{t('coupons.add.coupon.button.label')}</DialogTitle>
      <DialogContent>
        <form>
          <Grid container>
            {/* IDENTIFICAÇÃO */}
            <Grid
              container
              spacing={4}
              item
              xs={12}
              component="fieldset"
              className={classes.fieldset}
            >
              <Grid
                item
                xs={12}
                component="legend"
                className={classes.legend}
              >
                {t('add.coupon.modal.identification.legend')}
              </Grid>

              {/* code */}
              <Grid item xs={8}>
                <TextField
                  id="add-coupon-modal-code"
                  name="code"
                  label={t('add.coupon.modal.code.label')}
                  value={code}
                  onChange={({ target }) => {
                    setCode(target.value.replaceAll(' ', '').toUpperCase());
                    setInvalidCode(!target.value);
                  }}
                  inputProps={{ maxLength: 40 }}
                  error={invalidCode}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  disabled={isPending || edit}
                  onBlur={({ target }) => setInvalidCode((prev) => prev || !target.value)}
                  helperText={invalidCode && t(message || 'required.field')}
                />
              </Grid>

              {/* savingPercent */}
              <Grid item xs={4}>
                <TextField
                  id="add-coupon-modal-savingPercent"
                  name="savingPercent"
                  label={t('add.coupon.modal.savingPercent.label')}
                  value={savingPercent}
                  onChange={({ target }) => {
                    setSavingPercent(target.value);
                    setInvalidSavingPercent(!target.value);
                  }}
                  inputProps={{
                    type: 'number',
                    maxLength: 3,
                    min: 1,
                    max: 100,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={invalidSavingPercent}
                  required
                  disabled={isPending}
                  onBlur={({ target }) => setInvalidSavingPercent((prev) => prev || !target.value)}
                  helperText={savingPercentHelperText}
                />
              </Grid>
            </Grid>

            {/* VALIDADE DO CUPOM */}
            <Grid
              container
              spacing={4}
              item
              xs={12}
              component="fieldset"
              className={classes.fieldset}
            >
              <Grid
                item
                xs={12}
                component="legend"
                className={classes.legend}
              >
                {t('add.coupon.modal.validity.legend')}
              </Grid>

              {/* type */}
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                >
                  <InputLabel
                    id="add-coupon-modal-type-label"
                    error={invalidType}
                  >
                    {t('add.coupon.modal.type.label')}
                  </InputLabel>
                  <Select
                    id="add-coupon-modal-type"
                    label={t('add.coupon.modal.type.label')}
                    labelId="add-coupon-modal-type-label"
                    name="type"
                    value={type}
                    onChange={({ target }) => {
                      setType(target.value);
                      setInvalidType(!target.value);
                    }}
                    disabled={isPending}
                    error={invalidType}
                    onBlur={({ target }) => setInvalidType(!target.value)}
                  >
                    <MenuItem value="FIRST_TIME">
                      {t('add.coupon.modal.type.FIRST_TIME.menu.item.text')}
                    </MenuItem>
                    <MenuItem value="SEASONAL">
                      {t('add.coupon.modal.type.SEASONAL.menu.item.text')}
                    </MenuItem>
                  </Select>
                </FormControl>
                {invalidType && (
                <FormHelperText
                  error
                  style={{ visibility: invalidType ? 'visible' : 'hidden' }}
                >
                    {t('required.field')}
                </FormHelperText>
                )}
              </Grid>
              {/* Coupon use quantity */}
              <Grid item xs={6}>
                <TextField
                  id="add-coupon-modal-useQuantity"
                  name="useQuantity"
                  label={t('add-coupon-modal-useQuantity')}
                  value={quantityUse}
                  onChange={({ target }) => setQuantityUse(target.value)}
                  inputProps={{
                    type: 'number',
                    min: 1,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={unlimitedUse}
                />
              </Grid>
              {/* Switch coupon unlimited use */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <FormControlLabel
                    label={t('add-coupon-modal-unlimitedCoupon')}
                    labelPlacement="start"
                    control={(
                      <Switch
                        checked={unlimitedUse}
                        onChange={({ target }) => setUnlimitedUse(target.checked)}
                        name="unlimitedCoupon"
                        color="primary"
                      />
                      )}
                  />
                </Box>
              </Grid>
              {/* validPeriodStart */}
              <Grid item xs={6}>
                <DatePicker
                  id="add-coupon-modal-validPeriodStart"
                  name="validPeriodStart"
                  inputVariant="outlined"
                  value={validPeriodStart}
                  onChange={setValidPeriodStart}
                  views={['date', 'month', 'year']}
                  label={t('add.coupon.modal.validPeriodStart.label')}
                  minDate={new Date('2018-01-01')}
                  size="small"
                  fullWidth
                  format="DD/MM/yyyy"
                  disablePast
                  cancelLabel={t('commom.cancel')}
                  required
                  disabled={isPending}
                  error={invalidValidPeriodStart}
                  onClose={() => setInvalidValidPeriodStart(!validPeriodStart)}
                  helperText={invalidValidPeriodStart && !validPeriodStart && t('required.field')}
                  clearable={false}
                />
              </Grid>

              {/* validPeriodFinish */}
              <Grid item xs={6}>
                <DatePicker
                  id="add-coupon-modal-validPeriodFinish"
                  name="validPeriodFinish"
                  inputVariant="outlined"
                  value={validPeriodFinish}
                  onChange={setValidPeriodFinish}
                  views={['date', 'month', 'year']}
                  label={t('add.coupon.modal.validPeriodFinish.label')}
                  minDate={new Date('2018-01-01')}
                  size="small"
                  fullWidth
                  format="DD/MM/yyyy"
                  disablePast
                  cancelLabel={t('commom.cancel')}
                  error={invalidValidPeriodFinish}
                  required
                  disabled={isPending}
                  onClose={() => {
                    if (!validPeriodFinish) {
                      setInvalidValidPeriodFinish(true);
                    }
                  }}
                  helperText={validPeriodFinishHelperText}
                  clearable={false}
                />
              </Grid>

              {/* validHoursStart */}
              <Grid item xs={6}>
                <TimePicker
                  id="add-coupon-modal-validHoursStart"
                  name="validHoursStart"
                  ampm={false}
                  label={t('add.coupon.modal.validHoursStart.label')}
                  value={validHoursStart}
                  onChange={setValidHoursStart}
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  clearable={false}
                  cancelLabel={t('commom.cancel')}
                  required
                  disabled={isPending}
                  onClose={() => setInvalidValidHoursStart(!validHoursStart)}
                  error={invalidValidHoursStart}
                  helperText={invalidValidHoursStart && t('required.field')}
                />
              </Grid>

              {/* validHoursFinish */}
              <Grid item xs={6}>
                <TimePicker
                  id="add-coupon-modal-validHoursFinish"
                  name="validHoursFinish"
                  ampm={false}
                  label={t('add.coupon.modal.validHoursFinish.label')}
                  value={validHoursFinish}
                  onChange={(time) => setValidHoursFinish(time)}
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  clearable={false}
                  cancelLabel={t('commom.cancel')}
                  required
                  disabled={isPending}
                  onClose={() => {
                    if (!validHoursFinish) {
                      setInvalidValidHoursFinish(true);
                    }
                  }}
                  error={invalidValidHoursFinish}
                  helperText={validHoursFinishHelperText}
                />
              </Grid>
            </Grid>

            {/* INFLUENCER E REMUNERAÇÃO */}
            <Grid
              container
              spacing={4}
              item
              xs={12}
              component="fieldset"
              className={classes.fieldset}
            >
              <Grid
                item
                xs={12}
                component="legend"
                className={classes.legend}
              >
                {t('add.coupon.modal.influencer.remuneration.legend')}
              </Grid>

              {/* influencerName */}
              <Grid item xs={12}>
                <TextField
                  id="add-coupon-modal-influencerName"
                  name="influencerName"
                  label={t('add.coupon.modal.influencerName.label')}
                  value={influencerName}
                  onChange={({ target }) => {
                    setInfluencerName(target.value);
                    setInvalidInfluencerName(!target.value);
                  }}
                  inputProps={{ type: 'text', maxLength: 150 }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  disabled={isPending}
                  onBlur={({ target }) => setInvalidInfluencerName(!target.value)}
                  helperText={invalidInfluencerName && t('required.field')}
                  error={invalidInfluencerName}
                />
              </Grid>

              {/* influencerMail */}
              <Grid item xs={12}>
                <TextField
                  id="add-coupon-modal-influencerMail"
                  name="influencerMail"
                  label={t('add.coupon.modal.influencerMail.label')}
                  value={influencerMail}
                  onChange={({ target }) => {
                    setInfluencerMail(target.value.replaceAll(' ', ''));
                    setInvalidInfluencerMail(!(checkMail(target.value)));
                  }}
                  inputProps={{
                    type: 'email',
                    maxLength: 150,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  disabled={isPending}
                  onBlur={({ target }) => setInvalidInfluencerMail(
                    (!checkMail(target.value)) || !target.value,
                  )}
                  helperText={
                    invalidInfluencerMail
                    && ((
                      !influencerMail
                      && t('required.field')
                    ) || t('add.coupon.modal.invalidInfluencerMail.message'))
                  }
                  error={invalidInfluencerMail}
                />
              </Grid>

              {/* influencerType */}
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                >
                  <InputLabel
                    id="add-coupon-modal-influencerType-label"
                    error={invalidInfluencerType}
                  >
                    {t('add.coupon.modal.influencerType.label')}
                  </InputLabel>
                  <Select
                    id="add-coupon-modal-influencerType"
                    label={t('add.coupon.modal.influencerType.label')}
                    labelId="add-coupon-modal-influencerType-label"
                    name="influencerType"
                    value={influencerType}
                    onChange={({ target }) => {
                      setInfluencerType(target.value);
                      setInvalidInfluencerType(!target.value);
                    }}
                    required
                    disabled={isPending}
                    onClose={() => setInvalidInfluencerType(!influencerType)}
                    error={invalidInfluencerType}
                  >
                    <MenuItem value="INFLUENCER">
                      {t('add.coupon.modal.influencerType.INFLUENCER.menu.item.text')}
                    </MenuItem>
                    <MenuItem value="BROKER">
                      {t('add.coupon.modal.influencerType.BROKER.menu.item.text')}
                    </MenuItem>
                  </Select>
                  <FormHelperText
                    error
                    style={{ visibility: invalidInfluencerType ? 'visible' : 'hidden' }}
                  >
                    {t('required.field')}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {/* subsequentRemuneration */}
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                >
                  <InputLabel
                    id="add-coupon-modal-subsequentRemuneration-label"
                    error={invalidSubsequentRemuneration}
                  >
                    {t('add.coupon.modal.subsequentRemuneration.label')}
                  </InputLabel>
                  <Select
                    id="add-coupon-modal-subsequentRemuneration"
                    label={t('add.coupon.modal.subsequentRemuneration.label')}
                    labelId="add-coupon-modal-subsequentRemuneration-label"
                    name="subsequentRemuneration"
                    value={subsequentRemuneration}
                    onChange={({ target }) => {
                      setSubsequentRemuneration(target.value);
                      setInvalidSubsequentRemuneration((target.value === '' && true) || false);
                    }}
                    disabled={subsequentRemunerationDisabled}
                    onClose={() => setInvalidSubsequentRemuneration(subsequentRemuneration === '')}
                    error={invalidSubsequentRemuneration}
                  >
                    <MenuItem value>{t('commom.yes')}</MenuItem>
                    <MenuItem value={false}>{t('commom.no')}</MenuItem>
                  </Select>
                  <FormHelperText
                    error
                    style={{ visibility: invalidSubsequentRemuneration ? 'visible' : 'hidden' }}
                  >
                    {t('required.field')}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {/* remunerationFeePercent */}
              <Grid item xs={6}>
                <TextField
                  id="add-coupon-modal-remunerationFeePercent"
                  name="remunerationFeePercent"
                  label={t('add.coupon.modal.remunerationFeePercent.label')}
                  value={remunerationFeePercent}
                  onChange={({ target }) => {
                    const { value } = target;
                    setRemunerationFeePercent(value);
                    setInvalidRemunerationFeePercent(!value || value <= 0 || value > 100);
                  }}
                  inputProps={{
                    type: 'number',
                    maxLength: 3,
                    min: 1,
                    max: 100,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={remunerationFeePercentDisabled}
                  required={subsequentRemuneration === true}
                  onBlur={({ target }) => {
                    if (!target.value) {
                      setInvalidRemunerationFeePercent(true);
                    }
                  }}
                  error={invalidRemunerationFeePercent}
                  helperText={remunerationFeePercentHelperText}
                />
              </Grid>

              {/* remunerationPeriod */}
              <Grid item xs={6}>
                <TextField
                  id="add-coupon-modal-remunerationPeriod"
                  name="remunerationPeriod"
                  label={t('add.coupon.modal.remunerationPeriod.label')}
                  value={remunerationPeriod}
                  onChange={({ target }) => {
                    setRemunerationPeriod(target.value);
                    setInvalidRemunerationPeriod(!target.value);
                  }}
                  inputProps={{ maxLength: 150 }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={remunerationPeriodDisabled}
                  required={subsequentRemuneration === true}
                  onBlur={({ target }) => setInvalidRemunerationPeriod(!target.value)}
                  error={invalidRemunerationPeriod}
                  helperText={invalidRemunerationPeriod && t('required.field')}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Typography
          color="error"
          variant="caption"
          style={{ visibility: isRejected ? 'visible' : 'hidden' }}
        >
          {t('form.generic.error.message')}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => history.goBack()}
          disabled={isPending}
        >
          {t('go.back')}
        </Button>
        <Button
          className={classes.submitButton}
          form="addCouponForm"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={submitDisabled}
        >
          {
            isPending && (
              <CircularProgress
                className={classes.buttonProgress}
                color="primary"
                size={20}
              />
            )
          }
          {t((edit && 'commom.save') || 'coupons.add.coupon.submit.button.label')}
        </Button>
      </DialogActions>
      <Typography
        variant="caption"
        color="textSecondary"
      >
        {`* ${t('required.fields')}`}
      </Typography>
    </Dialog>
  );
};

export default AddCouponModal;
