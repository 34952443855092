import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '../../../../../components';
import { status as listOfStatus } from '../../../../../utils';
import useStyles from './styles';

const Tbody = ({ instance, status, message }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  return (
    <TableBody {...getTableBodyProps()}>
      {
        (
          status === listOfStatus.PENDING && (
            <TableRow>
              <TableCell
                colSpan={instance.columns.length}
                align="center"
              >
                <CircularProgress
                  color="primary"
                  size={30}
                />
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED
          && !page.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t('no.data.found')}
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            page
              .map((row) => {
                prepareRow(row);

                return (
                  <TableRow {...row.getRowProps()}>
                    {
                      row
                        .cells
                        .map((cell) => {
                          const {
                            value,
                            column: {
                              id,
                              align,
                              alignBody,
                            },
                            row: {
                              original,
                            },
                          } = cell;

                          let component = null;

                          switch (id) {
                            case 'remunerationPeriod.range.value': {
                              component = (
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.remunerationPeriod}
                                  >
                                    {original.remunerationPeriod.days.value}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.remunerationDates}
                                  >
                                    {original.remunerationPeriod.range.value}
                                  </Grid>
                                </Grid>
                              );
                              break;
                            }
                            case 'price':
                              component = (
                                <p className={classes.price}>
                                  {value}
                                </p>
                              );
                              break;
                            case 'influencerRevenue':
                              component = (
                                <p className={classes.influencerRevenue}>
                                  {value}
                                </p>
                              );
                              break;
                            default:
                              component = value ? cell.render('Cell') : '-';
                              break;
                          }

                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              align={alignBody || align}
                            >
                              {component}
                            </TableCell>
                          );
                        })
                    }
                  </TableRow>
                );
              })
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t(message)}
              </TableCell>
            </TableRow>
          )
        )
      }
    </TableBody>
  );
};

Tbody.propTypes = {
  instance: PropTypes.shape({
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
    page: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tbody;
