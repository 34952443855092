import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg'
import './SortArrow.scss';
import arrow from '../../assets/img/arrow-up.svg';

const SortArrow = ({ enabled, down, className, ...rest }) => {
  
  const finalClassName = `sortArrow ${enabled ? 'sortArrow-enabled' : ''} ${down ? 'sortArrow-down' : ''} ${className}`;

  return <ReactSVG src={arrow} className={finalClassName} {...rest}/>;
};

export default SortArrow;

SortArrow.propTypes = {
  enabled: PropTypes.bool,
  down: PropTypes.bool,
};

SortArrow.defaultProps = {
  enabled: false,
  down: false,
};

