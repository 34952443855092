const onDutySortFunction = (rowA, rowB, columnId, desc) => {
  if (desc) {
    return rowA.values.onDuty ? -1 : 1;
  }
  return rowB.values.onDuty ? 1 : -1;
};

const checkboxFilter = (rows, id, filterValue) => {
  if (!filterValue) {
    return rows;
  }
  const availableValues = filterValue
    .filter((option) => option.checked)
    .map((option) => option.value);

  if (availableValues.length === 0) {
    return [];
  }

  return rows.filter((row) => {
    const rowValue = row.values[id];
    return availableValues.includes(rowValue);
  });
};

export {
  checkboxFilter,
  onDutySortFunction,
};
