import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  Button,
} from '../index';

const TableErrorFallback = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
    >
      <Typography color="error">
        {t('generic.error.message')}
      </Typography>
      <Button
        variant="text"
        color="primary"
        size="small"
        onClick={resetErrorBoundary}
      >
        {t('reload.button')}
      </Button>
    </Grid>
  );
};

TableErrorFallback.propTypes = {
  resetErrorBoundary: func.isRequired,
};

export default TableErrorFallback;