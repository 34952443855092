export default [
  'Acupuntura',
  'Administração em Saúde',
  'Alergia e Imunologia',
  'Alergia e Imunologia Pediátrica',
  'Anestesiologia',
  'Angiologia',
  'Angiorradiologia e Cirurgia Endovascular',
  'Áreas de Atuação reconhecidas',
  'Atendimento ao queimado',
  'Cancerologia',
  'Cardiologia',
  'Cardiologia Pediátrica',
  'Cirurgia Cardiovascular',
  'Cirurgia Crânio-Maxilo-Facial',
  'Cirurgia da Mão',
  'Cirurgia de Cabeça e Pescoço',
  'Cirurgia do Aparelho Digestivo',
  'Cirurgia do Trauma',
  'Cirurgia Geral',
  'Cirurgia Pediátrica',
  'Cirurgia Plástica',
  'Cirurgia Torácica',
  'Cirurgia Vascular',
  'Cirurgia Videolaparoscópica',
  'Citopatologia',
  'Clínica Médica',
  'Clínica Geral',
  'Coloproctologia',
  'Densitometria Óssea',
  'Dermatologia',
  'Dor',
  'Ecocardiografia',
  'Ecografia Vascular com Doppler',
  'Eletrofisiologia Clínica Invasiva',
  'Endocrinologia e Metabologia',
  'Endocrinologia Pediátrica',
  'Endoscopia',
  'Endoscopia Digestiva',
  'Endoscopia Ginecológica',
  'Endoscopia Respiratória',
  'Ergometria',
  'Foniatria',
  'Gastroenterologia',
  'Gastroenterologia Pediátrica',
  'Genética Médica',
  'Geriatria',
  'Ginecologia e Obstetrícia',
  'Hansenologia',
  'Hematologia e Hemoterapia',
  'Hematologia e Hemoterapia Pediátrica',
  'Hemodinâmica e Cardiologia Intervencionista',
  'Hepatologia',
  'Homeopatia',
  'Infectologia',
  'Infectologia Hospitalar',
  'Infectologia Pediátrica',
  'Mamografia',
  'Mastologia',
  'Medicina de Família e Comunidade',
  'Medicina de Tráfego',
  'Medicina de Urgência',
  'Medicina do Adolescente',
  'Medicina do Sono',
  'Medicina do Trabalho',
  'Medicina Esportiva',
  'Medicina Fetal',
  'Medicina Física e Reabilitação',
  'Medicina Intensiva',
  'Medicina Intensiva Pediátrica',
  'Medicina Legal e Perícia Médica',
  'Medicina Nuclear',
  'Medicina Paliativa',
  'Medicina Preventiva e Social',
  'Medicina Tropical',
  'Nefrologia',
  'Nefrologia Pediátrica',
  'Neonatologia',
  'Neurocirurgia',
  'Neurofisiologia Clínica',
  'Neurologia',
  'Neurologia Pediátrica',
  'Neurorradiologia',
  'Nutrição Parenteral e Enteral',
  'Nutrição Parenteral e Enteral Pediátrica',
  'Nutrologia',
  'Nutrologia Pediátrica',
  'Oftalmologia',
  'Ortopedia e Traumatologia',
  'Otorrinolaringologia',
  'Patologia',
  'Patologia Clínica/Medicina Laboratorial',
  'Pediatria',
  'Pneumologia',
  'Pneumologia Pediátrica',
  'Psicogeriatria',
  'Psicoterapia',
  'Psiquiatria',
  'Psiquiatria da Infância e Adolescência',
  'Psiquiatria Forense',
  'Radiologia e Diagnóstico por Imagem',
  'Radiologia Intervencionista e Angiorradiologia',
  'Radioterapia',
  'Reumatologia',
  'Reumatologia Pediátrica',
  'Sexologia',
  'Transplante de Medula Óssea',
  'Ultrassonografia em Ginecologia e Obstetrícia',
  'Urologia',
];
