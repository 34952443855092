import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '../card';
import {
  formatAsCurrency,
  status as listOfStatus,
} from '../../../../utils';

const BalanceAvailableCard = ({ amount, status }) => (
  <Card
    title="Saldo disponível"
    variant="success"
  >
    <div className="balance-available-card">
      {
        (
          status === listOfStatus.PENDING && (
            <CircularProgress
              color="primary"
              size={20}
            />
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <p>Ocorreu um erro</p>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            <p className="balance-available-card__value">
              {formatAsCurrency(amount)}
            </p>
          )
        )
      }
    </div>
  </Card>
);

BalanceAvailableCard.propTypes = {
  amount: PropTypes.number,
  status: PropTypes.string,
};

BalanceAvailableCard.defaultProps = {
  amount: undefined,
  status: listOfStatus.IDLE,
};

export default BalanceAvailableCard;
