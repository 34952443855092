import React, {
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Pagination,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import Tbody from './components/tbody';
import { TelemedicineContext } from '../../../../context';

const TableOfOpenCalls = () => {
  const {
    state,
    dispatch,
    actionTypes,
  } = useContext(TelemedicineContext);
  const { t } = useTranslation();
  const {
    status,
    data,
    message,
    setRequest,
  } = useAxios(state.openCallsRequest, []);

  useEffect(() => setRequest(state.openCallsRequest), [state.openCallsRequest, setRequest]);

  useEffect(() => dispatch({
    type: actionTypes.setOpenCalls,
    payload: data,
  }), [data, dispatch, actionTypes.setOpenCalls]);

  const columns = useMemo(() => [
    {
      Header: t('telemedicine.open.calls.table.patientName.column.title'),
      accessor: 'patientName',
    },
    {
      Header: t('telemedicine.open.calls.table.requestDate.column.title'),
      accessor: 'requestDate',
      align: 'center',
    },
    {
      Header: t('telemedicine.open.calls.table.houseCallStatus.column.title'),
      accessor: 'houseCallStatus',
      align: 'center',
    },
    {
      Header: t('telemedicine.open.calls.table.actions.column.title'),
      accessor: 'actions',
      align: 'center',
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data: state.openCalls,
      initialState: {
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfOpenCalls;
