import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
} from 'react-table';
import moment from 'moment';
import {
  Table,
  Thead,
  Pagination,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import Tbody from './tbody';
import { InfluencersContext } from '../../../../context';
import {
  formatAsDate,
  formatAsDatetime,
  formatAsCurrency,
} from '../../../../utils';

const TableOfInfluencers = () => {
  const { t } = useTranslation();
  const {
    state,
    dispatch,
    actionTypes,
  } = useContext(InfluencersContext);
  const {
    status,
    data,
    message,
  } = useAxios({
    url: '/housecall/compensation',
    method: 'GET',
  }, []);

  const filterRequestDate = (rows, _id, filterValue) => rows
    .filter((row) => {
      const { original } = row.original.requestDate;

      const requestDate = moment(original);
      const filterDate = moment(filterValue);

      return (
        filterDate.month() === requestDate.month()
        && filterDate.year() === requestDate.year()
      );
    });

  useEffect(() => dispatch({
    type: actionTypes.setInfluencers,
    payload: data
      .map((req) => {
        const start = req.remunerationDateStart
          ? formatAsDate(req.remunerationDateStart)
          : null;
        const finish = req.remunerationDateFinish
          ? formatAsDate(req.remunerationDateFinish)
          : null;

        return ({
          influencerName: req.influencerName,
          patientName: `${req.patientName} ${req.patientLastName}`,
          remunerationPeriod: {
            range: {
              original: {
                start: req.remunerationDateStart,
                finish: req.remunerationDateFinish,
              },
              value: (start && finish && `${start} a ${finish}`) || '-',
            },
            days: {
              original: req.remunerationPeriod,
              value: `${req.remunerationPeriod} DIAS`,
            },
          },
          couponCode: req.couponCode,
          remunerationPercent: `${req.remunerationPercent}%`,
          requestDate: {
            original: req.requestDate,
            value: req.requestDate ? formatAsDatetime(req.requestDate) : '-',
          },
          price: req.price ? formatAsCurrency(req.price, 1) : '-',
          influencerRevenue: req.influencerRevenue ? formatAsCurrency(req.influencerRevenue, 1).toString() : '-',
        });
      }),
  }), [data, dispatch, actionTypes.setInfluencers]);

  useEffect(() => {
    dispatch({
      type: actionTypes.setColumns,
      payload: [
        {
          Header: t('pay.influencer.table.influencerName.column.title'),
          accessor: 'influencerName',
          alignHead: 'left',
          alignBody: 'left',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.patientName.column.title'),
          accessor: 'patientName',
          alignHead: 'left',
          alignBody: 'left',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.remunerationPeriod.range.column.title'),
          accessor: 'remunerationPeriod.range.value',
          alignHead: 'center',
          alignBody: 'center',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.remunerationPeriod.days.column.title'),
          accessor: 'remunerationPeriod.days.value',
          alignHead: 'center',
          alignBody: 'center',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.couponCode.column.title'),
          accessor: 'couponCode',
          alignHead: 'center',
          alignBody: 'center',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.remunerationPercent.column.title'),
          accessor: 'remunerationPercent',
          alignHead: 'center',
          alignBody: 'center',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.requestDate.column.title'),
          accessor: 'requestDate.value',
          alignHead: 'center',
          alignBody: 'center',
          filter: filterRequestDate,
        },
        {
          Header: t('pay.influencer.table.price.column.title'),
          accessor: 'price',
          alignHead: 'center',
          alignBody: 'right',
          disableFilters: true,
        },
        {
          Header: t('pay.influencer.table.influencerRevenue.column.title'),
          accessor: 'influencerRevenue',
          alignHead: 'center',
          alignBody: 'right',
          disableFilters: true,
        },
      ],
    });
  }, [t, dispatch, actionTypes.setColumns]);

  const instance = useTable(
    {
      columns: state.columns,
      data: state.influencers,
      initialState: {
        pageSize: 10,
        hiddenColumns: ['remunerationPeriod.days.value'],
        filters: [{
          id: 'requestDate.value',
          value: state.monthAndYear,
        }],
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    setFilter,
    setAllFilters,
    rows,
  } = instance;

  useEffect(() => {
    dispatch({
      type: actionTypes.setFilteredInfluencers,
      payload: rows.map(({ values }) => values),
    });
  }, [rows, dispatch, actionTypes.setFilteredInfluencers]);

  const resetFilter = useCallback(() => {
    const today = moment();

    const payload = moment({
      day: today.startOf('month').date(),
      month: today.month(),
      year: today.year(),
    }).valueOf();

    dispatch({
      type: actionTypes.setMonthAndYear,
      payload,
    });
  }, [actionTypes.setMonthAndYear, dispatch]);

  useEffect(() => {
    resetFilter();
  }, [resetFilter]);

  useEffect(() => {
    if (state.monthAndYear) {
      setFilter('requestDate.value', state.monthAndYear);
    } else {
      setAllFilters([]);
    }
  }, [state.monthAndYear, setFilter, setAllFilters, resetFilter]);

  return (
    <>
      <Table {...getTableProps()}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfInfluencers;
