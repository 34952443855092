import * as XLSX from 'xlsx';

const downloadExcel = (excelData, docTitle, header) => {
  const worksheet = XLSX.utils.json_to_sheet([{}], {
    header,
  });
  const workbook = XLSX.utils.book_new();

  const worksheetCols = [
    { width: 15 },
    { width: 20 },
    { width: 30 },
    { width: 15 },
    { width: 20 },
    { width: 40 },
  ];

  worksheet['!cols'] = worksheetCols;

  XLSX.utils.book_append_sheet(workbook, worksheet);
  XLSX.utils.sheet_add_json(worksheet, excelData, {
    skipHeader: true,
    origin: 'A2',
  });
  XLSX.writeFile(workbook, `${docTitle}.xlsx`);
};

export default downloadExcel;
