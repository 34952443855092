import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { status as listOfStatus } from '../../utils';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
} from '../';

const ConfirmActionModal = (props) => {
  const {
    status,
    open,
    text,
    handleClose,
    handleCloseText,
    handleConfirm,
    handleConfirmText,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      transitionDuration={500}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
        {
          status === listOfStatus.REJECTED && (
            <Typography
              variant="caption"
              color="error"
              align="right"
            >
              {t('error.and.try.again.message')}
            </Typography>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          disabled={status === listOfStatus.PENDING}
          color="default"
        >
          {handleCloseText}
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={status === listOfStatus.PENDING}
          color="secondary"
          autoFocus
        >
          {
            (
              status === listOfStatus.PENDING && (
                <CircularProgress
                  color="primary"
                  size={20}
                />
              )
            ) || handleConfirmText
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmActionModal.propTypes = {
  status: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string,
  handleClose: PropTypes.func,
  handleCloseText: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleConfirmText: PropTypes.string,
  error: PropTypes.string,
};

ConfirmActionModal.defaultProps = {
  text: 'Confirm?',
  handleClose: () => {},
  handleCloseText: 'Cancel',
  handleConfirm: () => {},
  handleConfirmText: 'Confirm',
  error: '',
};

export default ConfirmActionModal;