import React, {
  useReducer,
  useContext,
  useEffect,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
} from '../../components';
import TableOfOpenCalls from './components/tableOfOpenCalls';
import TableOfAcceptedCalls from './components/tableOfAcceptedCalls';
import AcceptCallModal from './components/acceptCallModal';
import {
  TelemedicineContext,
  NotificationContext,
} from '../../context';
import {
  reducer,
  initialState,
  actionTypes,
} from './reducer';

const telemedicineMessages = [
  'telemedicine.requested',
  'telemedicine.attendance.confirmed',
  'telemedicine.attendance.started',
  'telemedicine.attendance.finished',
  'telemedicine.attendance.canceled',
  'telemedicine.attendance.not.confirmed',
  'telemedicine.attendance.canceled.patient',
  'telemedicine.attendance.canceled.provider',
];

const TelemedicineCalls = () => {
  const { message } = useContext(NotificationContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();

  useEffect(() => {
    if (message && telemedicineMessages.includes(message)) {
      dispatch({ type: actionTypes.reloadOpenCalls });
      dispatch({ type: actionTypes.reloadAcceptedCalls });
    }
  }, [message]);

  return (
    <TelemedicineContext.Provider
      value={{
        state,
        dispatch,
        actionTypes,
      }}
    >
      <div className="content">
        <AcceptCallModal />
        <Row>
          <Col md={12}>
            <ContentCard
              title={t('telemedicine.open.calls.table.title')}
              ctTableFullWidth
              ctTableResponsive
              content={(
                <ErrorBoundary
                  fallback={TableErrorFallback}
                  fallbackRender={TableErrorFallback}
                  FallbackComponent={TableErrorFallback}
                >
                  <TableOfOpenCalls />
                </ErrorBoundary>
              )}
            />
          </Col>
          <Col md={12}>
            <ContentCard
              title={t('telemedicine.accepted.calls.table.title')}
              ctTableFullWidth
              ctTableResponsive
              content={(
                <ErrorBoundary
                  fallback={TableErrorFallback}
                  fallbackRender={TableErrorFallback}
                  FallbackComponent={TableErrorFallback}
                >
                  <TableOfAcceptedCalls />
                </ErrorBoundary>
              )}
            />
          </Col>
        </Row>
      </div>
    </TelemedicineContext.Provider>
  );
};

export default TelemedicineCalls;
