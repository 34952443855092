import React from 'react';
import { func } from 'prop-types';
import MaskedInput from 'react-text-mask';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '../../../../components';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MultiSelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const countriesMultiSelectOptions = [
  'BR',
  'US',
];

export const languagesMultiSelectOptions = [
  'Português',
  'Espanhol',
  'Inglês',
];

export const languagesAndInitials = [
  {
    language: 'Português',
    initials: 'pt-BR',
  },
  {
    language: 'Espanhol',
    initials: 'es-ES',
  },
  {
    language: 'Inglês',
    initials: 'en-US',
  },
];

export const Dialog = withStyles(() => ({
  paper: {
    minWidth: 800,
    minHeight: 400,
    padding: 0,
  },
}))(MuiDialog);

export const DialogTitle = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))(MuiDialogTitle);

export const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(8),
  },
}))(MuiDialogActions);

const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

export const CpfInputMask = (props) => (
  <TextMaskCustom {...props} mask={cpfMask} />
);

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      placeholderChar={'\u2000'}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: func.isRequired,
};
