import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, FormControl } from 'react-bootstrap';
import Checkbox from '../../../components/checkbox/Checkbox';

const SelectColumnFilter = ({ data, column }) => {
  const { filterValue, setFilter, id, Header } = column;
  const options = React.useMemo(() => {
    const optionsSet = new Set();
    data.forEach((row) => {
      if (Array.isArray(row[id])) {
        row[id].forEach((value) => optionsSet.add(value));
      } else {
        optionsSet.add(row[id]);
      }
    });
    return [...optionsSet.values()];
  }, [id, data]);

  const [selectValue, setSelectValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!filterValue) {
      setSelectValue('');
    }
  }, [filterValue]);

  const handleOnChange = (newValue) => {
    setSelectValue(newValue);
    setFilter(newValue);
  };

  return (
    <Col sm={3}>
      <p className="filtersLabel">{Header}</p>
      <select
        className="select"
        value={selectValue}
        onChange={(e) => handleOnChange(e.target.value)}
      >
        <option value="">Todos</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {t(option)}
          </option>
        ))}
      </select>
    </Col>
  );
};

const onDutyOptions = [
  {
    label: 'Disponível',
    checked: false,
    value: true,
  },
  {
    label: 'Indisponível',
    checked: false,
    value: false,
  },
];

const statusOption = [
  {
    label: 'offline',
    checked: false,
    value: 'OFFLINE',
  },
  {
    label: 'online',
    checked: false,
    value: 'AVAILABLE',
  },
  {
    label: 'em trânsito',
    checked: false,
    value: 'IN_TRANSIT',
  },
  {
    label: 'em atendimento',
    checked: false,
    value: 'IN_A_HOUSECALL',
  },
];

const OnDutyColumnFilter = ({ column }) => (
  <ColumnFilter
    column={column}
    defaultOptions={onDutyOptions}
    columnSize={3}
    title="Disponibilidade"
  />
);

const StatusColumnFilter = ({ column }) => (
  <ColumnFilter column={column} defaultOptions={statusOption} columnSize={7} title="Status" />
);

const ColumnFilter = ({ defaultOptions, column, columnSize, title }) => {
  const { setFilter, filterValue } = column;
  const [options, setOptions] = useState(defaultOptions);

  useEffect(() => {
    if (!filterValue) {
      setOptions(defaultOptions);
    }
  }, [filterValue, defaultOptions]);

  const handleOnChange = (label, checked) => {
    setOptions((oldOptions) => {
      const newOptions = oldOptions.map((option) =>
        option.label === label ? { ...option, checked: !checked } : option,
      );

      setFilter(newOptions);
      return newOptions;
    });
  };

  return (
    <Col sm={columnSize}>
      <p className="filtersLabel">{title}</p>
      {options.map(({ label, checked }) => (
        <Checkbox
          key={label}
          onChange={() => handleOnChange(label, checked)}
          label={label}
          checked={checked}
        />
      ))}
    </Col>
  );
};

const DefaultColumnFilter = ({ column }) => {
  const { filterValue, setFilter, Header } = column;

  return (
    <Col sm={3}>
      <p className="filtersLabel">{Header}</p>
      <FormControl
        type="text"
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value || undefined)}
        placeholder={`Digite o ${Header.toLowerCase()} do profissional`}
      />
    </Col>
  );
};

export { StatusColumnFilter, OnDutyColumnFilter, SelectColumnFilter, DefaultColumnFilter };

StatusColumnFilter.propTypes = {
  column: PropTypes.objectOf(PropTypes.any).isRequired,
};

OnDutyColumnFilter.propTypes = {
  column: PropTypes.objectOf(PropTypes.any).isRequired,
};

SelectColumnFilter.propTypes = {
  column: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.objectOf(PropTypes.any).isRequired,
};

ColumnFilter.propTypes = {
  defaultOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  column: PropTypes.objectOf(PropTypes.any).isRequired,
  columnSize: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};
