import axios, { clearUserToken, setUserToken } from '../api/index';
import analytics from '../analytics';

const login = async (mail, password) => axios.post('users/login', { mail, password })
  .then(({ data }) => {
    const {
      token, id, companyId, name,
    } = data.user;

    analytics.identify(mail);
    analytics.people.set({
      $name: name,
      $email: mail,
      companyId,
    });

    analytics.track('Login realizado');

    localStorage.setItem('token', token);
    localStorage.setItem('id', id);
    localStorage.setItem('name', name);
    localStorage.setItem('mail', mail);

    setUserToken(token, id);
    localStorage.setItem('companyId', companyId);
    return true;
  })
  .catch(() => false);

const logout = async () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('id');
  localStorage.clear();
  clearUserToken();

  return axios.post('user/logout', { userId, token })
    .then(() => true)
    .catch(() => false);
};

const isLogged = () => {
  const token = localStorage.getItem('token');
  return !!token;
};

const id = () => localStorage.getItem('id');
const companyId = () => localStorage.getItem('companyId');
const token = () => localStorage.getItem('token');
const name = () => localStorage.getItem('name');
const mail = () => localStorage.getItem('mail');

const getUser = () => ({
  id: id(),
  companyId: companyId(),
  name: name(),
  mail: mail(),
  token: token(),
});

const userService = {
  login,
  isLogged,
  logout,
  companyId,
  token,
  name,
  mail,
  getUser,
};

export default userService;
