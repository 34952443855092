import React from 'react';
import {
  func, element, string, bool,
} from 'prop-types';
import { Button } from '../../../../components';

const Card = ({
  title, subtitle,
  action, actionLabel, actionDisabled,
  children, variant,
}) => (
  <div className={`financials-panel__card financials-panel__card--${variant}`}>
    <div className="financials-panel__card__head">
      <div className="financials-panel__card__column">
        <p className="financials-panel__card__title">{title}</p>
        <p className="financials-panel__card__subtitle">{subtitle}</p>
      </div>
      {
        action && (
          <Button
            variant="text"
            disabled={actionDisabled}
          >
            {actionLabel}
          </Button>
        )
      }
    </div>
    <div className="financials-panel__card__body">
      {children}
    </div>
  </div>
);

Card.propTypes = {
  title: string.isRequired,
  subtitle: string,
  action: func,
  actionLabel: string,
  actionDisabled: bool,
  children: element,
  variant: string,
};

Card.defaultProps = {
  subtitle: null,
  action: null,
  actionLabel: null,
  actionDisabled: false,
  children: null,
  variant: 'white',
};

export default Card;
