export const actionTypes = {
  resetProfessionalRequest: 'RESET_PROFESSIONALS_REQUEST',
};

export const initialState = {
  professionalsRequest: {
    url: 'backoffice/doctors/all',
    method: 'GET',
  },
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.resetProfessionalRequest:
      return {
        ...state,
        professionalsRequest: {
          ...initialState.professionalsRequest,
        },
      };
    default:
      throw new Error();
  }
}
