const commom = {
  'city.br.rs.porto.alegre.name': 'Porto Alegre',
  'city.br.sc.joinville.name': 'Joinville',
  'medical.specialty.name.emergency': 'Ambulância',
  'medical.specialty.name.telemedicine': 'Telemedicina',
  'medical.specialty.name.aeromedical': 'Aeromédico',
  'medical.specialty.name.general.clinic': 'Clínico Geral',
  'medical.specialty.name.nursing': 'Enfermagem',
  'doctor.crud.error.document.number.cpf.invalid': 'CPF inválido.',
  'doctor.crud.error.mail.already.registered': 'Já existe um médico cadastrado com este e-mail.',
  'network.error.message': 'Ocorreu um erro. Verifique sua conexão com a internet e tente novamente.',
  'generic.error.message': 'Ocorreu um erro.',
  'form.generic.error.message': 'Ocorreu um erro. Verifique as informações e tente novamente.',
  'no.data.found': 'Nenhum resultado encontrado.',
  'reload.button': 'Recarregar',
  'go.back': 'Voltar',
  'clear.filters': 'Limpar filtros',
  export: 'Exportar',
  clear: 'Limpar',
  'commom.yes': 'Sim',
  'commom.no': 'Não',
  'required.field': 'campo obrigatório',
  'required.fields': 'campos obrigatórios',
  'error.and.try.again.message': 'Ocorreu um erro, tentar novamente?',
  'commom.delete': 'Excluir',
  'commom.edit': 'Editar',
  'commom.cancel': 'Cancelar',
  'commom.active': 'Ativo',
  'commom.inactive': 'Inativo',
  'commom.save': 'Salvar',
};

const openHouseCalls = {
  'openHouseCalls.page.title': 'Regulação de ambulâncias',
  'openHouseCalls.table.patientName.column.title': 'Nome do paciente',
  'openHouseCalls.table.patientCellphone.column.title': 'Telefone para contato',
  'openHouseCalls.table.requestAddress.column.title': 'Endereço',
  'openHouseCalls.table.houseCallStatus.column.title': 'Status',
  'openHouseCalls.table.actions.column.title': 'Ações',
};

const acceptedHouseCalls = {
  'acceptedHouseCalls.table.patientName.column.title': 'Nome do paciente',
  'acceptedHouseCalls.table.patientCellphone.column.title': 'Telefone para contato',
  'acceptedHouseCalls.table.requestAddress.column.title': 'Endereço',
  'acceptedHouseCalls.table.houseCallStatus.column.title': 'Acompanhamento',
  'acceptedHouseCalls.table.doctorName.column.title': 'Unidade de Atendimento',
};

const houseCalls = {
  'houseCalls.page.title': 'Histórico de atendimentos',
  'houseCalls.table.medicalSpecialtyName.column.title': 'Serviço',
  'houseCalls.table.patientName.column.title': 'Cliente',
  'houseCalls.table.requestAddress.column.title': 'Endereço',
  'houseCalls.table.doctorName.column.title': 'Profissional',
  'houseCalls.table.companyName.column.title': 'Parceira',
  'houseCalls.table.houseCallStatus.column.title': 'Status',
  'houseCalls.table.paymentPending.column.title': 'Pagamento',
  'houseCalls.table.startedDate.column.title': 'Datas',
  'houseCalls.table.actions.column.title': 'Ações',
  'houseCalls.calls.table.dates.column.requestDate.tag': 'Solicitado',
  'houseCalls.calls.table.dates.column.acceptDate.tag': 'Aceito',
  'houseCalls.calls.table.dates.column.startedDate.tag': 'Iniciado',
  'houseCalls.calls.table.dates.column.finishedDate.tag': 'Finalizado',
  'houseCalls.confirm.end.call.message': 'Finalizar o atendimento?',
  'houseCalls.confirm.end.call.button.message': 'Finalizar',
  REQUESTED: 'SOLICITADO',
  WAITING: 'AGUARDANDO',
  NOT_STARTED: 'NÃO INICIADO',
  STARTED: 'INICIADO',
  CANCELED: 'CANCELADO',
  FINISHED: 'FINALIZADO',
};

const professionals = {
  'professionals.page.title': 'Profissionais',
  'professionals.contentCard.title': 'Busca de profissionais',
  'professionals.addDoctor.generic.error.message': 'Não foi possível cadastrar as informações disponibilizadas. Verifique os campos selecionados.',
  'professionals.table.name.column.title': 'Nome',
  'professionals.table.mail.column.title': 'E-mail',
  'professionals.table.companyName.column.title': 'Parceira',
  'professionals.table.onDuty.column.title': 'Atividade',
  'professionals.table.doctorStatus.column.title': 'Status',
  'professionals.table.currentLocation.column.title': 'Última Localização',
  'professionals.table.actions.column.title': 'Ações',
  'professionals.add.ambulance.button.label': 'adicionar ambulância',
  'professionals.add.doctor.button.label': 'adicionar teleconsulta',
};

const financials = {
  'financials.page.title': 'Painel Financeiro',
  'financials.table.createdAt.column.title': 'data',
  'financials.table.transactionId.column.title': 'transação',
  'financials.table.status.column.title': 'status',
  'financials.table.amount.column.title': 'valor',
  'financials.table.fee.column.title': 'descontos',
  'financials.table.total.column.title': 'total',
};

const leads = {
  'leads.page.title': 'Leads Plano de Saúde',
  'leads.table.title': 'Lista de contatos',
  'leads.table.creationDate.column.title': 'data da simulação',
  'leads.table.name.column.title': 'nome do usuário',
  'leads.table.email.column.title': 'e-mail',
  'leads.table.cpf.column.title': 'dados da simulação',
  'leads.table.phoneNumber.column.title': 'número para contato',
  'leads.table.insurancePrice.column.title': 'valor simulado',
};

const addCouponModal = {
  'add.coupon.modal.identification.legend': 'Identificação',
  'add.coupon.modal.code.label': 'Código do cupom',
  'add.coupon.modal.savingPercent.label': '% de desconto',
  'add.coupon.modal.savingPercent.rule.message': 'O percentual de desconto deve estar entre 1% e 85%, ou ser igual a 100%',
  'add.coupon.modal.validity.legend': 'Validade do cupom',
  'add.coupon.modal.type.label': 'Quando é válido?',
  'add.coupon.modal.type.FIRST_TIME.menu.item.text': 'Somente 1º uso',
  'add.coupon.modal.type.SEASONAL.menu.item.text': 'Em qualquer uso',
  'add.coupon.modal.validPeriodStart.label': 'Data início',
  'add.coupon.modal.validPeriodFinish.label': 'Data fim',
  'add.coupon.modal.invalidValidPeriodFinish.message': 'A data fim deve ser maior que a data inicio',
  'add.coupon.modal.influencer.remuneration.legend': 'Influencer e remuneração',
  'add.coupon.modal.validHoursStart.label': 'Hora início',
  'add.coupon.modal.validHoursFinish.label': 'Hora fim',
  'add.coupon.modal.invalidValidHoursFinish.message': 'A hora fim deve ser maior que a hora inicio',
  'add.coupon.modal.influencerName.label': 'Nome do influenciador',
  'add.coupon.modal.influencerMail.label': 'E-mail do influenciador',
  'add.coupon.modal.invalidInfluencerMail.message': 'E-mail inválido',
  'add.coupon.modal.influencerType.label': 'Tipo',
  'add.coupon.modal.influencerType.INFLUENCER.menu.item.text': 'Influencer',
  'add.coupon.modal.influencerType.BROKER.menu.item.text': 'Corretor',
  'add.coupon.modal.subsequentRemuneration.label': 'Esse cupom remunera o influencer?',
  'add.coupon.modal.remunerationFeePercent.label': '% de remuneração',
  'add.coupon.modal.remunerationFeePercent.rule.message': 'O percentual deve estar entre 1% e 100%',
  'add.coupon.modal.remunerationPeriod.label': 'Período em dias da remuneração',
  'add-coupon-modal-useQuantity': 'Quantidade de uso',
  'add-coupon-modal-unlimitedCoupon': 'Uso ilimitado',
  'error.coupon.code.already.exists': 'Este código de cupom já está em uso. Crie um código novo.',
};

const coupons = {
  ...addCouponModal,
  'coupons.sidebar.button.title': 'Cupons',
  'coupons.register.page.title': 'Adicionar cupom',
  'coupons.usage.history.page.title': 'Histórico de cupons',
  'coupons.table.title': 'Listagem de cupom de desconto',
  'coupons.table.code.column.title': 'cupom',
  'coupons.table.validPeriod.column.title': 'validade',
  'coupons.table.validHours.column.title': 'horário',
  'coupons.table.active.column.title': 'status',
  'coupons.table.type.column.title': '1º uso',
  'coupons.table.remuneration.column.title': 'período de remuneração',
  'coupons.table.influencer.column.title': 'influencer',
  'coupons.table.totalUses.column.title': 'nº de usos',
  'coupons.table.actions.column.title': 'ações',
  'coupons.add.coupon.button.label': 'Adicionar cupom',
  'coupons.add.coupon.submit.button.label': 'Adicionar',
  'coupons.add.success.notification.title': 'CUPOM CADASTRADO',
  'coupons.add.success.notification.message': 'Cupom cadastrado com sucesso',
  'coupons.edit.success.notification.title': 'CUPOM ATUALIZADO',
  'coupons.edit.success.notification.message': 'Cupom atualizado com sucesso',
  'coupons.delete.success.notification.title': 'CUPOM EXCLUÍDO',
  'coupons.delete.success.notification.message': 'Cupom excluído com sucesso',
  'coupons.delete.confirm.message': 'Excluir o cupom',
  'coupons.usage.history.table.title': 'Listagem de cupons utilizados',
  'coupons.usage.history.table.usage.date.column.title': 'Data do uso',
  'coupons.usage.history.table.coupon.column.title': 'Cupom',
  'coupons.usage.history.table.patient.column.title': 'Paciente',
  'coupons.usage.history.table.original.value.column.title': 'Valor original',
  'coupons.usage.history.table.value.with.coupon.column.title': 'Valor com cupom',
  'coupons.usage.history.table.influencer.column.title': 'Influencer',
  'coupons.usage.history.excel.file.name': 'lista-de-cupons-utilizados',
};

const telemedicine = {
  'telemedicine.page.title': 'Regulação de Telemedicina',
  'telemedicine.open.calls.table.title': 'Solicitações abertas',
  'telemedicine.open.calls.table.patientName.column.title': 'nome do paciente',
  'telemedicine.open.calls.table.requestDate.column.title': 'horário da solicitação',
  'telemedicine.open.calls.table.houseCallStatus.column.title': 'status',
  'telemedicine.accepted.calls.table.houseCallStatus.column.value.request': 'Solicitado',
  'telemedicine.open.calls.table.actions.column.title': 'ações',
  'telemedicine.accepted.calls.table.title': 'Solicitações aceitas',
  'telemedicine.accepted.calls.table.patientName.column.title': 'nome do paciente',
  'telemedicine.accepted.calls.table.doctorName.column.title': 'médico',
  'telemedicine.accepted.calls.table.attendance.column.title': 'acompanhamento',
  'telemedicine.accept.call.modal.title': 'Aceitar solicitação',
  'telemedicine.accept.call.modal.subtitle': 'Antes de aceitar a solicitação, defina um médico que irá atender a este chamado:',
  'telemedicine.accept.call.modal.remember': 'Lembre-se: Depois de aceita por você, esta solicitação ainda terá de ser confirmada pelo paciente que a solicitou. Aguarde esta confirmação para acompanhar o atendimento.',
  'telemedicine.accept.call.modal.doctor.select.label': 'Médico',
  'telemedicine.accept.call.modal.doctor.select.loading.message': 'Carregando lista de médicos...',
  'telemedicine.accept.call.modal.doctor.select.message': 'Selecione um médico',
  'telemedicine.accept.call.modal.doctor.select.error.message': 'Não foi possível carregar a lista de médicos',
  'telemedicine.accept.call.modal.doctor.select.no.doctor.available.message': 'Não há médicos disponíveis',
  'telemedicine.accept.calls.table.requestDate.tag': 'Solicitado',
  'telemedicine.accept.calls.table.acceptDate.tag': 'Aceito',
  'telemedicine.accept.calls.table.startedDate.tag': 'Iniciado',
  'telemedicine.accept.calls.table.accept.button': 'aceitar',
};

const emergencyNotifications = {
  'emergency.requested.notification.title': 'SOLICITAÇÃO DE AMBULÂNCIA',
  'emergency.requested': 'Uma ambulância esta sendo solicitada.',
  'emergency.attendance.started': 'O atendimento foi iniciado.',
  'emergency.attendance.finished': 'O atendimento foi finalizado.',
  'emergency.attendance.canceled': 'O atendimento foi cancelado.',
  'emergency.attendance.confirmed': 'O atendimento foi confirmado.',
  'emergency.attendance.not.confirmed': 'O atendimento não foi confirmado.',
  'emergency.attendance.canceled.patient': 'O atendimento foi cancelado pelo paciente.',
  'emergency.attendance.canceled.provider': 'O atendimento foi cancelado pelo profissional.',
};

const telemedicineNotifications = {
  'telemedicine.requested.notification.title': 'SOLICITAÇÃO DE TELEMEDICINA',
  'telemedicine.requested': 'Uma teleconsulta está sendo solicitada.',
  'telemedicine.attendance.started': 'O atendimento foi iniciado.',
  'telemedicine.attendance.finished': 'O atendimento foi finalizado.',
  'telemedicine.attendance.canceled': 'O atendimento foi cancelado.',
  'telemedicine.attendance.confirmed': 'O atendimento foi confirmado.',
  'telemedicine.attendance.not.confirmed': 'O atendimento não foi confirmado.',
  'telemedicine.attendance.canceled.patient': 'O atendimento foi cancelado pelo paciente.',
  'telemedicine.attendance.canceled.provider': 'O atendimento foi cancelado pelo profissional.',
};

const notifications = {
  ...emergencyNotifications,
  ...telemedicineNotifications,
  'attendance.confirmed': 'O atendimento foi confirmado.',
  'attendance.not.confirmed': 'O atendimento não foi confirmado.',
  'attendance.canceled.patient': 'O atendimento foi cancelado pelo paciente.',
  'attendance.canceled.provider': 'O atendimento foi cancelado pelo profissional.',
  'attendance.started': 'O atendimento foi iniciado.',
  'attendance.finished': 'O atendimento foi finalizado.',
  'attendance.canceled': 'O atendimento foi cancelado.',
};

const payInfluencers = {
  'pay.influencer.page.title': 'Remunerar Influencer',
  'pay.influencer.table.container.title': 'Listagem de influencer',
  'pay.influencer.table.influencerName.column.title': 'Influencer',
  'pay.influencer.table.patientName.column.title': 'Cliente',
  'pay.influencer.table.remunerationPeriod.days.column.title': 'Dias remunerados',
  'pay.influencer.table.remunerationPeriod.range.column.title': 'Período de remuneração',
  'pay.influencer.table.couponCode.column.title': 'Cupom originador',
  'pay.influencer.table.remunerationPercent.column.title': '% do influencer',
  'pay.influencer.table.requestDate.column.title': 'Data',
  'pay.influencer.table.price.column.title': 'Valor',
  'pay.influencer.table.influencerRevenue.column.title': 'Valor corrigido',
  'pay.influencer.filter.container.title': 'Filtrar',
};

const ratings = {
  'ratings.page.title': 'Avaliações',
  'ratings.table.creationDate.column.title': 'Data da avaliação',
  'ratings.table.patientName.column.title': 'Nome do paciente',
  'ratings.table.doctorName.column.title': 'Nome do médico',
  'ratings.table.medicalSpecialtyName.column.title': 'Tipo de serviço',
  'ratings.table.houseCallRatting.column.title': 'Nota da avaliação',
  'ratings.table.houseCallRattingDescription.column.title': 'Texto da avaliação',
};

const backofficeUsers = {
  'backoffice.users.page.title': 'Usuários backoffice',
  'backoffice.users.table.name.column.title': 'Nome do usuário',
  'backoffice.users.table.mail.column.title': 'Email do usuário',
  'backoffice.users.table.companyName.column.title': 'Parceira',
  'backoffice.users.table.actions.column.title': 'Ações',
  'backoffice.users.modal.company.select.loading.message': 'Carregando parceiras...',
  'backoffice.users.modal.company.select.message': 'Selecione uma parceira (opcional)',
  'backoffice.users.modal.company.select.label': 'Parceira',
  'backoffice.users.add.user.button.label': 'Adicionar usuário',
  'backoffice.users.add.user.submit.button.label': 'Adicionar',
  'user.backoffice.crud.add.mail.already': 'Email já cadastrado',
};

const dependents = {
  'dependents.page.title': 'Dependentes',
  'dependents.filter.container.title': 'Filtrar',
  'dependents.table.patientName.column.title': 'Usuário',
  'dependents.table.name.column.title': 'Dependente',
  'dependents.table.cpf.column.title': 'CPF',
  'dependents.table.creationDate.column.title': 'Data de cadastro',
  'dependents.table.exclusionDate.column.title': 'Data de exclusão',
  'dependents.search.container.patientName.label': 'Usuário',
  'dependents.search.container.name.label': 'Dependente',
};

export default {
  translation: {
    ...commom,
    ...openHouseCalls,
    ...acceptedHouseCalls,
    ...houseCalls,
    ...financials,
    ...professionals,
    ...leads,
    ...coupons,
    ...telemedicine,
    ...notifications,
    ...payInfluencers,
    ...ratings,
    ...backofficeUsers,
    ...dependents,
  },
};
