import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '../../../../components';
import Card from '../card';
import {
  formatAsCurrency,
  status as listOfStatus,
} from '../../../../utils';

const WaitingFundsCard = ({
  amount,
  status,
  message,
}) => {
  const { t } = useTranslation();

  return (
    <Card
      title="Saldo a receber"
      actionLabel="Antecipar"
      action={() => null}
      actionDisabled
      variant="alert"
    >
      <div className="waiting-funds-card">
        {
          (
            status === listOfStatus.PENDING
            && (
              <CircularProgress
                color="primary"
                size={20}
              />
            )
          ) || (
            status === listOfStatus.REJECTED
            && message
            && <p>{t(message)}</p>
          ) || (
            status === listOfStatus.RESOLVED
            && (
              <p className="waiting-funds-card__value">
                {formatAsCurrency(amount)}
              </p>
            )
          )
        }
      </div>
    </Card>
  );
};

WaitingFundsCard.propTypes = {
  amount: PropTypes.number,
  status: PropTypes.string,
  message: PropTypes.string,
};

WaitingFundsCard.defaultProps = {
  amount: undefined,
  status: listOfStatus.IDLE,
  message: '',
};

export default WaitingFundsCard;
