import api from '../api';

const getHouseCalls = async (params) => {
  const { data } = await api.get('housecall/find-by-filter', { params });
  return data;
};

const getOpenHouseCalls = async () => {
  const { data } = await api.get('housecall?status=NEW&specialty=emergency');
  return data;
};

const getAcceptedHouseCalls = async () => {
  const { data } = await api.get('housecall?houseCallStatus=NOT_STARTED,STARTED&specialty=emergency');
  return data;
};

const postAcceptCall = async (payload) => {
  const { data } = await api.post('housecall/accept', payload);
  return data;
};

const postFinishCall = (patientRequestId) => api.post('/housecall/finish', {
  patientRequestId,
});

const openHouseCallService = {
  getHouseCalls,
  getOpenHouseCalls,
  getAcceptedHouseCalls,
  postAcceptCall,
  postFinishCall,
};

export default openHouseCallService;
