import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  ContentCard, ErrorFallback,
} from '../../components';
import TableOfFinancials from './components/table';
import CardsContainer from './components/cardsContainer';

const FinancialsPanel = () => (
  <div className="content financials-panel">
    <ContentCard
      ctTableFullWidth
      ctTableResponsive
      content={<CardsContainer />}
    />

    <div className="financials-panel__table">
      <ContentCard
        title="Extrato detalhado"
        ctTableFullWidth
        ctTableResponsive
        content={
          (
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              fallbackRender={ErrorFallback}
            >
              <TableOfFinancials />
            </ErrorBoundary>
          )
        }
      />
    </div>
  </div>
);

export default FinancialsPanel;
