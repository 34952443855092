import { createTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import shape from './shape';

export default createTheme({
  palette: {
    primary: {
      main: palette.mainColor,
      dark: palette.mainColorDark,
      light: palette.mainColorLight,
      contrastText: palette.mainColorContrast,
      disabled: palette.mainColorDisabled,
    },
    text: {
      primary: palette.textColorPrimary,
      secondary: palette.textColorSecondary,
      disabled: palette.textColorDisabled,
      hint: palette.textColorContrast,
    },
    error: {
      main: palette.statusColorDanger,
      light: palette.statusColorDangerLight,
    },
    success: {
      main: palette.statusColorSuccess,
      light: palette.statusColorSuccessLight,
    },
    info: {
      main: palette.statusColorNeutral,
      light: palette.statusColorNeutralLight,
    },
    warning: {
      main: palette.statusColorAttention,
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 16,
    fontFamily: 'Quicksand',
    body1: {
      ...typography['paragraph-16px-1rem-regular'],
    },
    h1: {
      ...typography['title-27px-1.713rem-medium'],
    },
    body2: {
      ...typography['paragraph-16px-1rem-medium'],
    },
    h6: {
      ...typography['subtitle-21px-1.309rem-medium'],
    },
    caption: {
      ...typography['description-12px-0.764rem-medium'],
    },
  },
  spacing: 4,
  shape: {
    borderRadius: shape.borderRadius,
    boxShadow: shape.boxShadow,
  },
  overrides: {
    MuiCircularProgress: {
      colorPrimary: {
        color: palette.statusColorNeutral,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1rem',
        height: 31,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 0,
        marginBottom: 16,
      },
    },
    MuiFormControlLabel: {
      root: {
        margin: 0,
      },
      label: {
        color: palette.statusColorNeutral,
      },
    },
    MuiRadio: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
      },
      colorSecondary: {
        color: palette.statusColorNeutral,

        '&.Mui-checked': {
          color: palette.statusColorNeutral,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 9,
        fontWeight: 500,
        lineHeight: '10.8px',
      },
    },
    MuiButton: {
      textPrimary: {
        color: palette.statusColorNeutral,
      },
      textSecondary: {
        color: palette.statusColorDanger,
      },
      outlinedPrimary: {
        color: palette.statusColorNeutral,
        borderWidth: shape.borderWidth,
        borderStyle: shape.borderStyle,
        borderColor: palette.statusColorNeutral,
        '&:hover': {
          borderWidth: shape.borderWidth,
          borderStyle: shape.borderStyle,
          borderColor: palette.mainColor,
          backgroundColor: 'transparent',
        },
        '&.Mui-disabled': {
          borderWidth: shape.borderWidth,
          borderStyle: shape.borderStyle,
          borderColor: palette.textColorDisabled,
        },
      },
      containedPrimary: {
        borderWidth: shape.borderWidth,
        borderStyle: shape.borderStyle,
        borderColor: palette.statusColorNeutral,
        backgroundColor: palette.statusColorNeutral,
        color: palette.mainColorContrast,
        '&:hover': {
          borderWidth: shape.borderWidth,
          borderStyle: shape.borderStyle,
          borderColor: palette.mainColor,
          backgroundColor: palette.statusColorNeutral,
        },
        '&.Mui-disabled': {
          borderWidth: shape.borderWidth,
          borderStyle: shape.borderStyle,
          borderColor: palette.mainColorDisabled,
          color: palette.textColorDisabled,
          backgroundColor: palette.mainColorDisabled,
        },
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: palette.statusColorNeutral,
      },
      colorAction: {
        color: palette.statusColorSuccess,
      },
    },
    MuiAlert: {
      root: {
        ...typography['description-12px-0.764rem-medium'],
        lineHeight: '14.4px',
        padding: '10px 16px',
      },
      message: {
        padding: 0,
        alignSelf: 'center',
      },
      icon: {
        padding: 0,
      },
      outlinedError: {
        color: palette.statusColorDanger,
        borderColor: palette.statusColorDanger,
        backgroundColor: palette.statusColorDangerLight,
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: palette.mainColor,
      },
      colorError: {
        color: palette.statusColorDanger,
      },
    },
    MuiDialog: {
      paper: {
        minWidth: 800,
        padding: 32,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        overflowY: 'unset',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '1rem 0 0 0',
        overflowY: 'unset',
      },
    },
    MuiTable: {
      root: {
        boxShadow: shape.boxShadow,
      },
    },
    MuiTableHead: {
      root: {
        height: 48,
        backgroundColor: palette.mainColorLight,
      },
    },
    MuiTableBody: {},
    MuiTableRow: {
      root: {
        ...typography['paragraph-16px-1rem-regular'],
        '&:nth-of-type(even)': {
          backgroundColor: palette.mainColorDisabled,
        },
      },
      head: {
        '& :first-child': {
          borderTopLeftRadius: shape.borderRadius,
        },
        '& :last-child': {
          borderTopRightRadius: shape.borderRadius,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
        padding: '1rem',
      },
      body: {
        ...typography['paragraph-16px-1rem-regular'],
      },
      head: {
        ...typography['paragraph-16px-1rem-medium-uppercase'],
        color: palette.mainColorDark,
      },
    },
    MuiTableSortLabel: {
      icon: {
        fontSize: '1.8rem',
        marginRight: 0,
        marginLeft: 0,
        color: palette.mainColor,
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: 'hidden',
        '& .MuiPaper-root': {
          boxShadow: 'none',
        },
        '&.MuiPaper-elevation1': {
          boxShadow: 'none',
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: palette.statusColorNeutral,
        '&$checked': {
          color: palette.statusColorNeutral,
        },
      },
    },
  },
});
