import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

import "./index.scss";
import { SubMenuDropDown, SubMenuDropUp } from "../../assets/icons";

const SidebarButton = ({ route, activeRoute, trackAndSidebar }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  const subRouteStyle = (route) =>
    pathname.indexOf(route.layout + route.path) > -1
      ? "subMenu__button__active"
      : "";

  return (
    <li
      className={
        route.upgrade ? "active active-pro" : activeRoute(route, showMenu)
      }
      key={route.name}
    >
      {!route.subRoutes ? (
        <NavLink
          to={!route.subRoutes && route.layout + route.path}
          onClick={() => trackAndSidebar(route.name)}
          className="nav-link"
          activeClassName="active"
        >
          <i>{route.icon}</i>
          <p>{route.name}</p>
        </NavLink>
      ) : (
        <button
          className={`dropdownButton ${showMenu ? "subMenuActive" : ""}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="dropdownButton__align">
            <i>{route.icon}</i>
            <p>{route.name}</p>
          </div>
          {showMenu ? <SubMenuDropUp /> : <SubMenuDropDown />}
        </button>
      )}
      {route.subRoutes && showMenu && (
        <div className="subMenu">
          {route.subRoutes.map((subRoute, index) => {
            const subMenuLink = subRoute.layout + subRoute.path;
            return (
              <div
                className={`subMenu__button ${subRouteStyle(subRoute)}`}
                key={index}
              >
                <NavLink
                  to={subMenuLink}
                  onClick={() => trackAndSidebar(subRoute.name)}
                  className="subMenu__link"
                  activeClassName="active"
                >
                  <p>{subRoute.name}</p>
                </NavLink>
              </div>
            );
          })}
        </div>
      )}
    </li>
  );
};

SidebarButton.propTypes = {
  trackAndSidebar: PropTypes.func.isRequired,
  activeRoute: PropTypes.func.isRequired,
  route: PropTypes.instanceOf(Object).isRequired,
};

export default SidebarButton;
