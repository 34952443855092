import React, { useContext, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Button,
  Alert,
  Checkbox,
  ListItemText,
} from '../../../components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CpfInputMask,
  MultiSelectMenuProps,
  languagesAndInitials,
  countriesMultiSelectOptions,
  languagesMultiSelectOptions,
} from './components';
import { brazilianStates, medicalSpecialties, status } from '../../../utils';
import DoctorService from '../../../services/doctors';
import notificationSystemRef from '../../../services/notificationSystemRef';
import { ProfessionalsContext } from '../../../context';

const initialRequestStatus = {
  status: status.IDLE,
  message: '',
};
const initialAlertState = {
  severity: '',
  message: '',
};
const AddDoctor = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const { dispatch, actionTypes } = useContext(ProfessionalsContext);
  const [gender, setGender] = useState('');
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [medicalRegistryNumber, setMedicalRegistryNumber] = useState('');
  const [medicalRegistryState, setMedicalRegistryState] = useState('');
  const [phoneNumberState, setPhoneNumberState] = useState('');
  const [specialtyName, setSpecialtyName] = useState('');
  const [requestStatus, setRequestStatus] = useState(initialRequestStatus);
  const [alert, setAlert] = useState(initialAlertState);
  const [serviceCountries, setServiceCountries] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);

  const statusPending = requestStatus.status === status.PENDING;
  const statusRejected = requestStatus.status === status.REJECTED;
  const invalidMail =
    !mail ||
    !mail.includes('@') ||
    !mail.includes('.') ||
    requestStatus.message === 'doctor.crud.error.mail.already.registered';
  const invalidDocNumber =
    !documentNumber ||
    documentNumber.length !== 11 ||
    requestStatus.message === 'doctor.crud.error.document.number.cpf.invalid';
  const submitDisabled =
    statusPending ||
    !gender ||
    invalidMail ||
    invalidDocNumber ||
    !medicalRegistryNumber ||
    !medicalRegistryState ||
    !specialtyName ||
    !serviceCountries.length ||
    !selectedLanguage.length;

  const handleSubmit = async () => {
    try {
      setRequestStatus({ status: status.PENDING });

      const spokenLanguages = selectedLanguage.map((language) => {
        const findLanguage = languagesAndInitials.find((lang) => language === lang.language);
        return findLanguage?.initials;
      });

      const doctor = {
        gender,
        name,
        mail,
        documentNumber,
        medicalRegistryNumbers1: {
          number: medicalRegistryNumber,
          state: medicalRegistryState,
        },
        cellPhoneDdi: phoneNumberState.slice(0, 2),
        cellPhoneNumber: phoneNumberState.slice(2),
        countryIsoCode: 'BR',
        specialtyName: {
          nameEs: `${specialtyName} es`,
          nameEn: `${specialtyName} en`,
          namePt: specialtyName,
        },
        serviceCountries,
        spokenLanguages,
        documentType: 'CPF',
        specialty: 'medical.specialty.name.telemedicine',
      };
      await DoctorService.postDoctor(doctor);
      setRequestStatus({ status: status.RESOLVED });
      notificationSystemRef.current.addNotification({
        title: 'TELECONSULTA CADASTRADA',
        message: 'Uma nova teleconsulta foi cadastrada no sistema.',
        level: 'success',
        position: 'tr',
        autoDismiss: 10,
      });
      dispatch({ type: actionTypes.resetProfessionalRequest });
      setOpen(false);
    } catch (error) {
      if (error.response) {
        setRequestStatus({
          status: status.REJECTED,
          message: error.response.data,
        });
        setAlert({
          severity: 'error',
          message: 'professionals.addDoctor.generic.error.message',
        });
      } else if (error.message === 'Network Error') {
        setRequestStatus({
          status: status.REJECTED,
          message: error.message,
        });
        setAlert({
          severity: 'error',
          message: 'network.error.message',
        });
      } else {
        setAlert({
          severity: 'error',
          message: 'professionals.addDoctor.generic.error.message',
        });
        setRequestStatus({
          status: status.REJECTED,
          message: error.message,
        });
      }
    }
  };

  useEffect(() => {
    setGender('');
    setName('');
    setMail('');
    setDocumentNumber('');
    setMedicalRegistryNumber('');
    setMedicalRegistryState('');
    setSpecialtyName('');
    setServiceCountries(['BR']);
    setSelectedLanguage(['Português']);
    setRequestStatus(initialRequestStatus);
  }, [open]);

  useEffect(() => {
    setRequestStatus(initialRequestStatus);
  }, [
    gender,
    name,
    mail,
    documentNumber,
    medicalRegistryNumber,
    medicalRegistryState,
    specialtyName,
    serviceCountries,
    selectedLanguage,
  ]);

  return (
    <Dialog {...props}>
      <DialogTitle>Adicionar teleconsulta</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {statusRejected && (
              <Alert severity={alert.severity} variant="outlined">
                {t(alert.message)}
              </Alert>
            )}
          </Grid>
          {/* GENDER */}
          <Grid item xs={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="add-doctor-modal-gender-label">Tratamento</InputLabel>
              <Select
                labelId="add-doctor-modal-gender-label"
                id="add-doctor-modal-gender"
                name="gender"
                value={gender}
                onChange={({ target }) => setGender(target.value)}
                label="Tratamento"
              >
                <MenuItem value="MALE">Dr</MenuItem>
                <MenuItem value="FEMALE">Dra</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* NAME */}
          <Grid item xs={6}>
            <TextField
              id="add-doctor-modal-name"
              name="name"
              label="Nome do profissional"
              value={name}
              onChange={({ target }) => setName(target.value)}
              inputProps={{ maxLength: 150 }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>

          {/* DOC NUMBER */}
          <Grid item xs={3}>
            <TextField
              id="add-doctor-modal-document-number"
              label="CPF"
              name="documentNumber"
              value={documentNumber}
              onChange={({ target }) => setDocumentNumber(target.value.replace(/\.|-|\s/g, ''))}
              InputProps={{
                inputComponent: CpfInputMask,
              }}
              error={statusRejected && invalidDocNumber}
              helperText={invalidDocNumber && t(requestStatus.message)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>

          {/* MAIL */}
          <Grid item xs={6}>
            <TextField
              id="add-doctor-modal-mail"
              label="E-mail do profissional"
              name="mail"
              value={mail}
              onChange={({ target }) => setMail(target.value)}
              type="email"
              inputProps={{ maxLength: 150 }}
              variant="outlined"
              size="small"
              fullWidth
              error={statusRejected && invalidMail}
              helperText={invalidMail && t(requestStatus.message)}
            />
          </Grid>

          {/* MEDICAL REGISTRY NUMBER */}
          <Grid item xs={3}>
            <TextField
              id="add-doctor-modal-medical-registry-number"
              label="CRM"
              name="medicalRegistryNumber"
              value={medicalRegistryNumber}
              onChange={({ target }) => setMedicalRegistryNumber(target.value)}
              inputProps={{ maxLength: 10 }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>

          {/* MEDICAL REGISTRY STATE */}
          <Grid item xs={3}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="add-doctor-modal-medical-registry-state-label">UF do CRM</InputLabel>
              <Select
                labelId="add-doctor-modal-medical-registry-state-label"
                id="add-doctor-modal-medical-registry-state"
                name="medicalRegistryState"
                value={medicalRegistryState}
                onChange={({ target }) => setMedicalRegistryState(target.value)}
                label="UF do CRM"
              >
                {brazilianStates.map(({ code }) => (
                  <MenuItem key={code} value={code}>
                    {code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* PhoneNumber */}

          <Grid item xs={6}>
            <TextField
              id="add-doctor-modal-number"
              label="Telefone"
              name="phoneNumber"
              value={phoneNumberState}
              onChange={({ target }) => setPhoneNumberState(target.value)}
              inputProps={{ maxLength: 11 }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          {/* SPECIALTYName */}

          <Grid item xs={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="add-doctor-modal-specialtyName-label">
                Especialidade do profissional
              </InputLabel>
              <Select
                labelId="add-doctor-modal-specialtyName-label"
                id="add-doctor-modal-specialtyName"
                name="speciality"
                value={specialtyName}
                onChange={({ target }) => setSpecialtyName(target.value)}
                label="Especialidade do profissional"
              >
                {medicalSpecialties.map((speciality) => (
                  <MenuItem key={speciality} value={speciality}>
                    {speciality}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* SERVICE COUNTRIES */}
          <Grid item xs={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="multiple-countries-checkbox-label">Países de atendimento</InputLabel>
              <Select
                labelId="multiple-countries-checkbox-label"
                id="multiple-countries-checkbox"
                multiple
                value={serviceCountries}
                onChange={({ target }) => setServiceCountries(target.value)}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MultiSelectMenuProps}
                label="Países de atendimento"
              >
                {countriesMultiSelectOptions.map((country) => (
                  <MenuItem key={country} value={country}>
                    <Checkbox color="primary" checked={serviceCountries.indexOf(country) > -1} />
                    <ListItemText primary={country} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* SERVICE LANGUAGES */}
          <Grid item xs={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="multiple-languages-checkbox-label">Idiomas de atendimento</InputLabel>
              <Select
                labelId="multiple-languages-checkbox-label"
                id="multiple-languages-checkbox"
                multiple
                value={selectedLanguage}
                onChange={({ target }) => setSelectedLanguage(target.value)}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MultiSelectMenuProps}
                label="Idiomas de atendimento"
              >
                {languagesMultiSelectOptions.map((language) => (
                  <MenuItem key={language} value={language}>
                    <Checkbox color="primary" checked={selectedLanguage.indexOf(language) > -1} />
                    <ListItemText primary={language} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(false)}
          disabled={statusPending}
        >
          voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={submitDisabled}
          startIcon={statusPending && <CircularProgress color="primary" size={20} />}
        >
          cadastrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddDoctor.propTypes = {
  open: bool,
  setOpen: func.isRequired,
};

AddDoctor.defaultProps = {
  open: false,
};

export default AddDoctor;
