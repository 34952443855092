import { makeStyles } from '@material-ui/core/styles';
import {
  typography,
  palette,
} from '../../../material';

export default makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tag: {
    ...typography['hint-9px-0.584rem-medium'],
    color: palette.textColorPrimary,
    textTransform: 'uppercase',
  },
  value: {
    ...typography['paragraph-16px-1rem-light'],
    color: palette.textColorPrimary,
  },
}));
