import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Pagination,
} from '../../../../components';
import Tbody from './tbody';
import { useAxios } from '../../../../hooks';

const TableOfLeads = () => {
  const { t } = useTranslation();

  const {
    data,
    status,
    message,
  } = useAxios({
    url: 'insurance',
    method: 'GET',
    params: {
      status: 'lead',
    },
  }, []);

  const columns = useMemo(() => [
    {
      Header: t('leads.table.creationDate.column.title'),
      accessor: 'creationDate',
    },
    {
      Header: t('leads.table.name.column.title'),
      accessor: 'patientUserName',
    },
    {
      Header: t('leads.table.email.column.title'),
      accessor: 'lead.mail',
    },
    {
      Header: t('leads.table.phoneNumber.column.title'),
      accessor: 'lead.phoneNumber',
    },
    {
      Header: t('leads.table.cpf.column.title'),
      accessor: 'cpf',
    },
    {
      Header: t('leads.table.insurancePrice.column.title'),
      accessor: 'insurancePrice',
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        isSorted: true,
        sortBy: [
          {
            id: 'creationDate',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfLeads;
