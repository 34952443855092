import api from '../api';
import userService from './user';

const STATUS_OK = 200;

const getAllDoctors = async () => {
  const { data } = await api.get('backoffice/doctors/all');
  return data;
};

const getAllEmergenceDoctors = async () => {
  const { data } = await api.get('backoffice/doctors/all');
  const emergencies = data.filter(
    (doctor) => doctor.specialities[0] === 'medical.specialty.name.telemedicine'
      && doctor.doctorStatus === 'AVAILABLE',
  );
  return emergencies;
};

const resendWelcomeEmail = async (doctorId) => {
  const { status } = await api.post(`resend/welcome/${doctorId}`);
  return status === STATUS_OK;
};

const putDoctor = (doctor) => {
  const companyId = userService.companyId();
  const data = { ...doctor, companyId };

  return api.put('doctor', data);
};

const postDoctor = (doctor) => {
  const companyId = userService.companyId();
  const data = { ...doctor, companyId };

  return api.post('backoffice/doctors', data);
};

const doctorService = {
  getAllDoctors,
  getAllEmergenceDoctors,
  resendWelcomeEmail,
  putDoctor,
  postDoctor,
};

export default doctorService;
