import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '../../../../components';
import { status as listOfStatus } from '../../../../utils';
import { useAxios } from '../../../../hooks';
import { TelemedicineContext } from '../../../../context';
import DoctorSelect from '../tableOfOpenCalls/components/doctorSelect';
import useStyles from './styles';

const AcceptCallModal = () => {
  const { state, dispatch, actionTypes } = useContext(TelemedicineContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [doctorId, setDoctorId] = useState('');
  const { status, setRequest, data } = useAxios();

  const acceptCall = () => {
    const { patientRequestId } = state.callToAccept;

    setRequest({
      url: '/housecall/accept',
      method: 'POST',
      data: {
        doctorId,
        patientRequestId,
      },
    });

    dispatch({
      type: actionTypes.setOpenCalls,
      payload: state.openCalls.filter((c) => c.id !== patientRequestId),
    });

    dispatch({
      type: actionTypes.setOpenAcceptCallModal,
      payload: false,
    });
  };

  useEffect(() => {
    if (!state.openAcceptCallModal) setDoctorId('');
  }, [state.openAcceptCallModal]);

  useEffect(() => {
    if (data) {
      console.log('data', data);
      window.open(data.telemedicine.url, '_blank');
    }
  }, [data]);

  return (
    <Dialog
      open={state.openAcceptCallModal && !!state.callToAccept}
      onClose={() => dispatch({
        type: actionTypes.setOpenAcceptCallModal,
        payload: false,
      })}
      transitionDuration={500}
      className={classes.dialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        {t('telemedicine.accept.call.modal.title')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>
              {t('telemedicine.accept.call.modal.subtitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.remember}>
              {t('telemedicine.accept.call.modal.remember')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DoctorSelect doctorId={doctorId} setDoctorId={setDoctorId} />
          </Grid>
        </Grid>
        {/* <pre>
          {JSON.stringify(status, null, 2)}
        </pre>
        <pre>
          {JSON.stringify(doctorId, null, 2)}
        </pre>
        <pre>
          {JSON.stringify(state.callToAccept, null, 2)}
        </pre> */}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => dispatch({
            type: actionTypes.setOpenAcceptCallModal,
            payload: false,
          })}
        >
          {t('go.back')}
        </Button>
        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          disabled={
            status === listOfStatus.PENDING
            || status === listOfStatus.RESOLVED
            || !doctorId
            || !state.callToAccept.patientRequestId
          }
          onClick={acceptCall}
          startIcon={
            status === listOfStatus.PENDING && <CircularProgress color="primary" size={20} />
          }
        >
          {t('telemedicine.accept.call.modal.title')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptCallModal;
