import { makeStyles } from '@material-ui/core/styles';
import {
  typography,
  palette,
} from '../../../../material';

const button = {
  ...typography['CTA-16px-1rem-medium-uppercase'],
  padding: '10.5px 16px 10.5px 16px',
  maxHeight: '40px',
};

export default makeStyles((theme) => ({
  dialogTitle: {
    padding: 0,
    paddingBottom: theme.spacing(4),
    margin: 0,
    ...typography['subtitle-21px-1.309rem-medium'],
    color: palette.textColorPrimary,
  },
  dialogActions: {
    padding: 0,
    paddingTop: theme.spacing(4),
    margin: 0,
  },
  subtitle: {
    ...typography['paragraph-16px-1rem-regular'],
  },
  remember: {
    ...typography['description-12px-0.764rem-light'],
    color: palette.mainColor,
  },
  button,
  submitButton: {
    ...button,
    backgroundColor: palette.statusColorSuccess,
    borderColor: palette.statusColorSuccess,
    '&:hover': {
      backgroundColor: palette.statusColorSuccess,
      borderColor: palette.mainColor,
    },
  },
}));
