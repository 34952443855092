import React, {
  useReducer,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
} from '../../components';
import TableOfInfluencers from './components/tableOfInfluencers';
import SearchContainer from './components/searchContainer';
import { InfluencersContext } from '../../context';
import {
  reducer,
  initialState,
  actionTypes,
} from './reducer';

const PayInfluencer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t } = useTranslation();

  return (
    <InfluencersContext.Provider
      value={{
        state,
        dispatch,
        actionTypes,
      }}
    >
      <div className="content">
        <Row>
          <Col md={12}>
            <ContentCard
              title={t('pay.influencer.filter.container.title')}
              ctTableFullWidth
              ctTableResponsive
              content={(
                <ErrorBoundary
                  fallback={TableErrorFallback}
                  fallbackRender={TableErrorFallback}
                  FallbackComponent={TableErrorFallback}
                >
                  <SearchContainer />
                </ErrorBoundary>
              )}
            />
          </Col>
          <Col md={12}>
            <ContentCard
              title={t('pay.influencer.table.container.title')}
              ctTableFullWidth
              ctTableResponsive
              content={(
                <ErrorBoundary
                  fallback={TableErrorFallback}
                  fallbackRender={TableErrorFallback}
                  FallbackComponent={TableErrorFallback}
                >
                  <TableOfInfluencers />
                </ErrorBoundary>
              )}
            />
          </Col>
        </Row>
      </div>
    </InfluencersContext.Provider>
  );
};

export default PayInfluencer;
