import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Badge,
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
} from '../../../../components';
import {
  formatAsCurrency,
  status as listOfStatus,
} from '../../../../utils';

const renderDate = ({ millis }) => (
  <p className="">
    {moment(millis).format('DD/MM/YYYY - HH:mm')}
  </p>
);

const renderStatus = (status) => {
  /*
    DISPONIVEL - SUCCESS
    ESTORNADO - DANGER
    A RECEBER - ALERT
  */

  switch (status) {
    case 'AVAILABLE':
      return <Badge variant="success">DISPONÍVEL</Badge>;
    case 'WAITING_FUNDS':
      return <Badge variant="warning">A RECEBER</Badge>;
    case 'REFUNDED':
      return <Badge variant="danger">ESTORNADO</Badge>;
    default:
      return null;
  }
};

const renderAmount = (amount) => <p>{formatAsCurrency(amount)}</p>;

const renderDiscount = (discount) => (
  <p>
    <span>-</span>
    {formatAsCurrency(discount)}
  </p>
);

const renderTotal = (amount, fee) => <p>{formatAsCurrency(amount - fee)}</p>;

const Tbody = ({
  instance,
  status,
}) => {
  const { t } = useTranslation();
  const {
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  return (
    <TableBody {...getTableBodyProps()}>
      {
        (
          status === listOfStatus.PENDING && (
            <TableRow>
              <TableCell
                colSpan={instance.columns.length}
                align="center"
              >
                <CircularProgress
                  color="primary"
                  size={30}
                />
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            page
              .map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {
                      row
                        .cells
                        .map((cell) => {
                          const {
                            value,
                            column: {
                              Header,
                              align,
                            },
                            row: { original },
                          } = cell;
                          let component = null;

                          if (Header === 'data') {
                            component = renderDate(value);
                          } else if (Header === 'status') {
                            component = renderStatus(value);
                          } else if (Header === 'valor') {
                            component = renderAmount(value);
                          } else if (Header === 'descontos') {
                            component = renderDiscount(value);
                          } else if (Header === 'total') {
                            component = renderTotal(original.amount, original.fee);
                          } else {
                            component = cell.render('Cell');
                          }

                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              align={align}
                            >
                              {component}
                            </TableCell>
                          );
                        })
                    }
                  </tr>
                );
              })
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t('generic.error.message')}
              </TableCell>
            </TableRow>
          )
        )
      }
    </TableBody>
  );
};

Tbody.propTypes = {
  instance: PropTypes.shape({
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
    page: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  status: PropTypes.string.isRequired,
};

export default Tbody;
