import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ value, label, checked, onChange }) => (
  <div className="emerCheckbox">
    <input id={label} type="checkbox" value={value} onChange={onChange} checked={checked} />
    <label htmlFor={label}>{label}</label>
  </div>
);

export default Checkbox;