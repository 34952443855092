import React, {
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Pagination,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import { UserContext } from '../../../../context';
import Tbody from './tbody';

const TableOfFinancials = () => {
  const { companyId } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    status,
    data,
    setRequest,
  } = useAxios(null, []);

  const columns = useMemo(() => [
    {
      Header: t('financials.table.createdAt.column.title'),
      accessor: 'createdAt',
    },
    {
      Header: t('financials.table.transactionId.column.title'),
      accessor: 'transactionId',
      align: 'center',
    },
    {
      Header: t('financials.table.status.column.title'),
      accessor: 'status',
      align: 'center',
    },
    {
      Header: t('financials.table.amount.column.title'),
      accessor: 'amount',
      align: 'center',
    },
    {
      Header: t('financials.table.fee.column.title'),
      accessor: 'fee',
      align: 'center',
    },
    {
      Header: t('financials.table.total.column.title'),
      accessor: 'total',
      align: 'center',
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        isSorted: false,
        pageSize: 10,
        pageIndex: 0,
      },
      autoResetPage: false,
      manualPagination: true,
      pageCount: -1,
    },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    state: { pageIndex },
  } = instance;

  useEffect(() => {
    setRequest({
      url: `company/${companyId}/financial/payables`,
      method: 'GET',
      params: {
        count: 10,
        page: pageIndex + 1,
      },
    });
  }, [setRequest, companyId, pageIndex]);

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfFinancials;
