import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDown } from '../../assets/icons';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '../index';

const Thead = ({ instance }) => {
  const { headerGroups } = instance;

  return (
    <TableHead>
      {
        headerGroups
          .map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup
                  .headers
                  .map((column) => (
                    <TableCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      align={column.alignHead || column.align}
                    >
                      <TableSortLabel
                        active={column.canSort && column.isSorted}
                        IconComponent={ArrowDropDown}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    </TableCell>
                  ))
              }
            </TableRow >
          ))
      }
    </TableHead >
  );
};

Thead.propTypes = {
  instance: PropTypes.shape({
    headerGroups: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default Thead;
