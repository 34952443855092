import React, { useReducer, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  reducer,
  actionTypes,
  initialState,
} from './reducer';
import { BackofficeUsersContext } from '../../context';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
  Grid,
  Button,
} from '../../components';
import { AddCircleIcon } from '../../assets/icons';
import TableOfBackofficeUsers from './components/tableOfBackofficeUsers';
import AddUserModal from './components/addUserModal';

const Users = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openAddUser, setOpenAddUser] = useState(false);

  return (
    <BackofficeUsersContext.Provider
      value={{
        state,
        dispatch,
        actionTypes,
      }}
    >
      <div className="content">
        <Row>
          <Col md={12}>
            <ContentCard
              ctTableFullWidth
              ctTableResponsive
              content={(
                <Grid
                  container
                  alignItems="flex-end"
                  justify="flex-end"
                >
                  <AddUserModal
                    open={openAddUser}
                    onClose={() => setOpenAddUser(false)}
                    setOpen={setOpenAddUser}
                    transitionDuration={500}
                  />
                  <Grid item>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      startIcon={<AddCircleIcon color="primary" />}
                      onClick={() => setOpenAddUser(true)}
                    >
                      {t('backoffice.users.add.user.button.label')}
                    </Button>
                  </Grid>
                </Grid>
              )}
            />
            <ContentCard
              ctTableFullWidth
              ctTableResponsive
              content={(
                <ErrorBoundary
                  fallback={TableErrorFallback}
                  fallbackRender={TableErrorFallback}
                  FallbackComponent={TableErrorFallback}
                >
                  <TableOfBackofficeUsers />
                </ErrorBoundary>
              )}
            />
          </Col>
        </Row>
      </div>
    </BackofficeUsersContext.Provider>
  );
};

export default Users;
