import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import pt from './translations/pt';

const resources = { pt };

export default i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'pt',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
