import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';

const cleanFilters = (columns) =>
  columns.forEach((column) => {
    const { setFilter, canFilter } = column;
    debugger;
    if (canFilter) {
      // eslint-disable-next-line
      column.filterValue = '';
      setFilter(undefined);

      console.log('HERERE');
    }
  });

const Filters = ({ instance: { headerGroups, columns } }) => (
  <div className="filtersDiv">
    {headerGroups.map((headerGroup) => (
      <Row key={headerGroup.headers.toString()} className="p-0 m-0">
        {headerGroup.headers.map(
          (column) => column.canFilter && <div key={column.Header}>{column.render('Filter')}</div>,
        )}
        <Col sm={2} className="cleanFilterButtonDiv">
          <div>
            <Button type="button" onClick={() => cleanFilters(columns)}>
              Limpar filtros
            </Button>
          </div>
        </Col>
      </Row>
    ))}
  </div>
);

export default Filters;

Filters.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any).isRequired,
};
