import React, { useContext, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Grid, RadioGroup, FormControlLabel,
  Radio, Typography, TextField, Button,
  CircularProgress, Alert,
} from '../../../components';
import {
  Dialog, DialogTitle,
  DialogContent, DialogActions,
} from './components';
import DoctorService from '../../../services/doctors';
import notificationSystemRef from '../../../services/notificationSystemRef';
import { status } from '../../../utils';
import { ProfessionalsContext } from '../../../context';

const registerTypeOptions = {
  PROFESSIONAL: 'PROFESSIONAL',
  AMBULANCE: 'AMBULANCE',
};
const initialRequestStatus = {
  status: status.IDLE,
  message: '',
};
const initialAlertState = {
  severity: '',
  message: '',
};
const AddAmbulance = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  const { dispatch, actionTypes } = useContext(ProfessionalsContext);
  const [registerType, setRegisterType] = useState('');
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [requestStatus, setRequestStatus] = useState(initialRequestStatus);
  const [alert, setAlert] = useState(initialAlertState);

  const statusPending = requestStatus.status === status.PENDING;
  const statusRejected = requestStatus.status === status.REJECTED;

  const invalidMail = (
    !mail
    || !mail.includes('@')
    || !mail.includes('.')
    || requestStatus.message === 'doctor.crud.error.mail.already.registered'
  );

  useEffect(() => {
    setName('');
    setMail('');
    setRequestStatus(initialRequestStatus);
  }, [registerType]);

  useEffect(() => {
    setRegisterType('');
    setName('');
    setMail('');
    setRequestStatus(initialRequestStatus);
  }, [open]);

  useEffect(
    () => setRequestStatus(initialRequestStatus),
    [name, mail],
  );

  const handleSubmit = async () => {
    try {
      setRequestStatus({ status: status.PENDING });
      const doctor = {
        name,
        mail,
        countryIsoCode: 'BR',
        specialty: 'medical.specialty.name.emergency',
        gender: 'MALE',
        specialtyName: {
          namePt: 'Ambulância',
          nameEn: 'Ambulance',
          nameEs: 'Ambulancia',
        },
        documentType: 'CPF',
      };

      await DoctorService.putDoctor(doctor);
      setRequestStatus({ status: status.RESOLVED });
      notificationSystemRef.current.addNotification({
        title: 'AMBULÂNCIA CADASTRADA',
        message: 'Uma nova ambulância foi cadastrada no sistema.',
        level: 'success',
        position: 'tr',
        autoDismiss: 10,
      });
      dispatch({ type: actionTypes.resetProfessionalRequest });
      setOpen(false);
    } catch (error) {
      if (error.response) {
        setRequestStatus({
          status: status.REJECTED,
          message: error.response.data,
        });
        setAlert({
          severity: 'error',
          message: 'professionals.addDoctor.generic.error.message',
        });
      } else if (error.message === 'Network Error') {
        setRequestStatus({
          status: status.REJECTED,
          message: error.message,
        });
        setAlert({
          severity: 'error',
          message: 'network.error.message',
        });
      } else {
        setAlert({
          severity: 'error',
          message: 'professionals.addDoctor.generic.error.message',
        });
        setRequestStatus({
          status: status.REJECTED,
          message: error.message,
        });
      }
    }
  };

  const submitDisabled = (
    statusPending
    || !registerType
    || !name
    || invalidMail
  );

  return (
    <Dialog {...props}>
      <DialogTitle>
        Adicionar ambulância
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
            {
              statusRejected && (
                <Alert
                  severity={alert.severity}
                  variant="outlined"
                >
                  {t(alert.message)}
                </Alert>
              )
            }
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">Tipo de cadastro</Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <RadioGroup
              aria-label="registerType"
              name="registerType"
              value={registerType}
              onChange={({ target }) => setRegisterType(target.value)}
            >
              <FormControlLabel
                value={registerTypeOptions.PROFESSIONAL}
                color="primary"
                control={<Radio />}
                label="Cadastrar um profissional, que vai usar seu celular pessoal."
              />
              <FormControlLabel
                value={registerTypeOptions.AMBULANCE}
                color="primary"
                control={<Radio />}
                label="Cadastrar uma ambulância, que vai ter um celular fixo para ela."
              />
            </RadioGroup>
          </Grid>
          {
            registerType && (
              <Grid
                container
                item
                xs={12}
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="body1">
                    {
                      registerType === registerTypeOptions.PROFESSIONAL
                        ? 'Dados do profissional' : 'Dados da ambulância'
                    }
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={4}
                >
                  {/* NAME */}
                  <Grid item xs={6}>
                    <TextField
                      id="add-ambulance-modal-name"
                      name="name"
                      label={
                        registerType === registerTypeOptions.PROFESSIONAL
                          ? 'Nome' : 'Nome/identificação da ambulância'
                      }
                      value={name}
                      onChange={({ target }) => setName(target.value)}
                      inputProps={{ maxLength: 150 }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  {/* MAIL */}
                  <Grid item xs={6}>
                    <TextField
                      id="add-ambulance-modal-mail"
                      label="E-mail"
                      name="mail"
                      value={mail}
                      onChange={({ target }) => setMail(target.value)}
                      type="email"
                      inputProps={{ maxLength: 150 }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={statusRejected && invalidMail}
                      helperText={t(requestStatus.message)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(false)}
          disabled={statusPending}
        >
          voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={submitDisabled}
          startIcon={
            statusPending && (
              <CircularProgress
                color="primary"
                size={20}
              />
            )
          }
        >
          cadastrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddAmbulance.propTypes = {
  open: bool,
  setOpen: func.isRequired,
};

AddAmbulance.defaultProps = {
  open: false,
};

export default AddAmbulance;
