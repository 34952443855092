import React, {
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Pagination,
} from '../../../../components';
import { BackofficeUsersContext } from '../../../../context';
import { useAxios } from '../../../../hooks';
import { sort } from '../../../../utils';

const TableOfBackofficeUsers = () => {
  const { state } = useContext(BackofficeUsersContext);
  const { t } = useTranslation();
  const {
    status,
    data,
    message,
    setRequest,
  } = useAxios(state.usersRequest, []);

  useEffect(() => setRequest(state.usersRequest), [setRequest, state.usersRequest]);

  const columns = useMemo(() => [
    {
      Header: t('backoffice.users.table.name.column.title'),
      accessor: 'name',
      sortType: sort,
    },
    {
      Header: t('backoffice.users.table.mail.column.title'),
      accessor: 'mail',
      align: 'left',
    },
    {
      Header: t('backoffice.users.table.companyName.column.title'),
      accessor: 'companyName',
      align: 'left',
    },
    {
      Header: t('backoffice.users.table.actions.column.title'),
      accessor: 'actions',
      align: 'left',
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        isSorted: true,
        sortBy: [
          {
            id: 'name',
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfBackofficeUsers;
