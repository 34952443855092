import React, { useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import AdminNavbar from "../components/Navbars/AdminNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import style from "../variables/Variables.jsx";
import notificationSystemRef from "../services/notificationSystemRef";
import { UserContext } from "../context";
import { useRoutes } from "../hooks";
import CouponModal from "../views/coupons/components/addCouponModal";

const Admin = (props) => {
  let location = useLocation();
  let background = location.state && location.state.background;
  const { userType } = useContext(UserContext);
  const routes = useRoutes(userType);

  return (
    <div className="wrapper">
      <NotificationSystem ref={notificationSystemRef} style={style} />
      <Sidebar {...props} routes={routes} />
      <div id="main-panel" className="main-panel h-100">
        <AdminNavbar {...props} routes={routes} />
        <Switch location={background || location}>
          {routes.map((route, key) => {
            if (route.layout && route.path && !route.subRoutes) {
              return (
                <Route
                  path={route.layout + route.path}
                  render={(props) => <route.component {...props} />}
                  key={key}
                />
              );
            }
            if (route.subRoutes) {
              return route.subRoutes.map((subRoute, key) => (
                <Route
                  path={subRoute.layout + subRoute.path}
                  render={(props) => <subRoute.component {...props} />}
                  key={key}
                />
              ));
            }
            return null;
          })}
        </Switch>
        {background && (
          <Route path="/admin/coupons/create" children={<CouponModal />} />
        )}
      </div>
    </div>
  );
};

export default Admin;
