import api from '../api';

const usageHistory = async () => {
  const { data } = await api.get('/patients/coupons/usage-coupons');
  return data;
};

const couponsService = {
  usageHistory,
};

export default couponsService;
