import React from 'react';
import { string, func } from 'prop-types';

const ErrorFallback = ({ error, resetErrorBoundary }) => (
  <div role="alert">
    <p>Something went wrong:</p>
    <pre>{error.message}</pre>
    <button type="button" onClick={resetErrorBoundary}>Try again</button>
  </div>
);

ErrorFallback.propTypes = {
  error: string,
  resetErrorBoundary: func,
};

ErrorFallback.defaultProps = {
  error: '',
  resetErrorBoundary: () => null,
};

export default ErrorFallback;