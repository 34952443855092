export const actionTypes = {
  resetUsersRequest: 'RESET_USERS_REQUEST',
};

export const initialState = {
  usersRequest: {
    url: 'users/all',
    method: 'GET',
  },
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.resetUsersRequest:
      return { ...state, usersRequest: { ...initialState.usersRequest } };
    default:
      throw new Error();
  }
}
