/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//
// //
// // // For notifications
// //
//
import palette from '../material/palette';

export default {
  Wrapper: {},
  Containers: {
    DefaultStyle: {
      position: 'fixed',
      width: 'fit-content',
      height: 'fit-content',
      top: '72px',
      right: '12px',
      margin: '0px',
      padding: '0px',
      zIndex: 9998,
      border: '0',
      fontFamily: 'Quicksand,Arial,sans-serif',
      fontWeight: '400',
      boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.1)',
    },
  },

  NotificationItem: {
    DefaultStyle: {
      minHeight: '112px',
      width: '360px',
      cursor: 'pointer',
      borderRadius: '4px',
      fontSize: '14px',
      margin: '0px',
      padding: '24px',
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      opacity: 0,
      transition: "all 0.5s ease-in-out",
      WebkitTransform: "translate3d(0, 0, 0)",
      transform: "translate3d(0, 0, 0)",
      willChange: "transform, opacity",
      color: palette.mainColorDark,
      backgroundColor: palette.mainColorContrast,
      borderWidth: '2px',
      borderStyle: 'solid',
      isHidden: {
        opacity: 0
      },

      isVisible: {
        opacity: 1
      }
    },

    success: {
      borderColor: palette.statusColorSuccess,
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderColor: palette.statusColorDanger,
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderColor: palette.statusColorAttention,
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    },

    info: {
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0
    }
  },

  Title: {
    DefaultStyle: {
      fontSize: '16px',
      textTransform: 'uppercase',
      margin: 0,
      padding: 0,
      fontWeight: 600,
      color: palette.mainColorDark,
      lineHeight: '120%',
    }
  },

  MessageWrapper: {
    DefaultStyle: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '120%',
      color: palette.textColorPrimary,
      marginTop: '8px',
    }
  },

  Dismiss: {
    DefaultStyle: {
      fontFamily: "inherit",
      fontSize: "21px",
      color: "#000",
      float: "right",
      position: "absolute",
      right: "10px",
      top: "50%",
      marginTop: "-13px",
      backgroundColor: "#FFFFFF",
      display: "block",
      borderRadius: "50%",
      opacity: ".4",
      lineHeight: "11px",
      width: "25px",
      height: "25px",
      outline: "0 !important",
      textAlign: "center",
      padding: "6px 3px 3px 3px",
      fontWeight: "300",
      marginLeft: "65px"
    },
  },

  Action: {
    DefaultStyle: {
      background: "#ffffff",
      borderRadius: "2px",
      padding: "6px 20px",
      fontWeight: "bold",
      margin: "10px 0 0 0",
      border: 0
    },

    success: {
      backgroundColor: "#a1e82c",
      color: "#ffffff"
    },

    error: {
      backgroundColor: "#fc727a",
      color: "#ffffff"
    },

    warning: {
      backgroundColor: "#ffbc67",
      color: "#ffffff"
    },

    info: {
      backgroundColor: "#63d8f1",
      color: "#ffffff"
    }
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0
    }
  }
};
