import React from 'react';
import './Badge.scss';

const Badge = ({ children, variant }) => {
  return (
    <div className={`customBadge ${variant ? `customBadge-${variant}` :  ''}`}>
      {children}
    </div>
  )
};

export default Badge;