export const actionTypes = {
  setName: 'SET_NAME',
  setPatientName: 'SET_PATIENT_NAME',
  resetFilter: 'RESET_FILTER',
};

export const initialState = {
  name: '',
  patientName: '',
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.setName:
      return {
        ...state,
        name: action.payload,
      };
    case actionTypes.setPatientName:
      return {
        ...state,
        patientName: action.payload,
      };
    case actionTypes.resetFilter:
      return {
        ...state,
        name: initialState.name,
        patientName: initialState.patientName,
      };
    default:
      throw new Error();
  }
}
