import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  CircularProgress,
} from '../../../../components';
import { BackofficeUsersContext } from '../../../../context';
import { useAxios } from '../../../../hooks';
import CompanySelect from './components/companySelect';

const AddUserModal = (props) => {
  const { open, setOpen } = props;
  const { dispatch, actionTypes } = useContext(BackofficeUsersContext);
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [companyId, setCompanyId] = useState('');
  const {
    message,
    setRequest,
    isPending,
    isResolved,
    isRejected,
  } = useAxios();

  useEffect(() => {
    if (open) {
      setName('');
      setMail('');
      setCompanyId('');
      setRequest(null);
    }
  }, [open, setRequest]);

  useEffect(() => setRequest(null), [name, mail, companyId, setRequest]);

  useEffect(() => {
    if (isResolved) {
      setOpen(false);
      dispatch({ type: actionTypes.resetUsersRequest });
    }
  }, [isResolved, setOpen, dispatch, actionTypes.resetUsersRequest]);

  const invalidEmail = useMemo(() => !mail || !mail.includes('@') || !mail.includes('.'), [mail]);

  const emailAlreadyRegister = useMemo(() => isRejected
    && message === 'user.backoffice.crud.add.mail.already', [isRejected, message]);

  const submitDisabled = useMemo(() => (
    isPending
    || !name
    || invalidEmail
  ), [isPending, name, invalidEmail]);

  const handleSubmit = () => {
    if (!submitDisabled) {
      setRequest({
        method: 'POST',
        url: 'user',
        data: {
          name,
          mail,
          companyId,
        },
      });
    }
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('backoffice.users.add.user.button.label')}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={4}
        >
          {/* NAME */}
          <Grid item xs={6}>
            <TextField
              id="add-user-modal-name"
              name="name"
              label="Nome"
              value={name}
              onChange={({ target }) => setName(target.value)}
              inputProps={{ maxLength: 150 }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          {/* MAIL */}
          <Grid item xs={6}>
            <TextField
              id="add-user-modal-mail"
              label="E-mail"
              name="mail"
              value={mail}
              onChange={({ target }) => setMail(target.value)}
              type="email"
              inputProps={{ maxLength: 150 }}
              variant="outlined"
              size="small"
              fullWidth
              error={emailAlreadyRegister}
              helperText={emailAlreadyRegister && t(message)}
            />
          </Grid>
          {/* PARCEIRA */}
          <Grid item xs={12}>
            <CompanySelect
              id="add-user-modal-companyName"
              name="companyName"
              label={t('backoffice.users.modal.company.select.label')}
              value={companyId}
              onChange={({ target }) => setCompanyId(target.value)}
              inputProps={{ maxLength: 150 }}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpen(false)}
          disabled={isPending}
        >
          {t('go.back')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={submitDisabled}
          startIcon={
            isPending && (
              <CircularProgress
                color="primary"
                size={20}
              />
            )
          }
        >
          {t('backoffice.users.add.user.submit.button.label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddUserModal;
