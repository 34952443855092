import React from 'react';

export const UserContext = React.createContext();
export const DocContext = React.createContext();
export const TelemedicineContext = React.createContext();
export const NotificationContext = React.createContext();
export const InfluencersContext = React.createContext();
export const BackofficeUsersContext = React.createContext();
export const CouponsContext = React.createContext();
export const ProfessionalsContext = React.createContext();
export const DependentsContext = React.createContext();
