import { makeStyles } from '@material-ui/core/styles';
import {
  palette,
  typography,
} from '../../../../material';

export default makeStyles(() => ({
  exportButton: {
    ...typography['CTA-16px-1rem-medium-uppercase'],
    padding: '0.65625rem 1rem 0.65625rem 1rem',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 8,
    backgroundColor: 'transparent',
    color: palette.statusColorNeutral,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: palette.statusColorNeutral,
      backgroundColor: palette.statusColorNeutralLight,
    },
    '&:focus': {
      borderColor: palette.mainColor,
    },
  },
  exportButtonIcon: {
    width: 19,
    height: 19,
  },
}));
