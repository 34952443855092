import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '../../../../../../components';
import { useAxios } from '../../../../../../hooks';

const DoctorSelect = ({ doctorId, setDoctorId }) => {
  const { t } = useTranslation();
  const {
    isPending,
    isResolved,
    isRejected,
    data,
  } = useAxios({
    url: 'backoffice/doctors/all',
    method: 'GET',
  }, []);

  const availableDoctors = useMemo(() => data
    .filter((doctor) => doctor.specialities.includes('medical.specialty.name.telemedicine')
      && doctor.onDuty === true
      && doctor.doctorStatus !== 'IN_TRANSIT'
      && doctor.doctorStatus !== 'IN_A_HOUSECALL')
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      if (b.name > a.name) return -1;
      return 0;
    }), [data]);

  const noDoctorAvailable = useMemo(() => isResolved
    && !availableDoctors.length, [isResolved, availableDoctors]);

  const label = useMemo(() => {
    if (isPending) {
      return t('telemedicine.accept.call.modal.doctor.select.loading.message');
    }
    if (isResolved && !doctorId && !noDoctorAvailable) {
      return t('telemedicine.accept.call.modal.doctor.select.message');
    }
    if (noDoctorAvailable) {
      return t('telemedicine.accept.call.modal.doctor.select.no.doctor.available.message');
    }
    if (isRejected) {
      return t('telemedicine.accept.call.modal.doctor.select.error.message');
    }
    return t('telemedicine.accept.call.modal.doctor.select.label');
  }, [isPending, t, isResolved, doctorId, noDoctorAvailable, isRejected]);

  return (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
    >
      <InputLabel id="doctor-select-label">
        {label}
      </InputLabel>
      <Select
        labelId="doctor-select-label"
        id="doctor-select"
        value={doctorId}
        onChange={({ target }) => setDoctorId(target.value)}
        label={label}
        disabled={isPending || isRejected || noDoctorAvailable}
        color="primary"
      >
        {
          isResolved
          && availableDoctors
            .map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
              >
                {name}
              </MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
};

DoctorSelect.propTypes = {
  doctorId: PropTypes.string.isRequired,
  setDoctorId: PropTypes.func.isRequired,
};

export default DoctorSelect;
