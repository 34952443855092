import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DependentsContext } from '../../../../context';
import {
  Grid,
  TextField,
  Button,
} from '../../../../components';

const SearchContainer = () => {
  const { t } = useTranslation();
  const {
    state,
    dispatch,
    actionTypes,
  } = useContext(DependentsContext);

  return (
    <Grid
      container
      item
      alignItems="flex-start"
      spacing={4}
    >
      <Grid item>
        <TextField
          name="patientName"
          label={t('dependents.search.container.patientName.label')}
          value={state.patientName}
          onChange={({ target }) => dispatch({
            type: actionTypes.setPatientName,
            payload: target.value,
          })}
          inputProps={{ maxLength: 150 }}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          name="name"
          label={t('dependents.search.container.name.label')}
          value={state.name}
          onChange={({ target }) => dispatch({
            type: actionTypes.setName,
            payload: target.value,
          })}
          inputProps={{ maxLength: 150 }}
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item>
        <Button
          variant="text"
          onClick={() => dispatch({ type: actionTypes.resetFilter })}
          size="small"
        >
          {t('clear.filters')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchContainer;
