import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  Typography,
  Grid,
  TableBody,
  TableRow,
  TableCell,
} from '../../../../../components';
import { status as listOfStatus } from '../../../../../utils';
import useStyles from './styles';

const formatAsTime = (time) => moment(time).format('HH:mm');

const Tbody = ({ instance, status, message }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  return (
    <TableBody {...getTableBodyProps()}>
      {
        (
          status === listOfStatus.PENDING && (
            <TableRow>
              <TableCell
                colSpan={instance.columns.length}
                align="center"
              >
                <CircularProgress
                  color="primary"
                  size={30}
                />
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED
          && !page.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t('no.data.found')}
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            page
              .map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {
                      row
                        .cells
                        .map((cell) => {
                          const {
                            value,
                            column: {
                              id,
                              align,
                            },
                            row: {
                              original,
                            },
                          } = cell;

                          let component = null;

                          if (id === 'patientName') {
                            const { patientName, patientLastName, dependentName } = original;
                            component = `${dependentName || `${patientName || ''} ${patientLastName || ''}`}`;
                          } else if (id === 'requestDate') {
                            const {
                              requestDate,
                              acceptDate,
                              startedDate,
                            } = original;

                            component = (
                              <Grid
                                container
                                spacing={4}
                                direction="row"
                                justify={align}
                              >
                                <Grid item>
                                  <Typography className={classes.tag}>
                                    {t('telemedicine.accept.calls.table.requestDate.tag')}
                                  </Typography>
                                  <Typography className={classes.value}>
                                    {requestDate ? formatAsTime(requestDate) : '-'}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.tag}>
                                    {t('telemedicine.accept.calls.table.acceptDate.tag')}
                                  </Typography>
                                  <Typography className={classes.value}>
                                    {acceptDate ? formatAsTime(acceptDate) : '-'}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.tag}>
                                    {t('telemedicine.accept.calls.table.startedDate.tag')}
                                  </Typography>
                                  <Typography className={classes.value}>
                                    {startedDate ? formatAsTime(startedDate) : '-'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          } else {
                            component = value ? cell.render('Cell') : '-';
                          }

                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              align={align}
                            >
                              {component}
                            </TableCell>
                          );
                        })
                    }
                  </tr>
                );
              })
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <tr>
              <Typography
                component="td"
                colSpan={8}
                color="error"
                align="center"
              >
                {t(message)}
              </Typography>
            </tr>
          )
        )
      }
    </TableBody>
  );
};

Tbody.propTypes = {
  instance: PropTypes.shape({
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
    page: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tbody;
