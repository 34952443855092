import React, { useContext } from 'react';
import { UserContext } from '../../../../context';
import { useAxios } from '../../../../hooks';
import BalanceAvailableCard from '../balanceAvailableCard';
import WaintingFundsCard from '../waitingFundsCard';

const initialData = {
  waitingFunds: {
    amount: undefined,
  },
  available: {
    amount: undefined,
  },
  transferred: {
    amount: undefined,
  },
};

const CardsContainer = () => {
  const { companyId } = useContext(UserContext);
  const {
    data,
    status,
    message,
  } = useAxios({
    url: `company/${companyId}/financial/balance`,
    method: 'GET',
  }, initialData);

  return (
    <div className="financials-panel__cards">
      <BalanceAvailableCard
        amount={data.available.amount}
        status={status}
        message={message}
      />
      <WaintingFundsCard
        amount={data.waitingFunds.amount}
        status={status}
        message={message}
      />
    </div>
  );
};

export default CardsContainer;
