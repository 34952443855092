import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
} from 'react-router';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import { makeStyles } from '@material-ui/core';
import {
  Table,
  Thead,
  Pagination,
  Badge,
  Button,
  Grid,
} from '../../../../components';
import Tbody from '../../components/table/tbody';
import {
  formatAsDate,
  formatAsTime,
  formatAsDatetime,
  status as listOfStatus,
} from '../../../../utils';
import {
  DeleteIcon,
  EditIcon,
} from '../../../../assets/icons';
import api from '../../../../api';
import ConfirmActionModal from '../../../../components/confirmActionModal';
import notificationSystem from '../../../../services/notificationSystemRef';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    textTransform: 'none',
  },
}));

const CreationDateCell = ({ value }) => (value && formatAsDatetime(value)) || '-';

CreationDateCell.propTypes = {
  value: PropTypes.number.isRequired,
};

const ValidHoursStartCell = ({ cell }) => {
  const {
    row: {
      original: {
        validHours,
      },
    },
  } = cell;

  const invalidHours = (
    !validHours
    || Number.isNaN(validHours.start)
    || Number.isNaN(validHours.finish)
  );

  if (invalidHours) {
    return '-';
  }

  return `${formatAsTime(validHours.start)} - ${formatAsTime(validHours.finish)}`;
};

ValidHoursStartCell.propTypes = {
  cell: PropTypes.shape({
    row: {
      original: {
        validHours: {
          start: PropTypes.number.isRequired,
          finish: PropTypes.number.isRequired,
        },
      },
    },
  }).isRequired,
};

const TypeCell = ({ value }) => {
  const { t } = useTranslation();
  const text = t((value === 'FIRST_TIME' && 'commom.yes') || 'commom.no');

  return text;
};

TypeCell.propTypes = {
  value: PropTypes.string.isRequired,
};

const ActiveCell = ({ value }) => {
  const { t } = useTranslation();
  const variant = (value && 'success') || 'secondary';
  const text = t((value && 'commom.active') || 'commom.inactive');

  return (
    <Badge variant={variant}>
      {text}
    </Badge>
  );
};

ActiveCell.propTypes = {
  value: PropTypes.bool.isRequired,
};

const ValidPeriodCell = ({ cell }) => {
  const {
    row: {
      original: {
        validPeriod,
      },
    },
  } = cell;

  if (validPeriod && validPeriod.start && validPeriod.finish) {
    return `${formatAsDate(validPeriod.start)} - ${formatAsDate(validPeriod.finish)}`;
  }

  return '-';
};

ValidPeriodCell.propTypes = {
  cell: PropTypes.shape({
    row: {
      original: {
        validPeriod: {
          start: PropTypes.number.isRequired,
          finish: PropTypes.number.isRequired,
        },
      },
    },
  }).isRequired,
};

const ActionsCell = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(listOfStatus.IDLE);
  const [error, setError] = useState('');
  const {
    cell: {
      row: {
        original,
      },
    },
    removeCouponByCode,
  } = props;
  const { code } = original;

  const handleClose = () => {
    setOpen(false);
    setStatus(listOfStatus.IDLE);
    setError('');
  };
  const handleConfirm = async () => {
    try {
      setStatus(listOfStatus.PENDING);
      await api.put(`coupon/inactive/${code}`);
      setStatus(listOfStatus.RESOLVED);
      notificationSystem.current.addNotification({
        title: t('coupons.delete.success.notification.title'),
        message: t('coupons.delete.success.notification.message'),
        level: 'success',
        position: 'tr',
        autoDismiss: 3,
      });
      removeCouponByCode(code);
      handleClose();
    } catch (err) {
      setStatus(listOfStatus.REJECTED);
      setError(err.message);
    }
  };

  return (
    <Grid>
      <ConfirmActionModal
        open={open}
        status={status}
        text={`${t('coupons.delete.confirm.message')} ${code}?`}
        handleConfirm={handleConfirm}
        handleConfirmText={t('commom.delete')}
        handleClose={handleClose}
        handleCloseText={t('commom.cancel')}
        error={error}
      />
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        startIcon={<EditIcon color="primary" />}
        onClick={() => history.push({
          pathname: 'coupons/create',
          state: {
            background: history.location,
            coupon: original,
          },
        })}
      >
        {t('commom.edit')}
      </Button>
      <Button
        className={classes.button}
        variant="text"
        color="secondary"
        startIcon={<DeleteIcon color="error" />}
        onClick={() => setOpen(true)}
      >
        {t('commom.delete')}
      </Button>
    </Grid>
  );
};

ActionsCell.propTypes = {
  cell: PropTypes.shape({
    row: {
      original: {
        code: PropTypes.string.isRequired,
      },
    },
  }).isRequired,
  removeCouponByCode: PropTypes.func.isRequired,
};

const TableOfCoupons = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState([]);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(listOfStatus.IDLE);
  const [message, setMessage] = useState('');
  const autoResetPageRef = useRef(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setStatus((oldStatus) => (oldStatus === listOfStatus.IDLE && listOfStatus.PENDING)
          || oldStatus);

        const response = await api.get('/coupon');

        setData(response.data);
        setStatus(listOfStatus.RESOLVED);
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data);
        } else if (error.message === 'Network Error') {
          setMessage('network.error.message');
        } else {
          setMessage('generic.error.message');
        }

        setStatus(listOfStatus.REJECTED);
      }
    };

    getData();
  }, [location]);

  const columns = useMemo(() => [
    {
      Header: 'creationDate',
      Cell: CreationDateCell,
      accessor: 'creationDate',
    },
    {
      Header: t('coupons.table.code.column.title'),
      accessor: 'code',
    },
    {
      Header: t('coupons.table.validPeriod.column.title'),
      Cell: ValidPeriodCell,
      accessor: 'validPeriod.start',
      align: 'center',
    },
    {
      Header: t('coupons.table.validHours.column.title'),
      Cell: ValidHoursStartCell,
      accessor: 'validHours.start',
      align: 'center',
    },
    {
      Header: t('coupons.table.active.column.title'),
      Cell: ActiveCell,
      accessor: 'active',
      align: 'center',
    },
    {
      Header: t('coupons.table.type.column.title'),
      Cell: TypeCell,
      accessor: 'type',
      align: 'center',
    },
    {
      Header: t('coupons.table.remuneration.column.title'),
      Cell: ({ value }) => value || '-',
      accessor: 'remuneration.remunerationPeriod',
      align: 'center',
    },
    {
      Header: t('coupons.table.influencer.column.title'),
      accessor: 'influencer.mail',
      align: 'center',
    },
    {
      Header: t('coupons.table.totalUses.column.title'),
      accessor: 'totalUses',
      align: 'center',
    },
    {
      Header: t('coupons.table.actions.column.title'),
      Cell: ActionsCell,
      accessor: 'actions',
      align: 'center',
    },
  ], [t]);

  useEffect(() => {
    autoResetPageRef.current = true;
    const activeCoupons = data.filter((coupon) => coupon.active);
    setCoupons(activeCoupons);
  }, [data]);

  const removeCouponByCode = (code) => {
    autoResetPageRef.current = true;
    setCoupons((old) => old.filter((row) => (row.code !== code)));
  };

  useEffect(() => {
    autoResetPageRef.current = false;
  }, [coupons]);

  const instance = useTable(
    {
      columns,
      data: coupons,
      initialState: {
        pageSize: 10,
        isSorted: true,
        hiddenColumns: ['creationDate'],
        sortBy: [
          {
            id: 'creationDate',
            desc: true,
          },
        ],
      },
      removeCouponByCode,
      autoResetPage: !autoResetPageRef.current,
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfCoupons;
