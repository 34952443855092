import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
} from '../../components';
import TableOfRatings from './components/tableOfRatings';

const Ratings = () => (
  <div className="content">
    <Row>
      <Col md={12}>
        <ContentCard
          ctTableFullWidth
          ctTableResponsive
          content={(
            <ErrorBoundary
              fallback={TableErrorFallback}
              fallbackRender={TableErrorFallback}
              FallbackComponent={TableErrorFallback}
            >
              <TableOfRatings />
            </ErrorBoundary>
          )}
        />
      </Col>
    </Row>
  </div>
);

export default Ratings;
