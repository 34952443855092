/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useContext } from "react";
import { Navbar } from "../index";
import { useLocation } from "react-router-dom";
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import { DocContext } from "../../context";

const Header = ({ routes }) => {
  const location = useLocation();
  const { showSidebar, setShowSidebar } = useContext(DocContext);

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];

      if (location.pathname.indexOf(route.layout + route.path) !== -1 && !route.subRoutes) {
        return route.name;
      }
      if (route.subRoutes) {
        for (
          let objPosition = 0;
          objPosition < route.subRoutes.length;
          objPosition++
        ) {
          const objRoute = route.subRoutes[objPosition];
          if (
            location.pathname.indexOf(objRoute.layout + objRoute.path) !== -1
          ) {
            return objRoute.name;
          }
        }
      }
    }
    return "Brand";
  };

  useEffect(() => {
    const toggle = () => document.documentElement.classList.toggle("nav-open");

    if (showSidebar) {
      var node = document.createElement("div");
      node.id = "bodyClick";

      node.onclick = function () {
        this.parentElement.removeChild(this);
        setShowSidebar(false);
        toggle();
      };

      document.body.appendChild(node);
      toggle();
    } else if (document.documentElement.classList.contains("nav-open")) {
      document.getElementById("bodyClick").remove();
      toggle();
    }
  }, [showSidebar, setShowSidebar]);

  return (
    <Navbar fluid>
      <Navbar.Header>
        <Navbar.Brand>{getBrandText()}</Navbar.Brand>
        <Navbar.Toggle onClick={() => setShowSidebar(!showSidebar)} />
        <AdminNavbarLinks />
      </Navbar.Header>
    </Navbar>
  );
};

export default Header;
