import moment from 'moment';

export { default as brazilianStates } from './brazilianStates';
export { default as medicalSpecialties } from './medicalSpecialties';

export const formatAsCurrency = (value, divider = 100) => (value / divider)
  .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatAsCurrencyWithoutDivider = (value) => (value || 0)
  .toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

export const formatAsCPF = (p) => `${p[0]}${p[1]}${p[2]}.${p[3]}${p[4]}${p[5]}.${p[6]}${p[7]}${p[8]}-${p[9]}${p[10]}`;

export const formatAsDate = (value) => moment(value).format('DD/MM/YYYY');
export const formatAsTime = (value) => {
  const H = Math.trunc(value / 60);
  const m = value % 60;

  return moment({ H, m }).format('HH:mm');
};
export const formatAsDatetime = (value) => moment(value).format('DD/MM/YYYY HH:mm');

export const formatAsDateFromToLocalDateTime = (date) => (date < 10 ? `0${date}` : date);

export const status = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

export const initialStatus = {
  status: status.IDLE,
  message: '',
};

export const userTypes = {
  ADMIN: 'ADMIN',
  COMPANY: 'COMPANY',
};

export const specialities = {
  EMERGENCY: 'medical.specialty.name.emergency',
  TELEMEDICINE: 'medical.specialty.name.telemedicine',
};

export const sort = (rowA, rowB) => {
  const nameA = rowA.values.name.toLowerCase();
  const nameB = rowB.values.name.toLowerCase();

  if (nameA > nameB) {
    return 1;
  }
  if (nameA < nameB) {
    return -1;
  }
  return 0;
};
