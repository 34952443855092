import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
} from 'react-router';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Pagination,
  Button,
} from '../../../../components';
import Tbody from '../../components/table/tbody';
import couponsService from '../../../../services/coupons';
import {
  formatAsCurrencyWithoutDivider,
  formatAsDateFromToLocalDateTime,
  status as listOfStatus,
} from '../../../../utils';
import downloadExcel from '../../../../utils/excel';

import './index.scss';

const ValueDateCell = ({ value }) => {
  const { dayOfMonth, monthValue, year } = value;

  if (dayOfMonth && monthValue && year) {
    const day = formatAsDateFromToLocalDateTime(dayOfMonth);
    const month = formatAsDateFromToLocalDateTime(monthValue);

    return `${day}/${month}/${year}`;
  }
  return '-';
};

ValueDateCell.propTypes = {
  value: PropTypes.shape({
    dayOfMonth: PropTypes.number.isRequired,
    monthValue: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
  }),
};

const ValuePriceCell = ({ value }) => (value != null && formatAsCurrencyWithoutDivider(value)) || formatAsCurrencyWithoutDivider(189);

ValuePriceCell.propTypes = {
  value: PropTypes.number.isRequired,
};

const TableOfCoupons = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(listOfStatus.IDLE);
  const [message, setMessage] = useState('');
  const autoResetPageRef = useRef(false);

  const excelFileName = `${t('coupons.usage.history.excel.file.name')}-${new Date().toISOString()}`;

  useEffect(() => {
    const getData = async () => {
      try {
        setStatus((oldStatus) => (oldStatus === listOfStatus.IDLE && listOfStatus.PENDING)
          || oldStatus);

        const response = await couponsService.usageHistory();

        setData(response);
        setStatus(listOfStatus.RESOLVED);
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data);
        } else if (error.message === 'Network Error') {
          setMessage('network.error.message');
        } else {
          setMessage('generic.error.message');
        }

        setStatus(listOfStatus.REJECTED);
      }
    };

    getData();
  }, [location]);

  const excelHeader = [
    t('coupons.usage.history.table.usage.date.column.title'),
    t('coupons.usage.history.table.coupon.column.title'),
    t('coupons.usage.history.table.patient.column.title'),
    t('coupons.usage.history.table.original.value.column.title'),
    t('coupons.usage.history.table.value.with.coupon.column.title'),
    t('coupons.usage.history.table.influencer.column.title'),
  ];

  const excelData = data.map((coupon) => {
    const { dayOfMonth, monthValue, year } = coupon.creationDate;

    const day = formatAsDateFromToLocalDateTime(dayOfMonth);
    const month = formatAsDateFromToLocalDateTime(monthValue);

    const servicePrice = formatAsCurrencyWithoutDivider(coupon.servicePrice);
    const price = formatAsCurrencyWithoutDivider(coupon.price);

    return ({
      usageDate: `${day}/${month}/${year}`,
      couponCode: coupon.couponCode,
      patientName: coupon.patientName,
      servicePrice,
      price,
      mail: coupon.mail,
    });
  });

  const columns = useMemo(() => [
    {
      Header: t('coupons.usage.history.table.usage.date.column.title'),
      Cell: ValueDateCell,
      accessor: 'creationDate',
      align: 'left',
    },
    {
      Header: t('coupons.usage.history.table.coupon.column.title'),
      Cell: ({ value }) => value || '-',
      accessor: 'couponCode',
      align: 'left',
    },
    {
      Header: t('coupons.usage.history.table.patient.column.title'),
      Cell: ({ value }) => value || '-',
      accessor: 'patientName',
      align: 'left',
    },
    {
      Header: t('coupons.usage.history.table.original.value.column.title'),
      Cell: ValuePriceCell,
      accessor: 'servicePrice',
      align: 'left',
    },
    {
      Header: t('coupons.usage.history.table.value.with.coupon.column.title'),
      Cell: ValuePriceCell,
      accessor: 'price',
      align: 'left',
    },
    {
      Header: t('coupons.usage.history.table.influencer.column.title'),
      Cell: ({ value }) => value || '-',
      accessor: 'mail',
      align: 'left',
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        isSorted: true,
      },
      autoResetPage: !autoResetPageRef.current,
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <div className="usageHistory__buttonContainer">
        <Button
          onClick={() => downloadExcel(excelData, excelFileName, excelHeader)}
        >
          Baixar excel
        </Button>
      </div>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody instance={instance} status={status} message={message} />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfCoupons;
