import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ContentCard } from '../../components';
import { TableOfCalls, ErrorFallback } from './table/Table';

const HouseCalls = () => (
  <div className="content">
    <ContentCard
      ctTableFullWidth
      ctTableResponsive
      content={
        <ErrorBoundary
          fallback={ErrorFallback}
          fallbackRender={ErrorFallback}
          FallbackComponent={ErrorFallback}
        >
          <TableOfCalls />
        </ErrorBoundary>
      }
    />
  </div>
);

export default HouseCalls;
