import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
} from '../../components';
import TableOfDependents from './components/tableOfDependents';
import SearchContainer from './components/searchContainer';
import { DependentsContext } from '../../context';
import {
  reducer,
  initialState,
  actionTypes,
} from './reducer';

const Dependents = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DependentsContext.Provider value={{ state, dispatch, actionTypes }}>
      <div className="content">
        <Row>
          <Col md={12}>
            <ContentCard
              title={t('dependents.filter.container.title')}
              ctTableFullWidth
              ctTableResponsive
              content={<SearchContainer />}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <ContentCard
              ctTableFullWidth
              ctTableResponsive
              content={(
                <ErrorBoundary
                  fallback={TableErrorFallback}
                  fallbackRender={TableErrorFallback}
                  FallbackComponent={TableErrorFallback}
                >
                  <TableOfDependents />
                </ErrorBoundary>
              )}
            />
          </Col>
        </Row>
      </div>
    </DependentsContext.Provider>
  );
};

export default Dependents;
