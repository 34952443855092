import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Thead,
  Badge,
  Pagination,
  TableBody,
  TableRow,
  TableCell,
} from '../../../components';
import { doctorService } from '../../../services';
import {
  specialities,
  userTypes,
} from '../../../utils';
import './Table.scss';
import { UserContext } from '../../../context';

const renderStatus = (status) => {
  if (status === 'IN_TRANSIT') {
    return <Badge variant="warning">EM TRÂNSITO</Badge>;
  }
  if (status === 'OFFLINE') {
    return <Badge variant="secondary">OFFLINE</Badge>;
  }
  if (status === 'AVAILABLE') {
    return <Badge variant="info">ONLINE</Badge>;
  }

  return <Badge variant="primary">EM ATENDIMENTO</Badge>;
};

const renderOnDuty = (onDuty) => {
  if (onDuty) {
    return <Badge variant="success">DISPONÍVEL</Badge>;
  }
  return <Badge variant="danger">INDISPONÍVEL</Badge>;
};

const renderCurrentLocation = (location, docSpecialities) => {
  if (docSpecialities.includes(specialities.EMERGENCY)) {
    if (location && location.latitude && location.longitude) {
      const { latitude, longitude } = location;
      return (
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
        >
          Última localização
        </a>
      );
    }
  }
  return null;
};

const renderActions = (cellPhone) => (
  <a
    className="action"
    href={`https://wa.me/${cellPhone}`}
  >
    Entrar em contato
  </a>
);

const renderEmail = (email) => <p className="email">{email}</p>;

const TableOfProfessionals = ({ instance }) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  const { t } = useTranslation();
  const { userType } = useContext(UserContext);

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <TableBody {...getTableBodyProps()}>
          {
            page
              .map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {
                      row.cells.map((cell) => {
                        const {
                          value,
                          column: {
                            Header,
                            align,
                          },
                          row: { original },
                        } = cell;
                        let component = null;

                        if (Header === 'Status') {
                          component = renderStatus(value);
                        } else if (Header === 'Atividade') {
                          component = renderOnDuty(value);
                        } else if (Header === 'Última Localização') {
                          component = renderCurrentLocation(value, original.specialities);
                        } else if (Header === 'Ações') {
                          component = renderActions(value);
                        } else if (Header === 'E-mail') {
                          component = renderEmail(value, t);
                        } else {
                          component = cell.render('Cell');
                        }

                        return (
                          <TableCell
                            {...cell.getCellProps()}
                            align={align}
                          >
                            {component}
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })
          }
        </TableBody>
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfProfessionals;

TableOfProfessionals.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any).isRequired,
};
