import React, {
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { InfluencersContext } from '../../../../context';
import {
  Grid,
  Button,
} from '../../../../components';
import { GetAppRoundedIcon } from '../../../../assets/icons';
import useStyles from './styles';

const SearchContainer = () => {
  const {
    state,
    dispatch,
    actionTypes,
  } = useContext(InfluencersContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const headers = useMemo(() => state.columns
    .map(({ accessor, Header }) => ({
      key: accessor, label: Header,
    })), [state.columns]);

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
    >
      <Grid
        container
        item
        xs={6}
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <DatePicker
            inputVariant="outlined"
            value={state.monthAndYear}
            onChange={(date) => dispatch({
              type: actionTypes.setMonthAndYear,
              payload: moment({
                day: date.startOf('month').date(),
                month: date.month(),
                year: date.year(),
              }).valueOf(),
            })}
            views={['year', 'month']}
            label="Período"
            minDate={new Date('2018-01-01')}
            size="small"
          />
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={() => dispatch({
              type: actionTypes.setMonthAndYear,
              payload: null,
            })}
            size="small"
          >
            {t('clear.filters')}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justify="flex-end"
      >
        {
          state.influencers.length
            ? (
              <CSVLink
                data={state.filteredInfluencers}
                headers={headers}
                target="_blank"
                filename="influencers.csv"
                onClick={() => !(!state.filteredInfluencers || !state.filteredInfluencers.length)}
                className={classes.exportButton}
              >
                <GetAppRoundedIcon className={classes.exportButtonIcon} />
                {t('export')}
              </CSVLink>
            ) : null
        }
      </Grid>
    </Grid>
  );
};

export default SearchContainer;
