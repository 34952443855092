import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  CircularProgress,
  Badge,
  Button,
  TableBody,
  TableRow,
  TableCell,
} from '../../../../../../components';
import { CheckCircleIcon } from '../../../../../../assets/icons';
import { status as listOfStatus } from '../../../../../../utils';
import { TelemedicineContext } from '../../../../../../context';
import useStyles from './styles';

const Tbody = ({ instance, status, message }) => {
  const {
    dispatch,
    actionTypes,
  } = useContext(TelemedicineContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  return (
    <TableBody {...getTableBodyProps()}>
      {
        (
          status === listOfStatus.PENDING && (
            <TableRow>
              <TableCell
                colSpan={instance.columns.length}
                align="center"
              >
                <CircularProgress
                  color="primary"
                  size={30}
                />
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED
          && !page.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t('no.data.found')}
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            page
              .map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {
                      row
                        .cells
                        .map((cell) => {
                          const {
                            value,
                            column: {
                              id,
                              align,
                            },
                            row: { original },
                          } = cell;

                          let component = null;

                          if (id === 'requestDate') {
                            component = moment(value).format('HH:mm');
                          } else if (id === 'patientName') {
                            const { patientName, patientLastName, dependentName } = original;
                            component = `${dependentName || `${patientName || ''} ${patientLastName || ''}`}`;
                          } else if (id === 'houseCallStatus') {
                            component = (
                              <Badge variant="warning">
                                {t('telemedicine.accepted.calls.table.houseCallStatus.column.value.request')}
                              </Badge>
                            );
                          } else if (id === 'actions') {
                            component = (
                              <Button
                                className={classes.acceptCallButton}
                                onClick={() => {
                                  dispatch({
                                    type: actionTypes.setCallToAccept,
                                    payload: original,
                                  });
                                  dispatch({
                                    type: actionTypes.setOpenAcceptCallModal,
                                    payload: true,
                                  });
                                }}
                                startIcon={(
                                  <CheckCircleIcon
                                    color="action"
                                  />
                                )}
                              >
                                {t('telemedicine.accept.calls.table.accept.button')}
                              </Button>
                            );
                          } else {
                            component = value ? cell.render('Cell') : '-';
                          }

                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              align={align}
                            >
                              {component}
                            </TableCell>
                          );
                        })
                    }
                  </tr>
                );
              })
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t(message)}
              </TableCell>
            </TableRow>
          )
        )
      }
    </TableBody>
  );
};

Tbody.propTypes = {
  instance: PropTypes.shape({
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
    page: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tbody;
