import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
} from '../../../components';
import UsageHistoryTable from './table';

const UsageHistory = () => {
  const { t } = useTranslation();

  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <ContentCard
            title={t('coupons.usage.history.table.title')}
            ctTableFullWidth
            ctTableResponsive
            content={(
              <ErrorBoundary
                fallback={TableErrorFallback}
                fallbackRender={TableErrorFallback}
                FallbackComponent={TableErrorFallback}
              >
                <UsageHistoryTable />
              </ErrorBoundary>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UsageHistory;
