import { makeStyles } from '@material-ui/core/styles';
import { typography } from '../../../../../material';

export default makeStyles(() => ({
  remunerationPeriod: {
    ...typography['hint-9px-0.584rem-medium'],
  },
  remunerationDates: {
    ...typography['paragraph-16px-1rem-light'],
    whiteSpace: 'nowrap',
  },
  price: {
    ...typography['paragraph-16px-1rem-medium'],
  },
  influencerRevenue: {
    ...typography['paragraph-16px-1rem-medium'],
  },
}));
