export const actionTypes = {
  setColumns: 'SET_COLUMNS',
  setInfluencers: 'SET_INFLUENCERS',
  setFilteredInfluencers: 'SET_FILTERED_INFLUENCERS',
  setMonthAndYear: 'SET_MONTH_YEAR',
};

export const initialState = {
  columns: [],
  influencers: [],
  filteredInfluencers: [],
  monthAndYear: null,
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.setInfluencers:
      return {
        ...state,
        influencers: action.payload,
      };
    case actionTypes.setFilteredInfluencers:
      return {
        ...state,
        filteredInfluencers: action.payload,
      };
    case actionTypes.setColumns:
      return {
        ...state,
        columns: action.payload,
      };
    case actionTypes.setMonthAndYear:
      return {
        ...state,
        monthAndYear: action.payload,
      };
    default:
      throw new Error();
  }
}
