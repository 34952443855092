import React, {
  useMemo,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
} from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Pagination,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import {
  formatAsCPF,
  formatAsDate,
} from '../../../../utils';
import { DependentsContext } from '../../../../context';

const TableOfDependents = () => {
  const { state } = useContext(DependentsContext);
  const { t } = useTranslation();
  const {
    status,
    data,
    message,
  } = useAxios({
    baseURL: `${process.env.REACT_APP_BACKOFICCE_API_URL}/`,
    url: 'dependent',
    method: 'GET',
    params: {
      showDeleted: true,
      allPatients: true,
    },
  }, []);

  const columns = useMemo(() => [
    {
      Header: t('dependents.table.patientId.column.title'),
      accessor: 'patientId',
    },
    {
      Header: t('dependents.table.patientName.column.title'),
      accessor: 'patientName',
    },
    {
      Header: t('dependents.table.id.column.title'),
      accessor: 'id',
    },
    {
      Header: t('dependents.table.name.column.title'),
      accessor: 'name',
    },
    {
      Header: t('dependents.table.cpf.column.title'),
      accessor: 'cpf',
      align: 'center',
      Cell: ({ value }) => formatAsCPF(value),
    },
    {
      Header: t('dependents.table.creationDate.column.title'),
      accessor: 'creationDate',
      align: 'center',
      Cell: ({ value }) => formatAsDate(value),
    },
    {
      Header: t('dependents.table.exclusionDate.column.title'),
      accessor: 'exclusionDate',
      align: 'center',
      Cell: ({ value }) => (value ? formatAsDate(value) : '-'),
    },
  ], [t]);

  const sortBy = useMemo(() => [{ id: 'patientName' }], []);
  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        isSorted: true,
        sortBy,
        hiddenColumns: ['patientId', 'id'],
        filters: [{
          id: 'name',
          value: state.name,
        }, {
          id: 'patientName',
          value: state.patientName,
        }],
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    setFilter,
  } = instance;

  useEffect(() => {
    setFilter('name', state.name);
    setFilter('patientName', state.patientName);
  }, [setFilter, state.name, state.patientName]);

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfDependents;
