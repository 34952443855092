import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import analytics from '../../../analytics';
import './AcceptModal.scss';

function AcceptModal({
  show, handleClose, handleAccept, doctors,
}) {
  const [selected, setSelected] = useState({});

  const acceptCall = (handle) => {
    if (!selected || !selected.id) return;

    handle(selected);
    analytics.track('Vinculou Ambulância', {
      ambulance: selected.id,
    });
    setSelected(false);
  };

  const close = (handle) => {
    handle();
    setSelected(false);
  };

  return (
    <>
      <Modal show={show} onHide={() => close(handleClose)} className="acceptModal">
        <Modal.Header closeButton>
          <Modal.Title><b>Aceitar Solicitação</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Antes de aceitar a solicitação, defina a unidade de atendimento
            que irá atender a este chamado:
          </p>
          <small>
            Lembre-se: Depois de aceita por você, esta solicitação ainda
            terá de ser confirmada pelo paciente que a solicitou.
            Aguarde esta confirmação para liberar e encaminhar a unidade de
            atendimento até o endereço informado.
          </small>
          <Select
            options={doctors}
            className="select"
            placeholder="Digite ou selecione na lista"
            onChange={setSelected}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => close(handleClose)}>Voltar</Button>
          <Button onClick={() => acceptCall(handleAccept)}>Aceitar Solicitação</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

AcceptModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  doctors: PropTypes.arrayOf(PropTypes.any),
};

AcceptModal.defaultProps = {
  show: false,
  doctors: [],
};

export default AcceptModal;
