import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKOFICCE_API_URL}`,
  headers: {
    'X-AUTH-APPLICATION-TOKEN': process.env.REACT_APP_BACKOFICCE_API_TOKEN,
  },
  timeout: 12000,
});

const setUserToken = (token, id) => {
  instance.defaults.headers.common['X-AUTH-TOKEN'] = token;
  instance.defaults.headers.common['X-CLIENT-ID'] = id;
};

const clearUserToken = () => {
  instance.defaults.headers.common['X-AUTH-TOKEN'] = null;
  instance.defaults.headers.common['X-CLIENT-ID'] = null;
};

const checkForTokenAndId = () => {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('id');

  if (token && userId) {
    setUserToken(token, userId);
  }
};

checkForTokenAndId();

export {
  setUserToken,
  clearUserToken,
};

export default instance;
