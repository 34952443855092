import { makeStyles } from '@material-ui/core/styles';
import { typography } from '../../../../material';

export default makeStyles({
  fieldset: {
    marginBottom: 16,
  },
  legend: {
    ...typography['paragraph-16px-1rem-regular'],
    border: 'none',
  },
  submitButton: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
