export default {
  'paragraph-16px-1rem-light': {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '1rem',
    lineHeight: '120%', /* or 19px */
  },
  'paragraph-16px-1rem-regular': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '120%', /* or 19px */
  },
  'paragraph-16px-1rem-medium': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '120%', /* or 19px */
  },
  'paragraph-16px-1rem-medium-uppercase': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '120%', /* or 19px */
    textTransform: 'uppercase',
  },
  'title-27px-1.713rem-medium': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.713rem',
    lineHeight: '120%', /* or 32px */
  },
  'subtitle-21px-1.309rem-medium': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1.309rem',
    lineHeight: '120%', /* or 25px */
  },
  'description-12px-0.764rem-medium': {
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '0.764rem',
    lineHeight: '14.4px',
  },
  'description-12px-0.764rem-light': {
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '0.764rem',
    lineHeight: '120%', /* or 14px */
  },
  'hint-9px-0.584rem-medium': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.584rem',
    lineHeight: '120%', /* identical to box height, or 11px */
  },
  'CTA-16px-1rem-medium-uppercase': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '120%', /* or 19px */
    textTransform: 'uppercase',
  },
};
