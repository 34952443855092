import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Professionals,
  HouseCalls,
  OpenHouseCalls,
  Financials,
  Leads,
  Telemedicine,
  PayInfluencer,
  Ratings,
  BackofficeUsers,
  Dependents,
  CouponsUsageHistory,
  RegisteredCoupons,
} from '../views';
import {
  RegulationIcon,
  ProfessionalsIcon,
  HistoryIcon,
  FinancialsIcon,
  LeadsIcon,
  CouponsIcon,
  TelemedicineIcon,
  PayInfluencersIcon,
  RatingsIcon,
  BackofficeUsersIcon,
  DependentsIcon,
} from '../assets/icons';
import { userTypes } from '../utils';

const useRoutes = (userType) => {
  const { t } = useTranslation();

  const couponsSubRoutes = [
    {
      path: '/coupons',
      name: t('coupons.register.page.title'),
      component: RegisteredCoupons,
      layout: '/admin',
    },
    {
      path: '/usage-history',
      name: t('coupons.usage.history.page.title'),
      component: CouponsUsageHistory,
      layout: '/admin',
    },
  ];

  const routes = useMemo(() => {
    const openHouseCallsRoute = {
      path: '/openhousecalls',
      name: t('openHouseCalls.page.title'),
      icon: <RegulationIcon />,
      component: OpenHouseCalls,
      layout: '/admin',
    };
    const houseCallsRoute = {
      path: '/housecalls',
      name: t('houseCalls.page.title'),
      icon: <HistoryIcon />,
      component: HouseCalls,
      layout: '/admin',
    };
    const professionalsRoute = {
      path: '/professionals',
      name: t('professionals.page.title'),
      icon: <ProfessionalsIcon />,
      component: Professionals,
      layout: '/admin',
    };
    const financialsRoute = {
      path: '/financials',
      name: t('financials.page.title'),
      icon: <FinancialsIcon />,
      component: Financials,
      layout: '/admin',
    };
    const leadsRoute = {
      path: '/leads',
      name: t('leads.page.title'),
      icon: <LeadsIcon />,
      component: Leads,
      layout: '/admin',
    };
    const couponsRoute = {
      name: t('coupons.sidebar.button.title'),
      icon: <CouponsIcon />,
      subRoutes: couponsSubRoutes,
    };

    const telemedicineRoute = {
      path: '/telemedicine',
      name: t('telemedicine.page.title'),
      icon: <TelemedicineIcon />,
      component: Telemedicine,
      layout: '/admin',
    };
    const payInfluencerRoute = {
      path: '/payinfluencers',
      name: t('pay.influencer.page.title'),
      icon: <PayInfluencersIcon />,
      component: PayInfluencer,
      layout: '/admin',
    };
    const ratingsRoute = {
      path: '/retings',
      name: t('ratings.page.title'),
      icon: <RatingsIcon />,
      component: Ratings,
      layout: '/admin',
    };
    const backofficeUsersRoute = {
      path: '/users/backoffice',
      name: t('backoffice.users.page.title'),
      icon: <BackofficeUsersIcon />,
      component: BackofficeUsers,
      layout: '/admin',
    };
    const dependentsRoute = {
      path: '/dependents',
      name: t('dependents.page.title'),
      icon: <DependentsIcon />,
      component: Dependents,
      layout: '/admin',
    };

    if (userType === userTypes.ADMIN) {
      const adminRoutes = [
        houseCallsRoute,
        professionalsRoute,
        leadsRoute,
        couponsRoute,
        payInfluencerRoute,
        ratingsRoute,
        backofficeUsersRoute,
        dependentsRoute,
      ];

      return adminRoutes;
    }
    if (userType === userTypes.COMPANY) {
      const companyRoutes = [
        // openHouseCallsRoute,
        telemedicineRoute,
        houseCallsRoute,
        professionalsRoute,
        financialsRoute,
      ];

      return companyRoutes;
    }

    return [];
  }, [t, userType, couponsSubRoutes]);

  return routes;
};

export default useRoutes;
