import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '../../../../components';
import { CancelIcon } from '../../../../assets/icons';
import { houseCallService } from '../../../../services';
import {
  status,
  initialStatus,
} from '../../../../utils';
import ConfirmActionModal from '../../../../components/confirmActionModal';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: theme.palette.text.primary,
    textTransform: 'none',
  },
}));

const ActionsCell = ({ rowIndex, call, updateMyData }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [requestStatus, setRequestStatus] = useState(initialStatus);
  const classes = useStyles();

  const handleClose = () => setOpen(false);
  const handleConfirm = async () => {
    try {
      setRequestStatus({
        status: status.PENDING,
        message: '',
      });
      console.log('call', call);
      await houseCallService.postFinishCall(call.patientRequestId);
      setRequestStatus({
        status: status.RESOLVED,
        message: '',
      });
      setOpen(false);
      updateMyData(rowIndex, 'houseCallStatus', 'FINISHED');
    } catch (error) {
      setRequestStatus({
        status: status.REJECTED,
        message: error.message,
      });
    }
  };

  if (call.houseCallStatus === 'NOT_STARTED'
  || call.houseCallStatus === 'STARTED') {
    return (
      <>
        <ConfirmActionModal
          open={open}
          text={t('houseCalls.confirm.end.call.message')}
          status={requestStatus}
          handleClose={handleClose}
          handleCloseText={t('commom.no')}
          handleConfirm={handleConfirm}
          handleConfirmText={t('commom.yes')}
        />
        <Button
          variant="text"
          color="primary"
          className={classes.button}
          startIcon={(
            <CancelIcon
              color="error"
            />
          )}
          onClick={() => setOpen(true)}
        >
          {t('houseCalls.confirm.end.call.button.message')}
        </Button>
      </>
    );
  }

  return null;
};

ActionsCell.propTypes = {
  rowIndex: PropTypes.number.isRequired,
  call: PropTypes.shape({
    patientRequestId: PropTypes.string.isRequired,
    houseCallStatus: PropTypes.oneOf([PropTypes.string, null]),
  }).isRequired,
  updateMyData: PropTypes.func.isRequired,
};

export default ActionsCell;
