import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import {
  Table,
  Thead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Pagination,
  CircularProgress,
  Typography,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Divider,
} from '../../../components';
import ActionsCell from '../components/actionsCell';
import { UserContext } from '../../../context';
import { houseCallService } from '../../../services';
import { userTypes, status, initialStatus, formatAsDatetime } from '../../../utils';
import useStyles from './styles';
import { useQuery } from '../../../hooks';
import './Table.scss';

const RequestAddressCell = ({ cell }) => {
  const {
    row: {
      original: {
        requestAddress,
        requestAddressNumber,
        requestAddressComplement,
        requestCityName,
        requestStateCode,
        requestCountryIsoCode,
        requestAddressZipCode,
      },
    },
  } = cell;
  const { t } = useTranslation();
  const streetAddress = [requestAddress, requestAddressNumber, requestAddressComplement]
    .filter((value) => value || '')
    .join(' ');

  return (
    <div>
      <p>{streetAddress}</p>
      <p>{`${requestAddressZipCode ? `cep: ${requestAddressZipCode}` : ''}`}</p>
      <p>
        {requestCityName
          ? `${t(requestCityName)} / ${requestStateCode || ''} - ${requestCountryIsoCode || ''}`
          : ''}
      </p>
    </div>
  );
};

RequestAddressCell.propTypes = {
  cell: PropTypes.shape({
    row: {
      original: {
        id: PropTypes.string.isRequired,
        houseCallStatus: PropTypes.string.isRequired,
      },
    },
  }).isRequired,
};

const MedicalSpecialtyNameCell = ({ value }) => {
  const { t } = useTranslation();

  return t(value);
};

const PatientNameCell = ({ cell }) => {
  const {
    row: { original },
  } = cell;

  const { patientId } = original;

  const { name, dependentName } = patientId;

  return `${dependentName || `${name}`}`;
};

const HouseCallStatusCell = ({ cell }) => {
  const {
    row: {
      original: { requestStatus, houseCallStatus },
    },
  } = cell;

  console.log('requestStatus', requestStatus);

  if (houseCallStatus === 'WAITING') {
    return <Badge variant="warning">AGUARDANDO</Badge>;
  }
  if (houseCallStatus === 'NOT_STARTED') {
    return <Badge variant="primary">NÃO INICIADO</Badge>;
  }
  if (houseCallStatus === 'STARTED') {
    return <Badge variant="success">INICIADO</Badge>;
  }
  if (
    houseCallStatus === 'CANCELED' ||
    (requestStatus === 'CANCELED' && houseCallStatus === null)
  ) {
    return <Badge variant="danger">CANCELADO</Badge>;
  }
  if (houseCallStatus === 'FINISHED') {
    return <Badge variant="secondary">FINALIZADO</Badge>;
  }

  return <Badge variant="info">SOLICITADO</Badge>;
};

HouseCallStatusCell.propTypes = {
  cell: PropTypes.shape({
    row: {
      original: {
        requestStatus: PropTypes.string.isRequired,
        houseCallStatus: PropTypes.string.isRequired,
      },
    },
  }).isRequired,
};

const PaymentPendingCell = ({ value }) => {
  if (value === null) {
    return value;
  }
  return value ? <Badge variant="warning">PENDENTE</Badge> : <Badge variant="success">PAGO</Badge>;
};

PaymentPendingCell.propTypes = {
  value: PropTypes.oneOf([PropTypes.bool.isRequired, null]).isRequired,
};

const DatesColumn = ({ row, column }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { requestDate, acceptDate, startedDate, finishedDate } = row.original;

  return (
    <Grid container spacing={4} direction="row" justify={column.align} wrap="nowrap">
      <Grid item>
        <Typography className={classes.tag}>
          {t('houseCalls.calls.table.dates.column.requestDate.tag')}
        </Typography>
        <Typography className={classes.value}>
          {requestDate ? formatAsDatetime(requestDate) : '-'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.tag}>
          {t('houseCalls.calls.table.dates.column.acceptDate.tag')}
        </Typography>
        <Typography className={classes.value}>
          {acceptDate ? formatAsDatetime(acceptDate) : '-'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.tag}>
          {t('houseCalls.calls.table.dates.column.startedDate.tag')}
        </Typography>
        <Typography className={classes.value}>
          {startedDate ? formatAsDatetime(startedDate) : '-'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.tag}>
          {t('houseCalls.calls.table.dates.column.finishedDate.tag')}
        </Typography>
        <Typography className={classes.value}>
          {startedDate ? formatAsDatetime(finishedDate) : '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

DatesColumn.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      requestDate: PropTypes.number,
      acceptDate: PropTypes.number,
      startedDate: PropTypes.number,
      finishedDate: PropTypes.number,
    }),
  }).isRequired,
  column: PropTypes.shape({
    align: PropTypes.string,
  }).isRequired,
};

const DateFilter = ({ column }) => {
  const { filterValue, setFilter, Header } = column;

  const handleChange = (value) => {
    const date = moment({
      day: value.startOf('day').date(),
      month: value.month(),
      year: value.year(),
    }).valueOf();

    setFilter(date);
  };

  return (
    <Grid item>
      <DatePicker
        inputVariant="outlined"
        value={filterValue || null}
        onChange={handleChange}
        label={Header}
        minDate={new Date('2018-01-01')}
        size="small"
        format="DD/MM/yyyy"
        cancelLabel="Cancelar"
      />
    </Grid>
  );
};

DateFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.oneOf([PropTypes.number, undefined]),
    setFilter: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
  }).isRequired,
};

const TextFilter = ({ column }) => {
  const { filterValue, setFilter, Header } = column;

  return (
    <Grid item>
      <TextField
        name="patientName"
        label={Header}
        placeholder={`Digite o ${Header.toLowerCase()}`}
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value || undefined)}
        inputProps={{ maxLength: 150 }}
        variant="outlined"
        size="small"
        fullWidth
      />
    </Grid>
  );
};

TextFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.oneOf([PropTypes.string, undefined]),
    setFilter: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
  }).isRequired,
};

const MEDICIAL_SPECIALITY_OPTIONS = [
  { value: 'medical.specialty.name.emergency', label: 'medical.specialty.name.emergency' },
  { value: 'medical.specialty.name.telemedicine', label: 'medical.specialty.name.telemedicine' },
];
const HOUSE_CALL_STATUS_OPTIONS = [
  'REQUESTED',
  'CANCELED',
  'FINISHED',
  'WAITING',
  'NOT_STARTED',
  'STARTED',
];

const MedicalSpecialtyNameFilter = ({ column }) => {
  const { setFilter, filterValue, Header } = column;
  const { t } = useTranslation();

  const handleFilter = (option) => {
    let filter = filterValue || [];

    if (filter.includes(option)) {
      filter = filter.filter((opt) => opt !== option);
    } else {
      filter = [...filter, option];
    }

    setFilter(filter);
  };

  return (
    <Grid item>
      <FormControl>
        <FormLabel>{Header}</FormLabel>
        <FormGroup row>
          {MEDICIAL_SPECIALITY_OPTIONS.map(({ value, label }) => (
            <FormControlLabel
              control={
                <Checkbox
                  key={value}
                  checked={Array.isArray(filterValue) && filterValue.includes(value)}
                  onChange={({ target }) => handleFilter(target.value)}
                  value={value}
                  color="primary"
                  size="small"
                />
              }
              label={t(label)}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

MedicalSpecialtyNameFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.arrayOf(PropTypes.string),
    setFilter: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
  }).isRequired,
};

const HouseCallStatusFilter = ({ column }) => {
  const { setFilter, filterValue, Header } = column;
  const { t } = useTranslation();

  const handleFilter = (option) => {
    let filter = filterValue || [];

    if (filter.includes(option)) {
      filter = filter.filter((opt) => opt !== option);
    } else {
      filter = [...filter, option];
    }

    setFilter(filter);
  };

  return (
    <Grid item>
      <FormControl>
        <FormLabel>{Header}</FormLabel>
        <FormGroup row>
          {HOUSE_CALL_STATUS_OPTIONS.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  key={option}
                  checked={Array.isArray(filterValue) && filterValue.includes(option)}
                  onChange={({ target }) => handleFilter(target.value)}
                  value={option}
                  color="primary"
                  size="small"
                />
              }
              label={t(option)}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};

HouseCallStatusFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.arrayOf(PropTypes.string),
    setFilter: PropTypes.func.isRequired,
    Header: PropTypes.string.isRequired,
  }).isRequired,
};

export const TableOfCalls = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const { userType } = useContext(UserContext);
  const { t } = useTranslation();
  const [requestStatus, setRequestStatus] = useState(initialStatus);
  const autoResetPageAndFiltersRef = useRef();
  const [data, setData] = useState([]);

  const updateMyData = (rowIndex, columnId, value) => {
    autoResetPageAndFiltersRef.current = true;

    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t('houseCalls.table.medicalSpecialtyName.column.title'),
        accessor: 'medicalSpecialtyName',
        filter: 'includes',
        Filter: MedicalSpecialtyNameFilter,
        Cell: MedicalSpecialtyNameCell,
      },
      {
        Header: t('houseCalls.table.patientName.column.title'),
        accessor: 'patientName',
        Filter: TextFilter,
        Cell: PatientNameCell,
      },
      {
        Header: t('houseCalls.table.requestAddress.column.title'),
        accessor: 'requestAddress',
        disableFilters: true,
        Cell: RequestAddressCell,
      },
      {
        Header: t('houseCalls.table.doctorName.column.title'),
        accessor: 'doctorName',
        Filter: TextFilter,
      },
      {
        Header: t('houseCalls.table.companyName.column.title'),
        accessor: 'companyName',
        disableFilters: true,
      },
      {
        Header: t('houseCalls.table.houseCallStatus.column.title'),
        accessor: 'houseCallStatus',
        align: 'center',
        Filter: HouseCallStatusFilter,
        Cell: HouseCallStatusCell,
      },
      {
        Header: t('houseCalls.table.paymentPending.column.title'),
        accessor: 'paymentPending',
        align: 'center',
        disableFilters: true,
        Cell: PaymentPendingCell,
      },
      {
        Header: t('houseCalls.table.startedDate.column.title'),
        accessor: 'startedDate',
        align: 'center',
        Cell: DatesColumn,
        Filter: DateFilter,
      },
      {
        Header: t('houseCalls.table.actions.column.title'),
        accessor: 'actions',
        align: 'center',
        disableFilters: true,
      },
    ],
    [t],
  );

  const hiddenColumns = useMemo(() => {
    if (userType === userTypes.ADMIN) return [];

    return ['companyName'];
  }, [userType]);

  useEffect(() => {
    const getData = async () => {
      try {
        setRequestStatus({
          status: status.PENDING,
          message: '',
        });

        const params = new URLSearchParams();
        const startedDate = query.get('startedDate');
        if (startedDate) {
          params.set('startDate', startedDate);
          params.set('endDate', startedDate);
        }
        const patientName = query.get('patientName')?.split(' ');
        if (patientName) {
          const [patientFirstName, patientLastName] = patientName;
          if (patientFirstName) {
            params.set('patientFirstName', patientFirstName);
          }
          if (patientLastName) {
            params.set('patientLastName', patientLastName);
          }
        }
        const doctorName = query.get('doctorName');
        if (doctorName) {
          params.set('profissionalName', doctorName);
        }
        const medicalSpecialtyName = query.get('medicalSpecialtyName').split(',');
        if (
          medicalSpecialtyName &&
          medicalSpecialtyName.length !== MEDICIAL_SPECIALITY_OPTIONS.length
        ) {
          params.set('serviceType', medicalSpecialtyName);
        }
        const houseCallStatus = query.get('houseCallStatus').split(',');

        const response = await houseCallService.getHouseCalls(params);

        autoResetPageAndFiltersRef.current = true;
        setData(response);
        setRequestStatus({
          status: status.RESOLVED,
          message: '',
        });
      } catch (error) {
        let message = '';

        if (error.response) {
          message = error.response.data;
        } else if (error.message === 'Network Error') {
          message = 'network.error.message';
        } else {
          message = 'generic.error.message';
        }

        setRequestStatus({
          status: status.REJECTED,
          message,
        });
      }
    };

    getData();
  }, [query]);

  useEffect(() => {
    autoResetPageAndFiltersRef.current = false;
  }, [data]);

  const initialFilter = useMemo(
    () => [
      {
        id: 'houseCallStatus',
        value: ['REQUESTED', 'CANCELED', 'FINISHED', 'WAITING', 'NOT_STARTED', 'STARTED'],
      },
      { id: 'medicalSpecialtyName', value: ['medical.specialty.name.telemedicine'] },
      { id: 'patientName', value: '' },
      { id: 'startedDate', value: null },
    ],
    [],
  );

  const instance = useTable(
    {
      columns,
      data,
      manualFilters: true,
      initialState: {
        pageSize: 10,
        isSorted: false,
        hiddenColumns,
        filters: initialFilter,
      },
      updateMyData,
      autoResetPage: autoResetPageAndFiltersRef.current,
      autoResetFilters: autoResetPageAndFiltersRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    state: { filters },
    headers,
    setAllFilters,
  } = instance;

  const replaceSearch = (params) => {
    const search = new URLSearchParams(params);
    history.replace({ search: search.toString() });
  };

  const getSearchParams = (arr) =>
    arr.filter(({ value }) => Boolean(value)).map(({ id, value }) => [id, value]);

  const handleFilter = (e) => {
    e.preventDefault();
    const params = getSearchParams(filters);
    replaceSearch(params);
  };

  const resetFilters = () => {
    setAllFilters(initialFilter);
    const params = getSearchParams(initialFilter);
    replaceSearch(params);
  };

  useEffect(resetFilters, []);

  const filtersOrder = [
    // 'patientName',
    // 'startedDate',
    'doctorName',
    // 'medicalSpecialtyName',
    // 'houseCallStatus',
  ];

  return (
    <div className={classes.root}>
      <Grid container component="form" onSubmit={handleFilter}>
        <Grid item xs={12} container alignItems="center" spacing="4">
          {filtersOrder.map((id) => {
            const column = headers.find((header) => header.id === id);

            return column.canFilter ? column.render('Filter') : null;
          })}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* <Grid
          container
          spacing="2"
          alignItems="center"
          className={classes.buttons}
        >
          <Grid item>
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={resetFilters}
            >
              Limpar filtro
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              Filtrar
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <TableBody {...getTableBodyProps()}>
          {(requestStatus.status === status.PENDING && (
            <TableRow>
              <TableCell colSpan={instance.columns.length} align="center">
                <CircularProgress color="primary" size={30} />
              </TableCell>
            </TableRow>
          )) ||
            (requestStatus.status === status.RESOLVED && !page.length && (
              <TableRow>
                <TableCell align="center" colSpan={instance.columns.length}>
                  {t('no.data.found')}
                </TableCell>
              </TableRow>
            )) ||
            (requestStatus.status === status.RESOLVED &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      const {
                        column: { id, align },
                        row: { index, original },
                      } = cell;

                      return (
                        <TableCell {...cell.getCellProps()} align={align}>
                          {(id === 'actions' && (
                            <ActionsCell
                              call={original}
                              rowIndex={index}
                              updateMyData={updateMyData}
                            />
                          )) ||
                            cell.render('Cell')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              }))}
        </TableBody>
      </Table>
      <Pagination tableInstance={instance} />
    </div>
  );
};

export const ErrorFallback = ({ resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center" justify="center">
      <Typography color="error">{t('generic.error.message')}</Typography>
      <Button variant="text" color="primary" size="small" onClick={resetErrorBoundary}>
        {t('reload.button')}
      </Button>
    </Grid>
  );
};

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
};
