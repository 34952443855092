import React from 'react';
import { useHistory } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
  Grid,
  Button,
} from '../../../components';
import { AddCircleIcon } from '../../../assets/icons';
import TableOfCoupons from './table';

const DiscountCoupons = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <ContentCard
            ctTableFullWidth
            ctTableResponsive
            content={(
              <Grid
                container
                alignItems="flex-end"
                justify="flex-end"
              >
                <Grid item>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon color="primary" />}
                    onClick={() => history.push({
                      pathname: 'coupons/create',
                      state: { background: history.location },
                    })}
                  >
                    {t('coupons.add.coupon.button.label')}
                  </Button>
                </Grid>
              </Grid>
            )}
          />
          <ContentCard
            title={t('coupons.table.title')}
            ctTableFullWidth
            ctTableResponsive
            content={(
              <ErrorBoundary
                fallback={TableErrorFallback}
                fallbackRender={TableErrorFallback}
                FallbackComponent={TableErrorFallback}
              >
                <TableOfCoupons />
              </ErrorBoundary>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DiscountCoupons;
