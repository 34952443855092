/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import userService from '../../services/user';
import person from '../../assets/img/faces/face-0.jpg';
import { Button } from '../index';

const AdminNavbarLinks = () => (
  <nav className="navbar-nav">
    <div className="user-info">
      <div>
        <p className="name">{userService.name()}</p>
        <p className="email">{userService.mail()}</p>
      </div>
      <img
        alt="logged user"
        className="photo"
        src={person}
        width="30"
        height="30"
      />
      <Button
        variant="text"
        color="primary"
        href="/"
        onClick={userService.logout}
      >
        Sair
      </Button>
    </div>
  </nav>
);

export default AdminNavbarLinks;
