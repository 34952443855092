import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Pagination,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import Tbody from './components/tbody';
import { formatAsDatetime } from '../../../../utils';

const TableOfRatings = () => {
  const { t } = useTranslation();
  const {
    status,
    data,
    message,
  } = useAxios({
    url: 'housecall/ratings',
    method: 'GET',
  }, []);
  const columns = useMemo(() => [
    {
      Header: t('ratings.table.creationDate.column.title'),
      accessor: 'creationDate',
      Cell: ({ value }) => formatAsDatetime(value),
    },
    {
      Header: t('ratings.table.patientName.column.title'),
      accessor: 'patientName',
      align: 'left',
    },
    {
      Header: t('ratings.table.doctorName.column.title'),
      accessor: 'doctorName',
      align: 'left',
    },
    {
      Header: t('ratings.table.medicalSpecialtyName.column.title'),
      accessor: 'medicalSpecialtyName',
      Cell: ({ value }) => t(value),
      align: 'center',
    },
    {
      Header: t('ratings.table.houseCallRatting.column.title'),
      accessor: 'houseCallRatting',
      align: 'center',
    },
    {
      Header: t('ratings.table.houseCallRattingDescription.column.title'),
      accessor: 'houseCallRattingDescription',
      align: 'center',
      maxWidth: 300,
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        isSorted: true,
        sortBy: [
          {
            id: 'creationDate',
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfRatings;
