import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress,
  TableBody,
  TableRow,
  TableCell,
} from '../../../../../../components';
import { status as listOfStatus } from '../../../../../../utils';

const Tbody = ({ instance, status, message }) => {
  const { t } = useTranslation();
  const {
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  return (
    <TableBody {...getTableBodyProps()}>
      {
        (
          status === listOfStatus.PENDING && (
            <TableRow>
              <TableCell
                colSpan={instance.columns.length}
                align="center"
              >
                <CircularProgress
                  color="primary"
                  size={30}
                />
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED
          && !page.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t('no.data.found')}
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            page
              .map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {
                      row
                        .cells
                        .map((cell) => (
                          <TableCell
                            {...cell.getCellProps()}
                            align={cell.column.align}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        ))
                    }
                  </TableRow>
                );
              })
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t(message)}
              </TableCell>
            </TableRow>
          )
        )
      }
    </TableBody>
  );
};

Tbody.propTypes = {
  instance: PropTypes.shape({
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
    page: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tbody;
