export const actionTypes = {
  setOpenCallsRequest: 'SET_OPEN_CALLS_REQUEST',
  setAcceptedCallsRequest: 'SET_ACCEPTED_CALLS_REQUEST',
  setOpenCalls: 'SET_OPEN_CALLS',
  setAcceptedCalls: 'SET_ACCEPTED_CALLS',
  addAcceptedCall: 'ADD_ACCEPTED_CALL',
  setOpenAcceptCallModal: 'SET_OPEN_ACCEPT_CALL_MODAL',
  reloadOpenCalls: 'RELOAD_OPEN_CALLS',
  reloadAcceptedCalls: 'RELOAD_ACCEPTED_CALLS',
};

export const initialState = {
  openCalls: [],
  acceptedCalls: [],
  openAcceptCallModal: false,
  callToAccept: null,
  openCallsRequest: {
    url: 'housecall',
    method: 'GET',
    params: {
      specialty: 'telemedicine',
      status: 'NEW',
    },
  },
  acceptedCallsRequest: {
    url: 'housecall',
    method: 'GET',
    params: {
      specialty: 'telemedicine',
      houseCallStatus: 'WAITING,NOT_STARTED,STARTED',
    },
  },
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.setOpenCallsRequest:
      return {
        ...state,
        openCallsRequest: action.payload,
      };
    case actionTypes.setAcceptedCallsRequest:
      return {
        ...state,
        acceptedCallsRequest: action.payload,
      };
    case actionTypes.setOpenCalls:
      return {
        ...state,
        openCalls: action.payload,
      };
    case actionTypes.setAcceptedCalls:
      return {
        ...state,
        acceptedCalls: action.payload,
      };
    case actionTypes.addAcceptedCall:
      return {
        ...state,
        acceptedCalls: [
          ...state.acceptedCalls,
          action.payload,
        ],
      };
    case actionTypes.setOpenAcceptCallModal:
      return {
        ...state,
        openAcceptCallModal: action.payload,
      };
    case actionTypes.setCallToAccept:
      return {
        ...state,
        callToAccept: action.payload,
        openAcceptCallModal: true,
      };
    case actionTypes.reloadOpenCalls:
      return {
        ...state,
        openCallsRequest: {
          ...initialState.openCallsRequest,
        },
      };
    case actionTypes.reloadAcceptedCalls:
      return {
        ...state,
        acceptedCallsRequest: {
          ...initialState.acceptedCallsRequest,
        },
      };
    default:
      throw new Error();
  }
}
