import React, {
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  usePagination,
  useSortBy,
} from 'react-table';
import {
  Table,
  Thead,
  Pagination,
} from '../../../../components';
import { useAxios } from '../../../../hooks';
import Tbody from './tbody';
import { TelemedicineContext } from '../../../../context';

const TableOfAcceptedCalls = () => {
  const {
    state,
    dispatch,
    actionTypes,
  } = useContext(TelemedicineContext);
  const { t } = useTranslation();
  const {
    status,
    data,
    message,
    setRequest,
  } = useAxios(state.acceptedCallsRequest, []);

  useEffect(() => setRequest(state.acceptedCallsRequest), [state.acceptedCallsRequest, setRequest]);

  useEffect(() => dispatch({
    type: actionTypes.setAcceptedCalls,
    payload: data,
  }), [data, dispatch, actionTypes.setAcceptedCalls]);

  useEffect(() => {
    setRequest(state.acceptedCallsRequest);
  }, [setRequest, state.acceptedCallsRequest]);

  const columns = useMemo(() => [
    {
      Header: t('telemedicine.accepted.calls.table.patientName.column.title'),
      accessor: 'patientName',
    },
    {
      Header: t('telemedicine.accepted.calls.table.doctorName.column.title'),
      accessor: 'doctorName',
    },
    {
      Header: t('telemedicine.accepted.calls.table.attendance.column.title'),
      accessor: 'requestDate',
      align: 'center',
    },
  ], [t]);

  const instance = useTable(
    {
      columns,
      data: state.acceptedCalls,
      initialState: {
        pageSize: 10,
      },
    },
    useSortBy,
    usePagination,
  );

  const { getTableProps } = instance;

  return (
    <>
      <Table {...getTableProps}>
        <Thead instance={instance} />
        <Tbody
          instance={instance}
          status={status}
          message={message}
        />
      </Table>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default TableOfAcceptedCalls;
