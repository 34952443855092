import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table as BootstrapTable } from 'react-bootstrap';
import Accept from '../../../components/action/Accept';
import Pagination from '../../../components/pagination/Pagination';
import SortArrow from '../../../components/sortArrow/SortArrow';
import './Table.scss';
import MiniBlock from '../miniblock/MiniBlock';

const renderAddress = (originalRow) => {
  const {
    requestAddress, requestAddressNumber, requestAddressZipCode,
  } = originalRow;

  return (
    <div>
      <p>{`${requestAddress}, ${requestAddressNumber} - ${requestAddressZipCode}`}</p>
    </div>
  );
};

const renderClientName = (originalRow) => {
  const { patientName, patientLastName, dependentName } = originalRow;
  return `${dependentName || `${patientName} ${patientLastName}`}`;
};

const renderTracking = ({ requestDate, acceptDate, startedDate }) => (
  <div>
    <MiniBlock title="Solicitado" timestamp={requestDate} />
    <MiniBlock title="Aceito" timestamp={acceptDate} />
    <MiniBlock title="Iniciado" timestamp={startedDate} />
  </div>
);

const renderActions = (original, handleAccept) => (
  <Accept>
    <a
      href="/"
      onClick={(e) => {
        e.preventDefault();
        handleAccept(original);
      }}
    >
      {' '}
      Aceitar
    </a>
  </Accept>
);

const renderCellphone = (value) => (value ? value.replace(/(\d{2})(\d{2})(\d{2})/, '$1 $2 $3') : null);

const renderDates = (originalRow) => {
  const {
    requestDate, acceptDate, startedDate, finishedDate,
  } = originalRow;

  const formatDate = (date) => moment(date).format('DD/MM/YY HH:mm');

  return (
    <div className="datesDiv">
      <p>{requestDate ? `Solicitação: ${formatDate(requestDate)}` : null}</p>
      <p>{acceptDate ? `Aceitação: ${formatDate(acceptDate)}` : null}</p>
      <p>{startedDate ? `Início: ${formatDate(startedDate)}` : null}</p>
      <p>{finishedDate ? `Fim: ${formatDate(finishedDate)}` : null}</p>
    </div>
  );
};

const Table = ({ instance, handleAccept }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = instance;

  const { t } = useTranslation();
  return (
    <>
      <BootstrapTable hover {...getTableProps}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div className="tableHeader">
                    {column.render('Header')}
                    {column.canSort
                      && (
                        <SortArrow
                          down={column.isSortedDesc}
                          enabled={column.isSorted}
                          {...column.getSortByToggleProps()}
                        />
                      )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { value, column: { Header }, row: { original } } = cell;
                  let component = null;

                  if (Header === 'Nome do paciente') {
                    component = renderClientName(original);
                  } else if (Header === 'Telefone para contato') {
                    component = renderCellphone(value);
                  } else if (Header === 'Endereço') {
                    component = renderAddress(original, t);
                  } else if (Header === 'Acompanhamento') {
                    component = renderTracking(original);
                  } else if (Header === 'Datas') {
                    component = renderDates(original);
                  } else if (Header === 'Ações') {
                    component = renderActions(original, handleAccept);
                  } else {
                    component = cell.render('Cell');
                  }
                  return <td className="tableTd" {...cell.getCellProps()}>{component}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </BootstrapTable>
      <Pagination tableInstance={instance} />
    </>
  );
};

export default Table;

Table.propTypes = {
  instance: PropTypes.objectOf(PropTypes.any).isRequired,
  handleAccept: PropTypes.func.isRequired,
};
