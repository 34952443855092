import React from 'react';
import { Pagination } from "react-bootstrap";
import './Pagination.scss';

const paginationSize = 5;

const EmerPagination = ({ tableInstance }) => {
  const {
    state: { pageIndex }, previousPage, nextPage, canPreviousPage,
    canNextPage, gotoPage, pageOptions, pageCount,
  } = tableInstance;

  const getPagesOptions = () => {
    if (pageOptions.length <= paginationSize) {
      return pageOptions;
    }
    if (pageIndex === 0 || pageIndex === 1 || pageIndex === 2) {
      return pageOptions.slice(0, 5);
    }
    const nPagesAfterIndex = pageOptions.length - (pageIndex + 1);
    if (nPagesAfterIndex === 0 || nPagesAfterIndex === 1 || nPagesAfterIndex === 2) {
      return pageOptions.slice(pageOptions.length - paginationSize, pageOptions.length);
    }

    return pageOptions.slice(pageIndex - Math.floor(paginationSize / 2), Math.ceil(pageIndex + (paginationSize / 2)));
  };

  return (
    <div>
      <div className="paginationButtonsDiv">
        <Pagination>
          <Pagination.First disabled={pageIndex === 0} onClick={() => gotoPage(0)} />
          <Pagination.Prev disabled={!canPreviousPage} onClick={() => previousPage()} />
          {getPagesOptions().map(pageNumber => (
            <Pagination.Item
              key={pageNumber}
              active={pageNumber === pageIndex}
              onClick={() => gotoPage(pageNumber)}
            >
              {pageNumber + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={!canNextPage}
            onClick={() => nextPage()}
          />
          <Pagination.Last
            disabled={pageCount === -1 || pageIndex === pageOptions.length - 1}
            onClick={() => gotoPage(pageOptions.length - 1)}
          />
        </Pagination>
      </div>
      {
        pageCount !== -1 && (
          <div className="paginationPageOf">
            {`Página ${pageOptions.length > 0 ? pageIndex + 1 : 0} de ${pageOptions.length}`}
          </div>
        )
      }
    </div>
  )
};

export default EmerPagination;