import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '../../../../../../components';
import { useAxios } from '../../../../../../hooks';

const CompanySelect = (props) => {
  const { value } = props;
  const { t } = useTranslation();
  const {
    data,
    isPending,
    isResolved,
  } = useAxios({
    url: 'company',
    method: 'GET',
  }, []);

  return (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
    >
      <InputLabel id="company-select-label">
        {
          (
            isPending
            && t('backoffice.users.modal.company.select.loading.message')
          ) || (
            !value
            && t('backoffice.users.modal.company.select.message')
          ) || t('backoffice.users.modal.company.select.label')
        }
      </InputLabel>
      <Select
        {...props}
        disabled={isPending || !data.length}
      >
        <MenuItem value="">
          {(isPending && t('backoffice.users.modal.company.select.loading.message')) || ''}
        </MenuItem>
        {
          isResolved && data
            .map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
              >
                {name}
              </MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
};

CompanySelect.propTypes = {
  value: PropTypes.string,
};

CompanySelect.defaultProps = {
  value: '',
};

export default CompanySelect;
