import { withStyles } from '@material-ui/core/styles';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '../../../../components';

export const Dialog = withStyles(() => ({
  paper: {
    minHeight: 400,
    padding: 0,
  },
}))(MuiDialog);

export const DialogTitle = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}))(MuiDialogTitle);

export const DialogContent = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(8),
  },
}))(MuiDialogActions);
