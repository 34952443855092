export default {
  mainColor: '#2400A8',
  mainColorDark: '#160066',
  mainColorLight: '#DCD6F1',
  mainColorContrast: '#FFFFFF',
  mainColorDisabled: '#F6F5FA',
  textColorPrimary: '#0A0033',
  textColorSecondary: '#8D8A99',
  textColorDisabled: '#DCDAE5',
  textColorContrast: '#F5F2FF',
  statusColorDanger: '#FF0000',
  statusColorDangerLight: '#FFE5E5',
  statusColorAlert: '#FF9500',
  statusColorAttention: '#FFD25F',
  statusColorSuccess: '#00D755',
  statusColorSuccessLight: '#CCF7DD',
  statusColorNeutral: '#00AAFF',
  statusColorNeutralLight: '#CCEEFF',
};
