import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  status as listOfStatus,
  formatAsCPF,
  formatAsCurrency,
} from '../../../../utils';
import {
  CircularProgress,
  Typography,
  TableBody,
  TableRow,
  TableCell,
} from '../../../../components';

const renderCapt = (text) => (
  <Typography
    variant="caption"
    color="textSecondary"
  >
    {text}
  </Typography>
);

const renderCpf = (cpf) => `${formatAsCPF(cpf)}`;
const renderBirthDate = (date) => (
  <>
    {`${moment(date).format('DD/MM/YYYY')} - `}
    {renderCapt('Nascimento')}
  </>
);
const renderInsurancePrice = (insurance) => (
  <Typography
    variant="body2"
    color="textPrimary"
    align="center"
  >
    {formatAsCurrency(insurance.monthlyValue, 1) || 'erro'}
  </Typography>
);
const renderDate = (date) => moment(date).format('DD/MM/YYYY - HH:mm');
const renderInsuranceInfo = (rowData) => {
  const {
    name,
    cpf,
    birthDate,
    gender,
    uf,
  } = rowData;

  return (
    <ul>
      <li>{name}</li>
      <li>
        <>
          {`${renderCpf(cpf)} - `}
          {renderCapt('CPF')}
        </>
      </li>
      <li>{renderBirthDate(birthDate)}</li>
      <li>{gender === 'MALE' ? 'Masculino' : 'Feminino'}</li>
      <li>{uf}</li>
    </ul>
  );
};
const renderPhoneNumber = (lead) => {
  const {
    phoneNumber,
    preferredContact,
  } = lead;

  let preferredText = '';
  switch (preferredContact) {
    case 'WHATSAPP':
      preferredText = 'Whatsapp';
      break;
    case 'PHONE':
      preferredText = 'Telefone';
      break;
    default:
      preferredText = 'Telefone';
  }

  return (
    <>
      {`${phoneNumber} - `}
      {renderCapt(preferredText)}
    </>
  );
};

const Tbody = ({
  instance,
  status,
  message,
}) => {
  const { t } = useTranslation();

  const {
    getTableBodyProps,
    prepareRow,
    page,
  } = instance;

  return (
    <TableBody {...getTableBodyProps}>
      {
        (
          status === listOfStatus.PENDING && (
            <TableRow>
              <TableCell
                colSpan={instance.columns.length}
                align="center"
              >
                <CircularProgress
                  color="primary"
                  size={30}
                />
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED
          && !page.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t('no.data.found')}
              </TableCell>
            </TableRow>
          )
        ) || (
          status === listOfStatus.RESOLVED && (
            page
              .map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {
                      row
                        .cells
                        .map((cell) => {
                          const {
                            value,
                            column: {
                              id,
                              align,
                            },
                            row: { original },
                          } = cell;
                          let component = null;

                          if (id === 'insurancePrice') {
                            component = renderInsurancePrice(value);
                          } else if (id === 'cpf') {
                            component = renderInsuranceInfo(original);
                          } else if (id === 'creationDate') {
                            component = renderDate(value);
                          } else if (id === 'lead.mail') {
                            component = value || 'E-mail não cadastrado';
                          } else if (id === 'lead.phoneNumber') {
                            component = renderPhoneNumber(original.lead);
                          } else {
                            component = cell.render('Cell');
                          }

                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              align={align}
                            >
                              {component}
                            </TableCell>
                          );
                        })
                    }
                  </TableRow>
                );
              })
          )
        ) || (
          status === listOfStatus.REJECTED && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={instance.columns.length}
              >
                {t(message)}
              </TableCell>
            </TableRow>
          )
        )
      }
    </TableBody>
  );
};

Tbody.propTypes = {
  instance: PropTypes.shape({
    getTableBodyProps: PropTypes.func,
    prepareRow: PropTypes.func,
    page: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Tbody;
