import {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { status as listOfStatus } from '../utils';
import api from '../api';

const useAxios = (initialRequest, initialData) => {
  const [request, setRequest] = useState(initialRequest);
  const [status, setStatus] = useState(listOfStatus.IDLE);
  const [message, setMessage] = useState('');
  const [data, setData] = useState(initialData);

  const isIdle = useMemo(() => status === listOfStatus.IDLE, [status]);
  const isPending = useMemo(() => status === listOfStatus.PENDING, [status]);
  const isResolved = useMemo(() => status === listOfStatus.RESOLVED, [status]);
  const isRejected = useMemo(() => status === listOfStatus.REJECTED, [status]);

  useEffect(() => {
    const getData = async () => {
      try {
        setStatus(listOfStatus.PENDING);

        const response = await api.request(request);

        setData(response.data);
        setStatus(listOfStatus.RESOLVED);
      } catch (error) {
        if (error.response) {
          setMessage(error.response.data);
        } else if (error.message === 'Network Error') {
          setMessage('network.error.message');
        } else {
          setMessage('generic.error.message');
        }

        setStatus(listOfStatus.REJECTED);
      }
    };

    if (request) {
      getData();
    } else {
      setStatus(listOfStatus.IDLE);
      setMessage('');
    }
  }, [request]);

  return {
    status,
    data,
    message,
    setRequest,
    isIdle,
    isPending,
    isResolved,
    isRejected,
  };
};

export default useAxios;
