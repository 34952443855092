import React, { useEffect, useState, useMemo, useContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import TableOfProfessionals from './table/Table';
import FilterCard from './filters/FilterCard';
import { DefaultColumnFilter, OnDutyColumnFilter, StatusColumnFilter } from './filters/Filters';
import { checkboxFilter, onDutySortFunction } from './filters/FilterFunctions';
import { Row, Col, Grid, Button, ContentCard } from '../../components';
import AddDoctorModal from './addDoctor';
import AddAmbulanceModal from './addAmbulance';
import { AddCircleIcon } from '../../assets/icons';
import { UserContext, ProfessionalsContext } from '../../context';
import { userTypes, sort } from '../../utils';
import { useAxios } from '../../hooks';
import { reducer, actionTypes, initialState } from './reducer';
import './Professionals.scss';

const Professionals = () => {
  const { t } = useTranslation();
  const { userType } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, setRequest } = useAxios(state.professionalsRequest, []);

  useEffect(() => setRequest(state.professionalsRequest), [setRequest, state.professionalsRequest]);

  const columns = useMemo(
    () => [
      {
        Header: t('professionals.table.name.column.title'),
        accessor: 'name',
        sortType: sort,
      },
      {
        Header: t('professionals.table.mail.column.title'),
        accessor: 'mail',
      },
      {
        Header: t('professionals.table.companyName.column.title'),
        accessor: 'companyName',
      },
      {
        Header: t('professionals.table.onDuty.column.title'),
        accessor: 'onDuty',
        sortType: onDutySortFunction,
        filter: checkboxFilter,
        Filter: OnDutyColumnFilter,
        align: 'center',
      },
      {
        Header: t('professionals.table.doctorStatus.column.title'),
        accessor: 'doctorStatus',
        filter: checkboxFilter,
        Filter: StatusColumnFilter,
        align: 'center',
      },
      {
        Header: t('professionals.table.currentLocation.column.title'),
        accessor: 'currentLocation',
        disableSortBy: true,
        disableFilters: true,
        align: 'center',
      },
      {
        Header: t('professionals.table.actions.column.title'),
        accessor: 'cellPhone',
        disableSortBy: true,
        disableFilters: true,
        align: 'center',
      },
    ],
    [t],
  );
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const hiddenColumns = useMemo(
    () => (userType === userTypes.ADMIN && []) || ['companyName'],
    [userType],
  );
  const instance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        isSorted: true,
        hiddenColumns,
        sortBy: [
          {
            id: 'name',
          },
        ],
      },
      defaultColumn,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const [openAddDoctor, setOpenAddDoctor] = useState(false);
  const [openAddAmbulance, setOpenAddAmbulance] = useState(false);

  return (
    <ProfessionalsContext.Provider value={{ state, dispatch, actionTypes }}>
      <div className="content">
        <Row>
          <Col md={12}>
            <ContentCard
              title={t('professionals.contentCard.title')}
              ctTableFullWidth
              ctTableResponsive
              content={
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{
                      paddingLeft: 16,
                      marginBottom: 24,
                    }}
                  >
                    {/* {
                      userType === userTypes.COMPANY && ( */}
                    <>
                      <AddDoctorModal
                        open={openAddDoctor}
                        onClose={() => setOpenAddDoctor(false)}
                        setOpen={setOpenAddDoctor}
                        transitionDuration={500}
                      />
                      <AddAmbulanceModal
                        open={openAddAmbulance}
                        onClose={() => setOpenAddAmbulance(false)}
                        setOpen={setOpenAddAmbulance}
                        transitionDuration={500}
                      />
                      <Grid item xs container justify="flex-end" spacing={1}>
                        <Button
                          variant="text"
                          color="primary"
                          size="small"
                          startIcon={<AddCircleIcon color="primary" />}
                          onClick={() => setOpenAddDoctor(true)}
                        >
                          {t('professionals.add.doctor.button.label')}
                        </Button>
                        <Button
                          variant="text"
                          color="primary"
                          size="small"
                          startIcon={<AddCircleIcon color="primary" />}
                          onClick={() => setOpenAddAmbulance(true)}
                        >
                          {t('professionals.add.ambulance.button.label')}
                        </Button>
                      </Grid>
                    </>
                    {/* )
                    } */}
                  </Grid>
                  <Grid item xs={12}>
                    <FilterCard instance={instance} />
                  </Grid>
                </Grid>
              }
            />
            <ContentCard
              ctTableFullWidth
              ctTableResponsive
              content={<TableOfProfessionals instance={instance} />}
            />
          </Col>
        </Row>
      </div>
    </ProfessionalsContext.Provider>
  );
};

export default Professionals;
