import React, {
  useState,
  useEffect,
} from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import AdminLayout from './layouts/Admin';
import { Login } from './views';
import { userService } from './services';
import theme from './material/theme';
import {
  UserContext,
  DocContext,
  NotificationContext,
} from './context';
import { userTypes } from './utils';
import notificationAudio from './sounds/seu_doc_bell.mp3';
import notificationIcon from './assets/img/emerLogoIcon.svg';
import analytics from './analytics';
import notificationSystemRef from './services/notificationSystemRef';
import 'moment/locale/pt-br';

moment.locale('pt-br');

const script = document.createElement('script');
const addWidget = () => {
  const widgetId = 64000001432;

  window.fwSettings = {
    widget_id: widgetId,
  };

  if (typeof window.FreshworksWidget !== 'function') {
    const n = ({ ...rest }) => {
      n.q.push(rest);
    };
    n.q = [];
    window.FreshworksWidget = n;
  }

  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;

  document.head.appendChild(script);
};

const removeWidget = () => document.head.removeChild(script);

const App = () => {
  const [userType, setUserType] = useState(null);
  const [id, setId] = useState(null);
  const [name, setName] = useState(null);
  const [mail, setMail] = useState(null);
  const [docTitle, setDocTitle] = useState('');
  const [companyId, setCompanyId] = useState(null);
  const [token, setToken] = useState(null);
  const [isLogged, setIslogged] = useState(userService.isLogged());
  const [ws, setWs] = useState(undefined);
  const [message, setMessage] = useState('');
  const [showSidebar, setShowSidebar] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const admin = (cId) => (
      cId === 'null'
      || cId === null
      || cId === ''
      || cId === undefined
    );

    setUserType(admin(companyId) ? userTypes.ADMIN : userTypes.COMPANY);
  }, [companyId]);

  useEffect(() => {
    document.title = `${docTitle} EMER backoffice`;
  }, [docTitle]);

  useEffect(() => {
    if (ws) {
      ws.onmessage = (evt) => {
        const data = JSON.parse(evt.data);

        if (data.message) {
          setMessage(data.message);

          analytics.track('Notificação de regulação de ambulâncias recebida', {
            'Logged user': mail,
            'Notification type': data.message,
          });

          const showNotification = ![
            'emergency.attendance.canceled',
            'telemedicine.attendance.canceled',
            'attendance.canceled',
          ].includes(data.message);

          if (showNotification) {
            const type = data.message.includes('telemedicine') ? 'telemedicine' : 'emergency';

            notificationSystemRef.current.addNotification({
              title: t(`${type}.requested.notification.title`),
              message: `${t(data.message)}`,
              level: 'warning',
              position: 'tr',
              autoDismiss: 10,
            });
            if (
              userType === userTypes.COMPANY
              && document.visibilityState === 'hidden'
              && Notification.permission === 'granted'
            ) {
              new Audio(notificationAudio).play();
              new Notification(t('emergency.requested.notification.title'), {
                body: t('emergency.requested'),
                icon: notificationIcon,
              });
            }
          }
        }
      };

      ws.onopen = () => {
        ws.send(JSON.stringify({
          event: 'CONNECT',
          token,
        }));
      };
    }
  }, [ws, mail, t, token, userType]);

  useEffect(() => {
    analytics.init();
    if (userService.isLogged()) {
      const user = userService.getUser();

      if ('id' in localStorage) setId(user.id);
      if ('companyId' in localStorage) setCompanyId(user.companyId);
      if ('name' in localStorage) setName(user.name);
      if ('mail' in localStorage) setMail(user.mail);
      if ('token' in localStorage) setToken(user.token);

      setWs(new WebSocket(`${process.env.REACT_APP_BACKOFICCE_WS_URL}/company/notification`));
    } else {
      localStorage.clear();
    }
  }, [isLogged]);

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
    addWidget();

    return () => {
      removeWidget();
    };
  }, []);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="pt-br">
      <DocContext.Provider value={{ setDocTitle, showSidebar, setShowSidebar }}>
        <NotificationContext.Provider value={{ message }}>
          <ThemeProvider theme={theme}>
            <UserContext.Provider
              value={{
                id,
                name,
                mail,
                userType,
                companyId,
              }}
            >
              <BrowserRouter>
                <Switch>
                  {
                    isLogged ? (
                      <>
                        <Route
                          path="/admin"
                          render={(props) => <AdminLayout {...props} />}
                        />
                        <Redirect
                          from="/"
                          to={`/admin/${userType === userTypes.ADMIN ? 'housecalls' : 'professionals'}`}
                        />
                      </>
                    ) : (
                      <>
                        <Route path="/login" render={() => <Login setIslogged={setIslogged} />} />
                        <Redirect from="/" to="/login" />
                      </>
                    )
                  }
                </Switch>
              </BrowserRouter>
            </UserContext.Provider>
          </ThemeProvider>
        </NotificationContext.Provider>
      </DocContext.Provider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
