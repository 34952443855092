/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/img/emer-logo.svg";
import analytics from "../../analytics";
import { DocContext } from "../../context";
import SidebarButton from "../SidebarButton";
import "./sidebar.scss";

const Sidebar = (props) => {
  const { showSidebar, setShowSidebar } = useContext(DocContext);
  const { pathname } = useLocation();

  const activeRoute = (prop, subState) => {
    return pathname.indexOf(prop.layout + prop.path) > -1 ||
      (subState && prop.subRoutes)
      ? "active"
      : "";
  };

  const trackAndSidebar = (name) => {
    analytics.track(`Abriu ${name}`);
    setShowSidebar(!showSidebar);
  };

  return (
    <div
      id="sidebar"
      className="sidebar"
      data-color={props.color}
      data-image={props.image}
    >
      <div className="logo text-center" href="/">
        <Logo />
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {props.routes.map((route, index) => {
            if (!route.redirect)
              return (
                <SidebarButton
                  key={index}
                  route={route}
                  showSidebar={showSidebar}
                  setShowSidebar={showSidebar}
                  activeRoute={activeRoute}
                  trackAndSidebar={trackAndSidebar}
                />
              );
            return null;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
