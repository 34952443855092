import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import userService from '../../services/user';
import { Button } from '../../components';
import { ReactComponent as LogoEmer } from '../../assets/img/logoEmer.svg';
import './Login.scss';

const Login = ({ setIslogged }) => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isLoged = await userService.login(mail, password);
    if (isLoged) {
      setIslogged(true);
    } else {
      setError(true);
    }
  };

  return (
    <div className="content">
      <div className="loginView">
        <div className="formCard">
          <LogoEmer className="logo" />
          <h4 className="title">
            Digite o seu e-mail e senha
            <br />
            para entrar:
          </h4>
          <form onSubmit={handleSubmit} autoComplete="on">
            <div className="formGroup">
              <label className="control-label" htmlFor="emailControl">E-mail</label>
              <FormControl
                maxLength="90"
                id="emailControl"
                value={mail}
                type="email"
                placeholder="e-mail"
                onChange={(e) => setMail(e.target.value)}
              />
            </div>
            <div className="formGroup">
              <label className="control-label" htmlFor="passwordControl">Senha</label>
              <FormControl
                maxLength="30"
                id="passwordControl"
                value={password}
                type="password"
                placeholder="senha"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="errorDiv">
              {error && <p>Os dados informados são inválidos.</p> }
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
              Entrar
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

Login.propTypes = {
  setIslogged: PropTypes.func.isRequired,
};
