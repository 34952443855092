import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  ContentCard,
  TableErrorFallback,
} from '../../components';
import TableOfLeads from './components/table';

const Leads = () => {
  const { t } = useTranslation();

  return (
    <div className="content">
      <Row>
        <Col md={12}>
          <ContentCard
            title={t('leads.table.title')}
            ctTableFullWidth
            ctTableResponsive
            content={(
              <ErrorBoundary
                fallback={TableErrorFallback}
                fallbackRender={TableErrorFallback}
                FallbackComponent={TableErrorFallback}
              >
                <TableOfLeads />
              </ErrorBoundary>
            )}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Leads;
